import classes from "./InfoPanel.module.css";
import useTheme from "../../../../common/hooks/useTheme";
import { useSelector } from "react-redux";
import { RootStatePlatform } from "../../store/platform-slice";

const InfoPanel = () => {
  const theme = useTheme();

  const reportData = useSelector(
    (state: RootStatePlatform) => state.platform.dataPlatformServer
  );

  const selectedMetric = useSelector(
    (state: RootStatePlatform) => state.platform.selectedMetric
  );

  const selectedMetricData = reportData.metrics.find(
    (el: any) => el.type === selectedMetric
  );
  return (
    <div
      className={classes.container}
      style={{ borderColor: theme.colors_ds.NEUTRAL_BORDER_WEAK }}
    >
      <div
        className={classes.title}
        style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
      >
        Why it is important to track
      </div>
      <div
        className={classes.text}
        style={{ color: theme.colors_ds.NEUTRAL_TEXT_STRONG }}
      >
        {selectedMetricData.whyItIsImportantToTrackText}
      </div>
    </div>
  );
};

export default InfoPanel;
