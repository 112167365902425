import classes from "./ChartBars.module.css";

import iconExcellent from "../../images/iconExcellent.png";
import iconGood from "../../images/iconGood.png";
import iconNeutral from "../../images/iconNeutral.png";
import iconLimitation from "../../images/iconLimitation.png";
import iconSevereLimitation from "../../images/iconSevereLimitation.png";

type label = { INDEX: number; NAME: string; VALUE: number };

interface Props {
  title1: string;
  title2: string;
  value: number;
  useLargerFont: boolean;
  customGap: boolean;
  labelsIsVisible: boolean;
  labels: label[];
  showIcon: boolean;
  iconLabel: string;
}

const ChartBars = ({
  title1,
  title2,
  value,
  useLargerFont,
  customGap,
  labelsIsVisible,
  labels,
  showIcon,
  iconLabel,
}: Props): JSX.Element => {
  let fillColor: string;
  let iconType: string;
  if (value <= 22) {
    fillColor = `${classes.fill} ${classes.severeLimitation}`;
    iconType = iconSevereLimitation;
  } else if (value <= 40) {
    fillColor = `${classes.fill} ${classes.limitations}`;
    iconType = iconLimitation;
  } else if (value <= 60) {
    fillColor = `${classes.fill} ${classes.neutral}`;
    iconType = iconNeutral;
  } else if (value <= 80) {
    fillColor = `${classes.fill} ${classes.good}`;
    iconType = iconGood;
  } else {
    fillColor = `${classes.fill} ${classes.excellent}`;
    iconType = iconExcellent;
  }

  return (
    <div>
      <div
        className={classes.header}
        style={{ fontSize: useLargerFont ? "24px" : "14px" }}
      >
        <span className={classes.bold}>{title1}</span>
        <span className={classes.bold}>-</span>
        <span>{title2}</span>
      </div>
      <div>
        <div
          className={classes.inner}
          style={customGap ? { marginTop: "5px" } : {}}
        >
          <div className={classes.container}>
            <div className={fillColor} style={{ width: `${value}%` }}></div>
          </div>
          <div className={`${classes.pointer} ${classes.poinet__q1}`}></div>
          <div className={`${classes.pointer} ${classes.poinet__q2}`}></div>
          <div className={`${classes.pointer} ${classes.poinet__q3}`}></div>
          <div className={`${classes.pointer} ${classes.poinet__q4}`}></div>
          <div>
            {labelsIsVisible && (
              <ul className={classes.labels}>
                {labels.map((label) => (
                  <li key={label.INDEX}>{label.NAME}</li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
      {showIcon && (
        <div className={classes.iconContainer}>
          <div className={classes.iconInner}>
            {/*<div className={classes.iconLabel}>{iconLabel}</div>*/}
            {/*<img*/}
            {/*  className={classes.iconImg}*/}
            {/*  src={iconType}*/}
            {/*  alt="icon per category"*/}
            {/*/>*/}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChartBars;
