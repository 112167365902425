import { createSlice } from "@reduxjs/toolkit";

import DUMMY_DATA from "./dataJSON.json";
import { format } from "date-fns";
import { ClientType } from "../../../common/entities/profile";

export interface RootStatePlatform {
  platform: RootStatePlatformObj;
}

export interface RootStatePlatformObj {
  data: any;
  dataNutServer: {
    overview_tab: { daily_tasks: []; last_health_data_sync_at: string | null };
  };
  dataPlatformServer: any;
  selectedCustomer: ClientType;
  ASActiveDate: string;
  selectedMetric: number;
}

export interface RootStatePlatform {
  platform: RootStatePlatformObj;
}

const initialPlatformState: RootStatePlatformObj = {
  data: DUMMY_DATA,
  dataNutServer: {
    overview_tab: { daily_tasks: [], last_health_data_sync_at: null },
  },
  dataPlatformServer: {},
  selectedCustomer: {
    id: "",
    firstName: "",
    lastName: "",
    gender: "",
    birthday: "",
    email: "",
    phone: "",
    country: "",
    address: "",
    zipcode: "",
    nutrition: false,
    workout: false,
    report: false,
    dateCreated: "",
  },
  ASActiveDate: format(new Date().toISOString(), "yyyy-MM-dd"),
  selectedMetric: 0,
};

const platformSlice = createSlice({
  name: "platform",
  initialState: initialPlatformState,
  reducers: {
    updateData(state, action) {
      state.data = action.payload;
    },
    updateDataNutServer(state, action) {
      state.dataNutServer = action.payload;
    },
    updateDataPlatformServer(state, action) {
      state.dataPlatformServer = action.payload;
    },
    updateSelectedCustomer(state, action) {
      state.selectedCustomer = action.payload;
    },
    updateASActiveDate(state, action) {
      state.ASActiveDate = action.payload;
    },
    setSelectedMetric(state, action) {
      state.selectedMetric = action.payload;
    },
    clearPlatformState(state) {
      state = initialPlatformState;
    },
  },
});

export const platformActions = platformSlice.actions;

export default platformSlice;
