import {
  LineChart,
  AreaChart,
  Line,
  XAxis,
  YAxis,
  Area,
  Tooltip,
  Dot,
  ReferenceLine,
} from "recharts";

type LineData = {
  x: number;
  line1?: number;
  line2?: number;
  line3?: number;
  line4?: number;
  line5?: number;
  line6?: number;
  lineUser?: number;
};

interface Props {
  width: number;
  height: number;
  biaxialLineChartData: {
    gender: string;
    lines_men: LineData[];
    lines_women: LineData[];
  };
}

const BiaxialLineChart = ({
  width,
  height,
  biaxialLineChartData,
}: Props): JSX.Element => {
  const linesDataHandler = (gender: string): LineData[] => {
    let linesData;
    switch (gender) {
      case "Male":
        linesData = biaxialLineChartData.lines_men;
        break;
      case "Female":
        linesData = biaxialLineChartData.lines_women;
        break;
      default:
        linesData = biaxialLineChartData.lines_men;
    }
    return linesData;
  };

  // const customAxisValues = (array: LineData[], string: string) => {
  //   let min: number = 100;
  //   let max: number = 0;
  //   let tickNumber: number = 0;
  //   const tickArray: number[] = [];
  //   array.forEach((el) => {
  //     for (const [key, value] of Object.entries(el)) {
  //       if (key.includes(string)) {
  //         if (min > value || min === undefined) {
  //           min = value;
  //         }
  //
  //         if (max < value || max === undefined) {
  //           max = value;
  //         }
  //       }
  //     }
  //   });
  //
  //   const newMin = Math.trunc(min / 10) * 10;
  //
  //   for (let i = 0; i <= max - newMin + 10; i += 10) {
  //     tickArray.push(newMin + i);
  //     tickNumber++;
  //   }
  //
  //   return {
  //     domain: [tickArray[0], tickArray[tickNumber - 1]],
  //     tickArray,
  //     tickNumber,
  //   };
  // };

  const linesData = linesDataHandler(biaxialLineChartData.gender);
  // const customXAxis = customAxisValues(linesData, "x");
  // const customYAxis = customAxisValues(linesData, "li");
  const customDasharray: string = "3 1";

  return (
    <AreaChart
      width={width}
      height={height}
      data={linesData}
      margin={{
        top: 10,
        right: 30,
        bottom: 0,
        left: 20,
      }}
    >
      <defs>
        <linearGradient id="colorExcellent" x1="0" y1="0" x2="1" y2="0">
          <stop offset="5%" stopColor="var(--secondary12)" stopOpacity={0.8} />
          <stop offset="95%" stopColor="var(--secondary12)" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient id="colorGood" x1="0" y1="0" x2="1" y2="0">
          <stop offset="5%" stopColor="var(--secondary07)" stopOpacity={0.8} />
          <stop offset="95%" stopColor="var(--secondary07)" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient id="colorNeutral" x1="0" y1="0" x2="1" y2="0">
          <stop offset="5%" stopColor="var(--secondary20)" stopOpacity={0.8} />
          <stop offset="95%" stopColor="var(--secondary20)" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient id="colorLimitations" x1="0" y1="0" x2="1" y2="0">
          <stop offset="5%" stopColor="var(--secondary50)" stopOpacity={0.8} />
          <stop offset="95%" stopColor="var(--secondary50)" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient id="colorSevereLimitations" x1="0" y1="0" x2="1" y2="0">
          <stop offset="5%" stopColor="var(--secondary40)" stopOpacity={0.8} />
          <stop offset="95%" stopColor="var(--secondary40)" stopOpacity={0.1} />
        </linearGradient>
      </defs>
      <XAxis
        dataKey="x"
        domain={[15, 75]}
        type="number"
        tickCount={15}
        tickSize={-3.64}
        tickMargin={10}
        tick={{ fontSize: 8.52, fill: "var(--primary850)" }}
        stroke="black"
        style={{ fontSize: "8.85px", fontFamily: "AktivGroteskEx_A_Md" }}
      />
      <YAxis
        yAxisId="left"
        domain={[15, 65]}
        type="number"
        tickCount={11}
        style={{ fontSize: "8.85px", fontFamily: "AktivGroteskEx_A_Md" }}
        stroke="black"
        tick={{ fontSize: 8.52, fill: "var(--primary850)" }}
        tickSize={-5.5}
        tickMargin={20}
      />
      <YAxis
        orientation="right"
        yAxisId="right"
        domain={[15, 65]}
        type="number"
        tickCount={11}
        style={{ fontSize: "8.85px", fontFamily: "AktivGroteskEx_A_Md" }}
        stroke="black"
        tick={{ fontSize: 8.52, fill: "var(--primary850)" }}
        tickSize={-5.5}
        tickMargin={20}
      />
      <Area
        yAxisId="left"
        dataKey="area0"
        fill="url(#colorSevereLimitations)"
        isAnimationActive={false}
        stroke="var(--secondary40)"
        strokeWidth={0.5}
        strokeDasharray={customDasharray}
      />
      <Area
        yAxisId="left"
        dataKey="area1"
        fill="url(#colorLimitations)"
        isAnimationActive={false}
        stroke="var(--secondary50)"
        strokeWidth={0.5}
        strokeDasharray={customDasharray}
      />
      <Area
        yAxisId="left"
        dataKey="area2"
        fill="url(#colorNeutral)"
        isAnimationActive={false}
        stroke="var(--secondary20)"
        strokeWidth={0.5}
        strokeDasharray={customDasharray}
      />
      <Area
        yAxisId="left"
        dataKey="area3"
        fill="url(#colorGood)"
        isAnimationActive={false}
        stroke="var(--secondary07)"
        strokeWidth={0.5}
        strokeDasharray={customDasharray}
      />
      <Area
        yAxisId="left"
        dataKey="area4"
        fill="url(#colorExcellent)"
        isAnimationActive={false}
        stroke="transparent"
        strokeWidth={0.5}
        strokeDasharray={customDasharray}
      />
      <Area
        yAxisId="left"
        isAnimationActive={false}
        type="linear"
        dataKey="lineUser"
        stroke="var(--primary1000)"
        strokeWidth={2}
        connectNulls={true}
        dot={{
          stroke: "var(--primary1000)",
          strokeWidth: 0,
          fill: "var(--primary1000)",
          opacity: 1,
          r: 4,
        }}
      />
      <ReferenceLine
        yAxisId="left"
        stroke="var(--primary800)"
        strokeDasharray="3 1"
        strokeWidth={0.7}
        segment={[
          { x: 15, y: linesData[2].lineUser },
          { x: linesData[2].x, y: linesData[2].lineUser },
        ]}
      />
      <ReferenceLine
        yAxisId="left"
        stroke="var(--primary800)"
        strokeDasharray="3 1"
        strokeWidth={0.7}
        segment={[
          { x: linesData[2].x, y: 15 },
          { x: linesData[2].x, y: linesData[2].lineUser },
        ]}
      />
    </AreaChart>
  );
};

export default BiaxialLineChart;
