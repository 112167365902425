import React, { MouseEventHandler } from "react";
import useTheme from "../hooks/useTheme";

type Props = { onClick?: MouseEventHandler<SVGSVGElement> };

const Arrow = ({ onClick }: Props) => {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="9"
      viewBox="0 0 16 9"
      fill="none"
    >
      <path d="M8 0L0 9H16L8 0Z" fill={theme.colors_ds.PRIMARY_ICON_MAIN} />
    </svg>
  );
};

export default Arrow;
