import Layout from "../../components/reusable/Layout";
import Title from "../../components/reusable/Title";
import Card from "../../components/Card";

import classes from "./CoreMetricsPage.module.css";

import longevityCard1 from "../../images/longevityCard1.png";
import longevityCard2 from "../../images/longevityCard2.png";
import longevityCard3 from "../../images/longevityCard3.png";
import weightLossCard1 from "../../images/weightLossCard1.png";
import weightLossCard2 from "../../images/weightLossCard2.png";
import weightLossCard3 from "../../images/weightLossCard3.png";
import performanceCard1 from "../../images/performanceCard1.png";
import performanceCard2 from "../../images/performanceCard2.png";
import performanceCard3 from "../../images/performanceCard3.png";

type Section = {
  HOW_TO_IMPROVE: string;
  HOW_TO_IMPROVE_TEXT: string;
  INDEX: number;
  TITLE1: string;
  TITLE2: string;
  WHY_IT_MATTERS: string;
  WHY_IT_MATTERS_TEXT: string;
};

interface Props {
  data: {
    DESCRIPTION: string;
    INDEX: number;
    SECTIONS: Section[];
    TITLE: string;
  };
  reportType: string;
}

const CoreMetricsPage = ({ data, reportType }: Props): JSX.Element => {

  let cardSrc: string;
  const cardHandler = (reportType: string, cardType: number) => {
    switch (reportType) {
      case "10":
      case "11":
        cardSrc =
          cardType === 1
            ? longevityCard1
            : cardType === 2
            ? longevityCard2
            : longevityCard3;
        break;
      case "12":
      case "13":
        cardSrc =
          cardType === 1
            ? weightLossCard1
            : cardType === 2
            ? weightLossCard2
            : weightLossCard3;
        break;
      case "14":
      case "15":
        cardSrc =
          cardType === 1
            ? performanceCard1
            : cardType === 2
            ? performanceCard2
            : performanceCard3;
        break;
      default:
        cardSrc =
          cardType === 1
            ? longevityCard1
            : cardType === 2
            ? longevityCard2
            : longevityCard3;
    }
    return cardSrc;
  };

  let borderColor: string;
  const borderColorHandler = (reportType: string, cardType: number) => {
    switch (reportType) {
      case "10":
      case "11":
        borderColor =
          cardType === 1
            ? "var(--secondary23)"
            : cardType === 2
            ? "var(--secondary24)"
            : "var(--secondary25)";
        break;
      case "12":
      case "13":
        borderColor =
          cardType === 1
            ? "var(--secondary09)"
            : cardType === 2
            ? "var(--secondary14)"
            : "var(--secondary15)";
        break;
      case "14":
      case "15":
        borderColor =
          cardType === 1
            ? "var(--secondary31)"
            : cardType === 2
            ? "var(--secondary32)"
            : "var(--secondary33)";
        break;
      default:
        borderColor =
          cardType === 1
            ? "var(--secondary23)"
            : cardType === 2
            ? "var(--secondary24)"
            : "var(--secondary25)";
    }
    return borderColor;
  };
  return (
    <Layout>
      <Title title={data.TITLE} />
      <div className={classes.description}>{data.DESCRIPTION}</div>
      <div className={classes.cards}>
        <Card
          data={data}
          index={1}
          width="466px"
          innerWidth="55%"
          source={cardHandler(reportType, 1)}
          borderColor={borderColorHandler(reportType, 1)}
        />
        <Card
          data={data}
          index={2}
          width="559px"
          innerWidth="60%"
          source={cardHandler(reportType, 2)}
          borderColor={borderColorHandler(reportType, 2)}
        />
        <Card
          data={data}
          index={3}
          width="671px"
          innerWidth="70%"
          source={cardHandler(reportType, 3)}
          borderColor={borderColorHandler(reportType, 3)}
        />
      </div>
    </Layout>
  );
};

export default CoreMetricsPage;
