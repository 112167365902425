import { useSelector } from "react-redux";

import classes from "./ScoreScreen.module.css";
import ScoreScreenSidebar from "../components/score/ScoreScreenSidebar";
import MetricDetails from "../components/score/MetricDetails";
import BodyLinksImgBox from "../components/score/BodyLinksImgBox";
import { RootStatePlatform } from "../store/platform-slice";
import useTheme from "../../../common/hooks/useTheme";

export const scoreCategories = {
  1: "Severe limitation",
  2: "Limitation",
  3: "Neutral",
  4: "Good",
  5: "Excellent",
};

const ScoreScreen = () => {
  const theme = useTheme();
  const reportData = useSelector(
    (state: RootStatePlatform) => state.platform.dataPlatformServer
  );

  const selectedMetric = useSelector(
    (state: RootStatePlatform) => state.platform.selectedMetric
  );

  return (
    <div className={classes.container}>
      <div>
        <div
          className={classes.testIncludedTitle}
          style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
        >
          Tests Included
        </div>
        <div className={classes.testIncludedTable}>
          {reportData.dashboard.tests_included.map((el: any, index: number) => (
            <div
              key={index}
              className={classes.testIncludedText}
              style={{
                color: theme.colors_ds.NEUTRAL_TEXT_WEAK,
                borderColor: theme.colors_ds.NEUTRAL_BORDER_WEAK,
              }}
            >
              <div style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}>
                {el[0]}
              </div>
              <div style={{ fontFamily: "var(--font-Medium)" }}>{el[1]}</div>
            </div>
          ))}
        </div>
      </div>
      <div className={classes.inner}>
        <ScoreScreenSidebar />
        <BodyLinksImgBox />
      </div>
      {selectedMetric > 0 && selectedMetric < 100 && <MetricDetails />}
    </div>
  );
};

export default ScoreScreen;
