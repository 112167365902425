import classes from "./Title.module.css";

interface Props {
  title: string;
}

const Title = ({ title }: Props): JSX.Element => (
  <div className={classes.title}>{title}</div>
);

export default Title;
