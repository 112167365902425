import classes from "./RecommendationsGroup.module.css";
import RecommendationCard from "./RecommendationCard";
import { useSelector } from "react-redux";
import { RootStatePlatform } from "../../store/platform-slice";
import React, { useState } from "react";
import useTheme from "../../../../common/hooks/useTheme";
import InfoIcon from "../../svg/InfoIcon";
import CustomTooltip from "../common/CustomTooltip";

type Props = {
  title: string;
  text: string;
  items: any[];
  color: string;
  hasIcon?: any;
  cardHasNoColorCoding?: boolean;
  groupHasLeftBorder?: boolean;
};

const RecommendationsGroup = ({
  title,
  text,
  items,
  color,
  hasIcon,
  cardHasNoColorCoding,
  groupHasLeftBorder,
}: Props) => {
  const theme = useTheme();
  const [expandedRecommendation, setExpandedRecommendation] =
    useState<number>(-1);
  const data = useSelector(
    (state: RootStatePlatform) => state.platform.data.score
  );
  const selectedMetric = useSelector(
    (state: RootStatePlatform) => state.platform.selectedMetric
  );

  const selectedMetricData = data.sectionTwo.find(
    (el: any) => el.type === selectedMetric
  );
  return (
    <div
      className={classes.box}
      style={{
        borderColor: groupHasLeftBorder
          ? theme.colors_ds.NEUTRAL_BORDER_DEFAULT
          : "transparent",
        paddingLeft: groupHasLeftBorder ? "16px" : "0",
      }}
    >
      <div className={classes.headerContainer}>
        {hasIcon && hasIcon}
        <div className={classes.title}>{title}</div>
        <CustomTooltip message={text}>
          <InfoIcon />
        </CustomTooltip>
      </div>
      <div className={classes.recommendationsContainer}>
        {items.map((el: any, index: number) => (
          <RecommendationCard
            key={index}
            item={el}
            index={index}
            color={color}
            expandedRecommendation={expandedRecommendation}
            setExpandedRecommendation={setExpandedRecommendation}
            cardHasNoColorCoding={cardHasNoColorCoding}
          />
        ))}
      </div>
    </div>
  );
};

export default RecommendationsGroup;
