import classes from "./CustomDatePicker.module.css";
import useTheme from "../../../../common/hooks/useTheme";
import { add, format, sub } from "date-fns";
import { platformActions, RootStatePlatform } from "../../store/platform-slice";
import ArrowLeft from "../../svg/ArrowLeft";
import ArrowRight from "../../svg/ArrowRight";
import { useDispatch, useSelector } from "react-redux";
import { useAppStore } from "../../../../providers/AppStore";
import { Skeleton } from "@mui/material";
import React from "react";

const CustomDatePicker = ({
  isLoadingDataNut,
}: {
  isLoadingDataNut: boolean;
}) => {
  const theme = useTheme();
  const ASActiveDate = useSelector(
    (state: RootStatePlatform) => state.platform.ASActiveDate
  );
  const data = useSelector(
    (state: RootStatePlatform) => state.platform.data.dashboard
  );
  const lastSync = useSelector(
    (state: RootStatePlatform) =>
      state.platform.dataNutServer.overview_tab.last_health_data_sync_at
  );
  const { ASIsIntegrationActive } = useAppStore();
  const dispatch = useDispatch();

  let styleStatus = "warning";

  let hoursAgo = "0 hours";
  const today = new Date();

  const diff_hours = (dt2: Date, dt1: Date) => {
    let diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.round(diff);
  };

  let lastSyncValue = new Date(
    new Date(lastSync).valueOf() - new Date().getTimezoneOffset() * 60 * 1000
  );

  // if null then
  if (lastSyncValue !== null) {
    let diffHoursFromLastSync = diff_hours(
      lastSyncValue,
      new Date(ASActiveDate)
    );

    // Null if we do not have sync data for today
    if (
      diffHoursFromLastSync < 0 &&
      Math.abs(diffHoursFromLastSync) > 24 * 60
    ) {
      styleStatus = "danger";
    } else {
      if (diffHoursFromLastSync > -1) {
        styleStatus = "success";
      } else {
        hoursAgo = Math.abs(Math.round(diffHoursFromLastSync / 60)) + " hours";
        styleStatus = "warning";
      }
    }
  }

  const getToastText = (status: string, time: Date, hoursAgo: string) => {
    const curLastSync = new Date(time);

    const timeText =
      lastSync === undefined || lastSync === null
        ? "No Date"
        : curLastSync.toDateString() === today.toDateString()
        ? `Today ${format(curLastSync, "hh:mm a")}`
        : curLastSync.toDateString() === today.toDateString()
        ? `Yesterday ${format(curLastSync, "hh:mm a")}`
        : format(curLastSync, "dd-MM-yyyy hh:mm a");

    if (ASIsIntegrationActive() === false) {
      return "No integration connected";
    }
    if (status === "success") {
      return "Last sync: " + timeText;
    } else if (status === "warning") {
      return "Last sync: " + hoursAgo + " ago";
    }

    return "No data for today";
  };

  return (
    <div className={classes.datePickerContainer}>
      <div
        className={classes.arrowContainer}
        onClick={() => {
          if (isLoadingDataNut) {
            return;
          }
          const minusOneDay = sub(ASActiveDate, { days: 1 });
          dispatch(
            platformActions.updateASActiveDate(
              format(minusOneDay, "yyyy-MM-dd")
            )
          );
        }}
      >
        <ArrowLeft />
      </div>

      <div>
        <div
          className={classes.backToCurrentDayText}
          style={
            ASActiveDate === format(today, "yyyy-MM-dd")
              ? { opacity: 0 }
              : { color: theme.colors_ds.PRIMARY_TEXT_MAIN, cursor: "pointer" }
          }
          onClick={() => {
            dispatch(
              platformActions.updateASActiveDate(format(today, "yyyy-MM-dd"))
            );
          }}
        >
          {data.datePicker.backToCurrentDate}
        </div>
        <div
          className={classes.activeDate}
          style={{ color: theme.colors_ds.NEUTRAL_TEXT_STRONG }}
        >
          {format(ASActiveDate, "EEEE dd/MM")}
        </div>
        {isLoadingDataNut ? (
          <Skeleton
            variant="text"
            sx={{
              fontSize: "20px",
              bgcolor: theme.colorTokens.N[30],
            }}
            animation={"pulse"}
          />
        ) : (
          <div
            className={classes.syncInfo}
            style={{ color: theme.colors_ds.NEUTRAL_TEXT_WEAK }}
          >
            {getToastText(styleStatus, lastSyncValue, hoursAgo)}
          </div>
        )}
      </div>
      {new Date(ASActiveDate).toDateString() === today.toDateString() ? (
        <div style={{ width: "30px", height: "100%" }}></div>
      ) : (
        <div
          className={classes.arrowContainer}
          onClick={() => {
            if (isLoadingDataNut) {
              return;
            }
            const plusOneDay = add(ASActiveDate, { days: 1 });
            dispatch(
              platformActions.updateASActiveDate(
                format(plusOneDay, "yyyy-MM-dd")
              )
            );
          }}
        >
          <ArrowRight fill={theme.colors_ds.NEUTRAL_ICON_STRONG} />
        </div>
      )}
    </div>
  );
};

export default CustomDatePicker;
