import classes from "../../modules/authentication/reusable/TextInput.module.css";
import React, { MouseEventHandler } from "react";
import useTheme from "../hooks/useTheme";

type Props = { onClick: MouseEventHandler<SVGSVGElement> };

const Eye_close = ({ onClick }: Props) => {
  const theme = useTheme();
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.icon}
      onClick={onClick}
    >
      <g clipPath="url(#clip0_1410_2699)">
        <path
          d="M14.5984 5.59961L6.39844 13.7996C6.89844 13.8996 7.49844 13.9996 7.99844 13.9996C11.5984 13.9996 14.3984 10.8996 15.5984 9.09961C16.0984 8.39961 16.0984 7.49961 15.5984 6.79961C15.3984 6.49961 14.9984 6.09961 14.5984 5.59961Z"
          fill={theme.colors_ds.NEUTRAL_ICON_WEAK}
        />
        <path
          d="M14.3 0.3L11.6 3C10.5 2.4 9.3 2 8 2C4.4 2 1.6 5.1 0.4 6.9C-0.1 7.6 -0.1 8.5 0.4 9.1C0.9 9.9 1.8 10.9 2.8 11.8L0.3 14.3C-0.1 14.7 -0.1 15.3 0.3 15.7C0.5 15.9 0.7 16 1 16C1.3 16 1.5 15.9 1.7 15.7L15.7 1.7C16.1 1.3 16.1 0.7 15.7 0.3C15.3 -0.1 14.7 -0.1 14.3 0.3ZM5.3 9.3C5.1 8.9 5 8.5 5 8C5 6.3 6.3 5 8 5C8.5 5 8.9 5.1 9.3 5.3L5.3 9.3Z"
          fill={theme.colors_ds.NEUTRAL_ICON_WEAK}
        />
      </g>
      <defs>
        <clipPath id="clip0_1410_2699">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Eye_close;
