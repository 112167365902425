import useTheme from "../hooks/useTheme";

const Success_icon = () => {
  const theme = useTheme();
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="1" width="15" height="15" rx="7.5" stroke="#20BF7D" />
      <path
        d="M6.3 11.1636C6.8 11.6604 7.3 11.5611 7.7 11.1636L11.7 7.18915C12.1 6.7917 12.1 6.19553 11.7 5.79809C11.3 5.40064 10.7 5.40064 10.3 5.79809L7 9.07703L5.7 7.78532C5.3 7.38788 4.7 7.38788 4.3 7.78532C3.9 8.18277 3.9 8.77894 4.3 9.17639L6.3 11.1636Z"
        fill={theme.colors_ds.SUCCESS_ICON_MAIN}
      />
    </svg>
  );
};

export default Success_icon;
