import Layout from "../../components/reusable/Layout";

import classes from "./TrainerNotePage.module.css";
import CustomCard from "../../components/reusable/CustomCard";

const TrainerNotePage = ({ data }) => {
  return (
    <Layout isDarkTheme={true} customPadding="40px">
      <CustomCard
        isDarkTheme={true}
        cardHeight="100%"
        cardWidth="100%"
        cardPadding="40px"
      >
        {data
          .replaceAll("<br>", "\n")
          .split(/[\r\n]+/)
          .map((el) => (
            <p className={classes.text} key={el}>
              {el}
            </p>
          ))}
      </CustomCard>
    </Layout>
  );
};

export default TrainerNotePage;
