import classes from "./Arrow.module.css";

interface Props {
  age: number;
  arrowIcon: string;
  arrowHeight: string;
  arrowColor: string;
  arrowContainerHeight?: string;
}

const Arrow = ({
  age,
  arrowIcon,
  arrowHeight,
  arrowColor,
  arrowContainerHeight,
}: Props): JSX.Element => {
  let arrowStyle: string;
  if (arrowContainerHeight) {
    arrowStyle = `${classes.container} ${classes.position1}`;
  } else {
    arrowStyle = `${classes.container} ${classes.position2}`;
  }

  const rotation = 1.8 * age - 90;
  return (
    <div
      className={arrowStyle}
      style={{
        transform: `rotate(${rotation}deg)`,
        transformOrigin: "center bottom",
        height: arrowContainerHeight,
      }}
    >
      <div
        className={classes.arrowLabel}
        style={{
          transform: `rotate(${rotation * -1}deg)`,
          backgroundColor: arrowColor,
        }}
      >
        {age}
      </div>
      <img
        style={{ height: arrowHeight }}
        className={classes.arrow}
        src={arrowIcon}
        alt="arrow point to the age"
      />
    </div>
  );
};

export default Arrow;
