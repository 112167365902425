import { useState, useEffect, useRef } from "react";
import classes from "./SecondaryClientMenu.module.css";
import useTheme from "../../../../common/hooks/useTheme";
import { NavLink, useParams, useLocation } from "react-router-dom";
import { tabs } from "../../store/helper";
import { pagesPlatform } from "./PlatformAppBar";

const SecondaryClientMenu = () => {
  const theme = useTheme();
  const location = useLocation();
  const menuRef = useRef<HTMLDivElement>(null);
  const [underlinePosition, setUnderlinePosition] = useState(0);
  const [underlineWidth, setUnderlineWidth] = useState(0);

  // Adjust underline position and width when the location changes
  useEffect(() => {
    const activeIndex = pagesPlatform.findIndex(
      (page) => page.link === location.pathname
    );
    if (activeIndex !== -1 && menuRef.current) {
      const menuItems = menuRef.current.querySelectorAll(`.${classes.menuTab}`);
      const activeItem = menuItems[activeIndex] as HTMLElement;

      if (activeItem) {
        setUnderlinePosition(activeItem.offsetLeft);
        setUnderlineWidth(activeItem.offsetWidth);
      }
    }
  }, [location.pathname]);

  return (
    <div className={classes.menuContainer} ref={menuRef}>
      <div className={classes.menuTabs}>
        {pagesPlatform
          .filter((item) => item.role === 1)
          .map((tab, index) => (
            <NavLink
              to={tab.link}
              key={index}
              className={classes.menuTab}
              style={({ isActive }) => ({
                textDecoration: "none",
                color: isActive
                  ? theme.colors_ds.NEUTRAL_TEXT_STRONG
                  : theme.colors_ds.NEUTRAL_TEXT_WEAK,
              })}
            >
              {tab.label}
            </NavLink>
          ))}
        {/* Dynamic underline based on active NavLink position and width */}
        <div
          className={classes.menuUnderline}
          style={{
            backgroundColor: theme.colors_ds.PRIMARY_ICON_MAIN,
            transform: `translateX(${underlinePosition}px)`,
            width: `${underlineWidth}px`,
            transition: "transform 0.4s ease, width 0.4s ease",
          }}
        />
      </div>
    </div>
  );
};

export default SecondaryClientMenu;
