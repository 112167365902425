import React, { useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";

import TextInput from "../reusable/TextInput";
import Button from "../../../common/ui/Button";
import OutlineButton from "../../../common/ui/OutlineButton";

import classes from "./SignUpForm.module.css";
import { useNavigate } from "react-router-dom";
import PasswordValidator from "../reusable/PasswordValidator";
import { PasswordReset } from "../../../common/network/Api";

type Props = {
  isPasswordUpdated: boolean;
  setIsPasswordUpdated: Function;
  setIsLoading: Function;
  setErrorMessage: Function;
  setShowError: Function;
  resetToken: string | null;
};

type UserDataProps = {
  password: string;
  passwordConfirm?: string;
  token?: string | null;
};

const ResetPasswordForm = ({
  isPasswordUpdated,
  setIsPasswordUpdated,
  setIsLoading,
  setErrorMessage,
  setShowError,
  resetToken,
}: Props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [curPassword, setCurPassword] = useState("");

  const navigate = useNavigate();
  const handleShowPassword = () => {
    setShowPassword(() => !showPassword);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(() => !showConfirmPassword);
  };

  const handleReset = async (userData: UserDataProps) => {
    try {
      await PasswordReset(userData);
      setIsPasswordUpdated(true);
      setErrorMessage("");

      // navigate(`/auth?mode=login&id=${id}`);
    } catch (e: any) {
      setErrorMessage(e.message);
      setShowError(true);
    }
  };

  const handleResetPassword = async (
    values: UserDataProps,
    { setSubmitting }: FormikHelpers<UserDataProps>
  ) => {
    setIsLoading(true);
    const user = {
      token: resetToken,
      password: values.password,
    };

    await handleReset(user);

    setSubmitting(false);
    setIsLoading(false);
  };

  return (
    <Formik
      initialValues={{
        password: "",
        passwordConfirm: "",
      }}
      validationSchema={Yup.object({
        password: Yup.string()
          .required("This is a required field.")
          .min(8, "Password must be at least 8 characters long.")
          .max(20, "Password must be less than 20 characters long.")
          .matches(
            /(?=.*[a-z])/,
            "Password must contain at least 1 lower case letter."
          )
          .matches(
            /(?=.*[A-Z])/,
            "Password must contain at least 1 upper case letter."
          )
          .matches(
            /(?=.*[!@#$%^&*[\]{}()?"\\,><':;|_~=+-])/,
            "Password must contain at least 1 special character."
          )
          .matches(/(?=.*\d)/, "Password must contain at least 1 number."),
        passwordConfirm: Yup.string()
          .required("Confirm password is required.")
          .oneOf([Yup.ref("password"), null], "Password does not match"),
      })}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleResetPassword}
    >
      <Form className={classes.inner} placeholder={""}>
        {!isPasswordUpdated && (
          <>
            <TextInput
              label="New Password"
              name="password"
              type={showPassword ? "text" : "password"}
              onClick={handleShowPassword}
              showPwd={showPassword}
              setCurPassword={setCurPassword}
              hasValidator={true}
            />
            <TextInput
              label="Confirm Password"
              name="passwordConfirm"
              type={showConfirmPassword ? "text" : "password"}
              onClick={handleShowConfirmPassword}
              showPwd={showConfirmPassword}
            />
            {/*{isFocused && <PasswordValidator curPassword={curPassword} />}*/}
            <PasswordValidator curPassword={curPassword} />
            <div className={classes.bottom}>
              <Button
                type="submit"
                text="Confirm"
                customWidth="100%"
                customMargin={"80px auto 0"}
              />
              <OutlineButton
                type="Button"
                text="Cancel"
                customWidth="100%"
                customMargin={"16px auto 0"}
                customHeight="48px"
                onClick={() => {
                  setIsPasswordUpdated(false);
                  navigate(`/auth?mode=login`);
                }}
              />
            </div>
          </>
        )}
        {/*{isPasswordUpdated && (*/}
        {/*  <div className={classes.bottom}>*/}
        {/*    <Button*/}
        {/*      type="button"*/}
        {/*      text="Login"*/}
        {/*      customWidth="100%"*/}
        {/*      customMargin={"55px auto 0"}*/}
        {/*      onClick={() => {*/}
        {/*        setIsPasswordUpdated(false);*/}
        {/*        navigate(`/auth?mode=login`);*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*)}*/}
      </Form>
    </Formik>
  );
};

export default ResetPasswordForm;
