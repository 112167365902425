import Points from "./Points";
import Labels from "./Labels";
import Arrow from "./Arrow";

import classes from "./AgeClock.module.css";

import arrowChronological from "../../../images/arrowChronological.png";
import arrowBiological from "../../../images/arrowBiological.png";

interface Props {
  data: {
    BIOLOGICAL_AGE: string;
    BIOLOGICAL_AGE_VALUE: number;
    CHRONOLOGICAL_AGE: string;
    CHRONOLOGICAL_AGE_VALUE: number;
    COMPARED_TO: string;
    DESCRIPTION: string;
    FAT_BURNING_EFFICIENCY_VALUE: number;
    FAT_BURN_EFFICIENCY: string;
    INCREASES_YOUR_AGE: string;
    INDEX: number;
    METABOLIC_RATE: string;
    METABOLIC_RATE_VALUE: number;
    REDUCES_YOUR_AGE: string;
    TITLE: string;
    VO2MAX: string;
    VO2MAX_VALUE: number;
  };
}

const AgeClock = ({ data }: Props): JSX.Element => {
  return (
    <div className={classes.container} id="svg">
      <svg id="svg" width="474" height="247" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3,247 a60,60 1 0,1 467,0"
          fill="transparent"
          stroke="var(--secondary22)"
          strokeWidth={3}
        />
      </svg>
      <Arrow
        arrowIcon={arrowChronological}
        age={data.CHRONOLOGICAL_AGE_VALUE}
        arrowHeight="195px"
        arrowColor="var(--secondary30)"
      />
      <Arrow
        arrowIcon={arrowBiological}
        age={data.BIOLOGICAL_AGE_VALUE}
        arrowHeight="215px"
        arrowColor="var(--secondary21)"
        arrowContainerHeight="310px"
      />
      <Points />
      <Labels />
      <div className={classes.mainLabel}>
        <div className={classes.value}>
          {data.BIOLOGICAL_AGE_VALUE - data.CHRONOLOGICAL_AGE_VALUE}
        </div>
        <div className={classes.description}>{data.COMPARED_TO}</div>
      </div>
    </div>
  );
};

export default AgeClock;
