import {useEffect, useState} from 'react';

import Layout from "../../components/reusable/Layout";
import Title from "../../components/reusable/Title";
import ScaleBalance from "./ScaleBalance";
import CalsTable from "./CalsTable";
import FuelSources from "./FuelSources";
import classes from "../TrainingProgramTrianglePage/TrainingProgramTrianglePage.module.css";

const CaloricBalancePage = ({data, onSvgConverter}) => {
    const [imgLoad, setImgLoad] = useState(false);

    useEffect(() => {
        onSvgConverter("scaleBalance", "resultScale", 755);
        setImgLoad(true);
    }, [onSvgConverter]);

    let imgScaleBalance = <div id="scaleBalance" >
        <ScaleBalance data={data.SECTION1}/>
    </div>

    return (
        <Layout>
            <Title title={data.TITLE}/>
            <div id="resultScale" className={classes.imgScale}>
                {!imgLoad && imgScaleBalance}
            </div>
            <CalsTable data={data.SECTION2}/>
            <FuelSources data={data.SECTION3}/>
        </Layout>
    );
};

export default CaloricBalancePage;
