import classes from "./CalsTable.module.css";

interface Props {
  data: {
    DAYS_YOU_DONT_WORKOUT: string;
    DAYS_YOU_WORKOUT: string;
    YOU_BURN: string;
    YOU_BURN_DAYS_YOU_DONT_WORKOUT: number;
    YOU_BURN_DAYS_YOU_WORKOUT: number;
    YOU_SHOULD_EAT: string;
    YOU_SHOULD_EAT_DAYS_YOU_DONT_WORKOUT: number;
    YOU_SHOULD_EAT_DAYS_YOU_WORKOUT: number;
  };
}

const CalsTable = ({ data }: Props): JSX.Element => {
  const SECTIONS = [
    {
      INDEX: 2,
      TITLE1: data.YOU_BURN,
      TABLE1: [
        {
          INDEX: 1,
          TITLE: data.DAYS_YOU_DONT_WORKOUT,
          TEXT: data.YOU_BURN_DAYS_YOU_DONT_WORKOUT,
        },
        {
          INDEX: 2,
          TITLE: data.DAYS_YOU_WORKOUT,
          TEXT: data.YOU_BURN_DAYS_YOU_WORKOUT,
        },
      ],
      TITLE2: data.YOU_SHOULD_EAT,
      TABLE2: [
        {
          INDEX: 1,
          TITLE: data.DAYS_YOU_DONT_WORKOUT,
          TEXT: data.YOU_SHOULD_EAT_DAYS_YOU_DONT_WORKOUT,
        },
        {
          INDEX: 2,
          TITLE: data.DAYS_YOU_WORKOUT,
          TEXT: data.YOU_SHOULD_EAT_DAYS_YOU_WORKOUT,
        },
      ],
    },
  ];
  return (
    <div className={classes.section2}>
      <div className={classes.innerLeft}>
        <div className={classes.title}>{SECTIONS[0].TITLE1}</div>
        <div className={classes.subTitle}>{SECTIONS[0].TABLE1[0].TITLE}</div>
        <div className={classes.value}>{SECTIONS[0].TABLE1[0].TEXT}</div>
        <div className={classes.subTitle}>{SECTIONS[0].TABLE1[1].TITLE}</div>
        <div className={classes.value}>{SECTIONS[0].TABLE1[1].TEXT}</div>
      </div>
      <div className={classes.innerRight}>
        <div className={classes.title}>{SECTIONS[0].TITLE2}</div>
        <div className={classes.subTitle}>{SECTIONS[0].TABLE2[0].TITLE}</div>
        <div className={classes.value}>{SECTIONS[0].TABLE2[0].TEXT}</div>
        <div className={classes.subTitle}>{SECTIONS[0].TABLE2[1].TITLE}</div>
        <div className={classes.value}>{SECTIONS[0].TABLE2[1].TEXT}</div>
      </div>
    </div>
  );
};

export default CalsTable;
