import useTheme from "../../../../../common/hooks/useTheme";
import classes from "./Labels.module.css";

const Labels = (): JSX.Element => {
  const theme = useTheme();
  return (
    <div
      className={classes.pointsAgeLabels}
      style={{ color: theme.colors_ds.NEUTRAL_TEXT_STRONG }}
    >
      <div className={classes.pointAgeLabel0}>0</div>
      <div className={classes.pointAgeLabel10}>10</div>
      <div className={classes.pointAgeLabel20}>20</div>
      <div className={classes.pointAgeLabel30}>30</div>
      <div className={classes.pointAgeLabel40}>40</div>
      <div className={classes.pointAgeLabel50}>50</div>
      <div className={classes.pointAgeLabel60}>60</div>
      <div className={classes.pointAgeLabel70}>70</div>
      <div className={classes.pointAgeLabel80}>80</div>
      <div className={classes.pointAgeLabel90}>90</div>
      <div className={classes.pointAgeLabel100}>100</div>
    </div>
  );
};

export default Labels;
