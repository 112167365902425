import classes from "./NutritionPlanTab.module.css";
import NutritionBars from "./NutritionBars";
import CardWithBarChart from "./CardWithBarChart";
import PieChartWithSectors from "../common/PieChartWithSectors";
import useTheme from "../../../../common/hooks/useTheme";
import { useSelector } from "react-redux";
import { RootStatePlatform } from "../../store/platform-slice";

const NutritionPlanTab = () => {
  const theme = useTheme();
  const reportData = useSelector(
    (state: RootStatePlatform) => state.platform.dataPlatformServer.programming
  );

  const proteinAnalysisChartData = {
    title: "Protein analysis",
    sectorNames: [
      { type: 1, label: "Animal based" },
      { type: 2, label: "Plant based" },
    ],
    pieData: [
      ...reportData.nutrition_reccomendations.protein_AnimalBased_Foods.map(
        (el: any) => {
          return {
            label: el,
            value:
              reportData.nutrition_reccomendations.protein_animal_based /
              reportData.nutrition_reccomendations.protein_AnimalBased_Foods
                .length,
            categoryType: 1,
          };
        }
      ),
      ...reportData.nutrition_reccomendations.protein_PlantBased_Foods.map(
        (el: any) => {
          return {
            label: el,
            value:
              reportData.nutrition_reccomendations.protein_plant_based /
              reportData.nutrition_reccomendations.protein_PlantBased_Foods
                .length,
            categoryType: 2,
          };
        }
      ),
    ],
  };

  const fatAnalysisChartData = {
    title: "Fat analysis",
    sectorNames: [
      { type: 1, label: "MUFA" },
      { type: 2, label: "PUFA" },
    ],
    pieData: [
      ...reportData.nutrition_reccomendations.fat_MUFA_Foods.map((el: any) => {
        return {
          label: el,
          value:
            reportData.nutrition_reccomendations.mufa /
            reportData.nutrition_reccomendations.fat_MUFA_Foods.length,
          categoryType: 1,
        };
      }),
      ...reportData.nutrition_reccomendations.fat_PUFA_Foods.map((el: any) => {
        return {
          label: el,
          value:
            reportData.nutrition_reccomendations.pufa /
            reportData.nutrition_reccomendations.fat_PUFA_Foods.length,
          categoryType: 2,
        };
      }),
    ],
  };

  const carbsAnalysisChartData = {
    title: "Carbs analysis",
    sectorNames: [
      { type: 1, label: "Complex carbs" },
      { type: 2, label: "Simple carbs" },
    ],
    pieData: [
      ...reportData.nutrition_reccomendations.carbs_complex_foods.map(
        (el: any) => {
          return {
            label: el,
            value:
              (reportData.nutrition_reccomendations.wholegrains +
                reportData.nutrition_reccomendations.vegetables) /
              reportData.nutrition_reccomendations.carbs_complex_foods.length,
            categoryType: 1,
          };
        }
      ),
      ...reportData.nutrition_reccomendations.carbs_simple_foods.map(
        (el: any) => {
          return {
            label: el,
            value:
              (reportData.nutrition_reccomendations.fruits +
                reportData.nutrition_reccomendations.high_glycemic_carbs) /
              reportData.nutrition_reccomendations.carbs_simple_foods.length,
            categoryType: 2,
          };
        }
      ),
    ],
  };

  const nonWorkoutAnalysisChartData = {
    title: "Non workout days",
    pieTitle: "Macronutrient breakdown",
    calorieIntakeValue: reportData.caloric_intake_non_workout_days,
    dailyActivityValue: reportData.daily_activities_kcal,
    RMRValue: reportData.rmr,
    workoutValue: reportData.caloric_burn_non_workout_days,
    pieData: [
      {
        label: "Protein",
        value: Math.round(reportData.nutrition_reccomendations.protein_gramms),
        categoryType: 1,
      },
      {
        label: "Fat",
        value: Math.round(reportData.fat_gramms),
        categoryType: 2,
      },
      {
        label: "Carbs",
        value: Math.round(reportData.carb_gramms),
        categoryType: 3,
      },
    ],
  };

  const workoutAnalysisChartData = {
    title: "Workout days",
    pieTitle: "Macronutrient breakdown",
    calorieIntakeValue: reportData.caloric_intake_workout_days,
    dailyActivityValue: reportData.daily_activities_kcal,
    RMRValue: reportData.rmr,
    workoutValue: reportData.caloric_burn_workout_days,
    pieData: [
      {
        label: "Protein",
        value: Math.round(reportData.nutrition_reccomendations.protein_gramms),
        categoryType: 1,
      },
      {
        label: "Fat",
        value: Math.round(reportData.fat_gramms),
        categoryType: 2,
      },
      {
        label: "Carbs",
        value: Math.round(reportData.carb_gramms),
        categoryType: 3,
      },
    ],
  };

  return (
    <div className={classes.innerContainer}>
      <div className={classes.sectionOne}>
        <NutritionBars data={nonWorkoutAnalysisChartData} />
        <NutritionBars data={workoutAnalysisChartData} />
      </div>
      <div
        className={classes.box}
        style={{ borderColor: theme.colors_ds.NEUTRAL_BORDER_WEAK }}
      >
        <div
          className={classes.title}
          style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
        >
          Nutrition Breakdown
        </div>
        <div
          className={classes.text}
          style={{ color: theme.colors_ds.NEUTRAL_TEXT_STRONG }}
        >
          Explore a comprehensive nutrition analysis based on the average
          recommendations derived from your three lowest scores. This analysis
          offers insights into balanced dietary choices to support your health
          and well-being.
        </div>
      </div>
      <div className={classes.sectionTwo}>
        <div>
          <div
            className={classes.title}
            style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
          >
            Micronutrients
          </div>
          <div className={classes.cardContainer}>
            {reportData.nutrition_reccomendations.micros.map(
              (
                el: {
                  label: string;
                  current: number;
                  target: number;
                },
                index: number
              ) => (
                <CardWithBarChart key={index} data={el} />
              )
            )}
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <PieChartWithSectors chartData={proteinAnalysisChartData} />
          <PieChartWithSectors chartData={fatAnalysisChartData} />
          <PieChartWithSectors chartData={carbsAnalysisChartData} />
        </div>
      </div>
    </div>
  );
};

export default NutritionPlanTab;
