import Points from "./Points";
import Labels from "./Labels";
import Arrow from "./Arrow";

import classes from "./AgeClock.module.css";

import arrowChronological from "../../../images/ArrowChronologicalNew.png";
import arrowBiological from "../../../images/ArrowBiologicalNew.png";
import { useSelector } from "react-redux";
import { RootStatePlatform } from "../../../store/platform-slice";
import useTheme from "../../../../../common/hooks/useTheme";

type LegendProps = {
  label: string;
  color: string;
};

const AgeClock = (): JSX.Element => {
  const theme = useTheme();

  const reportData = useSelector(
    (state: RootStatePlatform) => state.platform.dataPlatformServer.dashboard
  );

  const LegendItem = ({ label, color }: LegendProps) => {
    return (
      <div className={classes.legendItem}>
        <div className={classes.dataPoint} style={{ backgroundColor: color }} />
        <div className={classes.legendItemLabel}>{label}</div>
      </div>
    );
  };

  return (
    <div className={classes.ageComparisonContainer}>
      <div
        className={classes.title}
        style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
      >
        Age Comparison
      </div>
      <div
        className={classes.box}
        style={{ borderColor: theme.colors_ds.NEUTRAL_BORDER_WEAK }}
      >
        <div className={classes.legend}>
          <LegendItem label="Biological age" color={theme.colorTokens.S[60]} />
          <LegendItem
            label="Chronological age"
            color={theme.colorTokens.W[60]}
          />
        </div>
        <div className={classes.container} id="svg">
          <svg
            id="svg"
            width="474"
            height="247"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3,247 a60,60 1 0,1 467,0"
              fill="transparent"
              stroke={theme.colors_ds.NEUTRAL_TEXT_WEAK}
              strokeWidth={2}
            />
          </svg>
          <Arrow
            arrowIcon={arrowChronological}
            age={reportData.age}
            arrowHeight="185px"
            arrowColor={theme.colorTokens.W[60]}
          />
          <Arrow
            arrowIcon={arrowBiological}
            age={reportData.biological_age}
            arrowHeight="205px"
            arrowColor={theme.colorTokens.S[60]}
            arrowContainerHeight="310px"
          />
          <Points />
          <Labels />
          <div className={classes.mainLabel}>
            <div
              className={classes.value}
              style={{ color: theme.colors_ds.NEUTRAL_TEXT_STRONG }}
            >
              {reportData.biological_age - reportData.age}
            </div>
            <div
              className={classes.description}
              style={{ color: theme.colors_ds.NEUTRAL_TEXT_STRONG }}
            >
              compared to your chronological
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgeClock;
