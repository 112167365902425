import AuthPage from "./modules/authentication/screens/AuthPage";
import { isMobile } from "react-device-detect";
import RootNavigation from "./navigation/RootNavigation";

import classes from "./App.module.css";
import backgroundImg from "./common/images/backgroundImg.png";
import logo from "./common/images/logo.png";
import { useState, useEffect, useCallback } from "react";

import "./common/fonts/AktivGroteskEx_A_Bd-normal";
import "./common/fonts/AktivGroteskEx_A_Lt-normal";
import "./common/fonts/AktivGroteskEx_A_Md-normal";
import "./common/fonts/AktivGroteskEx_A_Rg-normal";
import "./common/fonts/AktivGroteskEx_A_XBd-normal";
import "./common/fonts/Poppins-Light-normal";

import ThemeProvider from "./providers/ThemeProvider";
import { AppStoreProvider } from "./providers/AppStore";

function App() {
  const appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty("--app-height", `${window.innerHeight}px`);
  };
  window.addEventListener("resize", appHeight);
  appHeight();

  const appWidth = () => {
    const doc = document.documentElement;
    doc.style.setProperty("--app-width", `${window.innerWidth}px`);
  };
  window.addEventListener("resize", appWidth);
  appWidth();

  useEffect(() => {
    appHeight();
    appWidth();
  }, []);

  const isLandscape = () =>
      window.matchMedia("(orientation:landscape)").matches,
    [orientation, setOrientation] = useState(
      isLandscape() ? "landscape" : "portrait"
    ),
    onWindowResize = () => {
      clearTimeout(window.resizeLag);
      window.resizeLag = setTimeout(() => {
        delete window.resizeLag;
        setOrientation(isLandscape() ? "landscape" : "portrait");
      }, 200);
    };

  useEffect(
    () => (
      onWindowResize(),
      window.addEventListener("resize", onWindowResize),
      () => window.removeEventListener("resize", onWindowResize)
    ),
    []
  );

  return (
    <>
      {/*{!isMobile && (*/}
      <ThemeProvider>
        <AppStoreProvider>
          <RootNavigation />
        </AppStoreProvider>
      </ThemeProvider>
      {/*)}*/}
      {/*{isMobile && (*/}
      {/*  <DeviceOrientation lockOrientation={"portrait"}>*/}
      {/*    <Orientation orientation="portrait" alwaysRender={false}>*/}
      {/*      <AuthPage />*/}
      {/*    </Orientation>*/}

      {/*    <Orientation orientation="landscape" alwaysRender={false}>*/}
      {/*      <div className={classes.landscapeMode}>*/}
      {/*        <div*/}
      {/*          className={classes.container}*/}
      {/*          style={{*/}
      {/*            backgroundImage: `url('${backgroundImg}')`,*/}
      {/*            backgroundPosition: "top",*/}
      {/*            backgroundSize: "cover",*/}
      {/*            height: "var(--app-height)",*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          <div className={classes.imgContainer}>*/}
      {/*            <img className={classes.logo} src={logo} />*/}
      {/*          </div>*/}
      {/*          <div className={classes.text}>*/}
      {/*            This page is not available in landscape version*/}
      {/*          </div>*/}
      {/*          <div className={classes.subText}>Please rotate your device</div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </Orientation>*/}
      {/*  </DeviceOrientation>*/}
      {/*)}*/}
    </>
  );
}

export default App;
