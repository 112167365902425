import classes from "./Pillar.module.css";

import rectangle50 from "../../images/pillars/rectangle50.png";

interface Props {
  data: { DESCRIPTION: string; ICON: string; INDEX: number; TITLE: string };
}

const Pillar = ({ data }: Props): JSX.Element => {
  const iconSource: string = data.ICON
    ? require("../../images/pillars/" + data.ICON)
    : false;

  return (
    <div className={classes.pillar}>
      <img className={classes.icon} src={iconSource} alt="pillar icon" />
      <div className={classes.container}>
        <img
          className={classes.imgBackground}
          src={rectangle50}
          alt="background"
        />
        <div className={classes.pillarTitleHide}>{data.TITLE}</div>
        <div className={classes.descriptionHide}>{data.DESCRIPTION}</div>
        <div className={classes.inner}>
          <div className={classes.pillarTitle}>{data.TITLE}</div>
          <div className={classes.description}>{data.DESCRIPTION}</div>
        </div>
      </div>
    </div>
  );
};

export default Pillar;
