import Button from "../../../../common/ui/Button";
import { useNavigate } from "react-router-dom";

import classes from "./IntroPage.module.css";
import React, { useEffect } from "react";
import backgroundImg from "../../../../common/images/backgroundImg.png";
import { useAppStore } from "../../../../providers/AppStore";
import Logo from "../../../../common/svgs/logo";
import useTheme from "../../../../common/hooks/useTheme";
import sideAuthImg from "../../../../common/images/backgroundImgMobile.png";

const IntroPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const handleStart = () => {
    navigate("/biometric-questionnaire");
  };
  const { ASIsUserLoaded } = useAppStore();

  useEffect(() => {
    if (!ASIsUserLoaded()) {
      navigate("/auth");
    }
  }, []);

  return (
    <div className={classes.container}>
      <div
        className={classes.side}
        style={{
          backgroundImage: `url('${sideAuthImg}')`,
          backgroundPosition: "top center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "100vh",
        }}
      />
      <div
        className={classes.main}
        style={{
          color: theme.colors_ds.NEUTRAL_TEXT_STRONG,
          backgroundColor: theme.colors_ds.NEUTRAL_BG_MAIN,
        }}
      >
        <div className={classes.inner}>
          <div
            className={classes.title}
            style={{ color: theme.colors_ds.NEUTRAL_TEXT_STRONG }}
          >
            Welcome to our biometric questionnaire!
          </div>
          <div className={classes.subTitle}>
            Our experts have put together a few questions to provide the optimal
            training and nutrition recommendations that work for your lifestyle.
          </div>
          <div className={classes.btn}>
            <Button text="Start" customWidth="150px" onClick={handleStart} />
            <div
              className={classes.subtitle2}
              style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
            >
              Will only take 6 min
            </div>
          </div>
        </div>
        <div className={classes.btnMobile}>
          <Button text="Start" customWidth="100%" onClick={handleStart} />
          <div
            className={classes.subtitle2}
            style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
          >
            Will only take 6 min
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroPage;
