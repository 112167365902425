import useTheme from "../hooks/useTheme";

const Male_icon = () => {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
    >
      <path
        d="M25 0.5V7.5H37.6L27.8 17.3C25 15.55 21.5 14.5 18 14.5C8.2 14.5 0.5 22.2 0.5 32C0.5 41.8 8.2 49.5 18 49.5C27.8 49.5 35.5 41.8 35.5 32C35.5 28.5 34.45 25 32.7 22.2L42.5 12.4V25H49.5V0.5H25ZM18 42.5C12.05 42.5 7.5 37.95 7.5 32C7.5 26.05 12.05 21.5 18 21.5C23.95 21.5 28.5 26.05 28.5 32C28.5 37.95 23.95 42.5 18 42.5Z"
        fill={theme.colors_ds.NEUTRAL_ICON_DEFAULT}
      />
    </svg>
  );
};

export default Male_icon;
