import ENDPOINTS from "./Endpoints";
import {
  PrecisionIDBaseApi,
  PrecisionNoAuthtokenPlatformBaseApi,
  PrecisionNUTBaseApi,
  PrecisionPlatformBaseApi,
} from "./AppApiManager";

export const loginUser = async (data: {
  client: string;
  email: string;
  password: string;
}) => {
  const client = await PrecisionNoAuthtokenPlatformBaseApi();
  console.log("data login", data);
  const response = await client.post(ENDPOINTS.PLATFORM.LOGIN_URL, data);
  console.log("TEST", response);
  return response.data;
};

export const getReportDataPlatform = async (
  id: string,
  type: string,
  lang: string
) => {
  const client = await PrecisionNoAuthtokenPlatformBaseApi();

  const response = await client.get(
    `${ENDPOINTS.PLATFORM.GET_REPORT_DATA_PLATFORM}/${id}/${type}/${lang}`
  );

  return response.data;
};

export const getReportDataUser = async (
  id: string,
  type: string,
  lang: string
) => {
  const client = await PrecisionPlatformBaseApi();

  const response = await client.get(
    `${ENDPOINTS.PLATFORM.GET_REPORT_DATA}/${id}/${type}/${lang}`
  );

  return response.data;
};

export const getUserInfo = async () => {
  const client = await PrecisionPlatformBaseApi();

  const response = await client.get(ENDPOINTS.PLATFORM.GET_USER_INFO);
  return response.data;
};

export const settings = async () => {
  const client = await PrecisionNUTBaseApi();
  const response = await client.get(ENDPOINTS.NUTRITION.SETTINGS_URL);
  return response.data;
};

export const getCustomers = async (
  page: number = 0,
  rows: number = 0,
  sort: string = "FirstName",
  sortOrder: "asc" | "desc" = "asc"
) => {
  const client = await PrecisionPlatformBaseApi();

  const response = await client.get(
    `${ENDPOINTS.PLATFORM.GET_CUSTOMERS}/${page}/${rows}/${sort}/${sortOrder}
  `
  );

  return response.data;
};

export const getCustomerEmail = async (id: string) => {
  const client = await PrecisionNoAuthtokenPlatformBaseApi();

  const response = await client.get(
    `${ENDPOINTS.PLATFORM.GET_CUSTOMER_EMAIL}${id}`
  );

  return response.data;
};

export const registerUser = async (data: any) => {
  const client = await PrecisionNoAuthtokenPlatformBaseApi();

  const response = await client.post(ENDPOINTS.PLATFORM.REGISTER_USER, data);

  return response.data;
};

export const submitBiometricQuestionnaireToNutServer = async (data: any) => {
  const client = await PrecisionNUTBaseApi();
  const response = await client.post(
    ENDPOINTS.NUTRITION.SUBMIT_BIOMETRIC_QUESTIONNAIRE,
    data
  );
  return response.data;
};

export const submitLifestyleQuestionnaireToNutServer = async (data: any) => {
  const client = await PrecisionNUTBaseApi();
  const response = await client.post(
    ENDPOINTS.NUTRITION.SUBMIT_LIFESTYLE_QUESTIONNAIRE,
    data
  );
  return response.data;
};

export const submitBiometricQuestionnaireToPlatformServer = async (
  data: any
) => {
  const client = await PrecisionNoAuthtokenPlatformBaseApi();

  const response = await client.post(
    ENDPOINTS.PLATFORM.SUBMIT_BIOMETRIC_QUESTIONNAIRE,
    data
  );

  return response.data;
};

export const submitBiometricQuestionnaireToNUTServerFailed = async (
  data: any
) => {
  const client = await PrecisionNoAuthtokenPlatformBaseApi();

  const response = await client.post(
    ENDPOINTS.PLATFORM.SUBMIT_BIOMETRIC_QUESTIONNAIRE_TO_NUT_FAILED,
    data
  );

  return response.data;
};

export const requestPasswordReset = async (email: { email: string }) => {
  const client = await PrecisionNoAuthtokenPlatformBaseApi();

  const response = await client.get(
    `${ENDPOINTS.PLATFORM.REQUEST_RESET_PASSWORD}${email}`
  );

  return response.data;
};

export const PasswordReset = async (data: {
  password: string;
  passwordConfirm?: string;
  token?: string | null;
}) => {
  const client = await PrecisionNoAuthtokenPlatformBaseApi();

  const response = await client.post(ENDPOINTS.PLATFORM.RESET_PASSWORD, data);

  return response.data;
};

export const getPlatformData = async (customerID: string) => {
  const client = await PrecisionPlatformBaseApi();

  const response = await client.get(
    `${ENDPOINTS.PLATFORM.GET_PLATFORM_DATA}${customerID}`
  );

  return response.data;
};

export const getHomePage = async (date: string) => {
  const client = await PrecisionNUTBaseApi();
  const response = await client.get(
    `${ENDPOINTS.NUTRITION.FETCH_HOME_URL}` + "?date=" + `${date}`
  );
  return response.data;
};

export const getAllIntegrations = async () => {
  const client = await PrecisionNUTBaseApi();
  const response = await client.get(
    ENDPOINTS.NUTRITION.INTEGRATIONS_STATUS_URL
  );
  return response.data;
};
