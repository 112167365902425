import classes from "./TestItem.module.css";

import testIcon from "../../images/testIcon.png";

type TestType = { INDEX: number; VALUE: string };

interface Props {
  item: {
    INDEX: number;
    PERIOD: string | null;
    TITLE: string;
    TYPE: TestType[];
  };
}

const TestItem = ({ item }: Props): JSX.Element => {
  const iconColorHandler = (type: number): {} => {
    let iconColor;
    switch (type) {
      case 1:
        iconColor = { backgroundColor: "var(--secondary21)" };
        break;
      default:
        iconColor = { backgroundColor: "var(--secondary10)" };
    }
    return iconColor;
  };

  return (
    <>
      <div className={classes.date}>{item.TITLE}</div>
      <div className={classes.inner}>
        {item.INDEX === 2 ? (
          <img
            className={classes.highlightedIcon}
            src={testIcon}
            alt="highlighted test"
          />
        ) : (
          <div className={classes.icon}></div>
        )}

        <div className={classes.line}></div>
        <div className={classes.period}>{item.PERIOD}</div>
      </div>
      <ul className={classes.type}>
        {item.TYPE.map((tab) => (
          <li key={tab.VALUE} className={classes.typeLabel}>
            <div
              className={classes.typeIcon}
              style={iconColorHandler(tab.INDEX)}
            ></div>
            {tab.VALUE}
          </li>
        ))}
      </ul>
    </>
  );
};

export default TestItem;
