import classes from "./BenefitTab.module.css";

import TabChartBar from "../../components/reusable/TabChartBar";

import arrowLeft from "../../images/arrowLeft.png";

interface Props {
  data: {
    INDEX: number;
    TEXT: string;
    TITLE: string;
    VALUE: number;
  };
  type: number;
}

const BenefitTab = ({ type, data }: Props) => {
  return (
    <div className={classes.tabs} style={type === 1 ? { height: "84px" } : {}}>
      <div className={classes.tabTitle}>{data.TEXT}</div>
      <img src={arrowLeft} className={classes.iconArrow} />
      {type === 1 && (
        <div className={classes.tabBar}>
          <TabChartBar label={data.TITLE} value={data.VALUE} />
        </div>
      )}
      {type === 2 && <div className={classes.tabText}>{data.TITLE}</div>}
    </div>
  );
};

export default BenefitTab;
