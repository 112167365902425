import classes from "./Factor.module.css";

import arrowDown from "../../images/arrowDown.png";
import arrowUp from "../../images/arrowUp.png";

interface Props {
  factor: string;
  valueArrow: number;
}

const Factor = ({ factor, valueArrow }: Props): JSX.Element => {
  return (
    <div className={classes.factors}>
      <div className={classes.factor}>{factor}</div>
      {/*// When value is -1 we show the arrow up icon*/}
      {/*// When value is 1 we show the arrow down icon*/}
      <img
        className={classes.arrow}
        src={(valueArrow === 1 && arrowDown) || (valueArrow === -1 && arrowUp)}
        alt="arrow shows how the factor affects the biological age"
      />
    </div>
  );
};

export default Factor;
