import classes from "./NavItem.module.css";

interface Props {
  title: string;
  value: number;
  index: number;
}

const NavItem = ({ title, value, index }: Props): JSX.Element => {
  let iconColor;
  let tabType = `${classes.navItem}`;

  switch (index) {
    case 1:
      iconColor = `${classes.icon} ${classes.severeLimitation}`;
      break;
    case 2:
      iconColor = `${classes.icon} ${classes.limitations}`;
      break;
    case 3:
      iconColor = `${classes.icon} ${classes.neutral}`;
      break;
    case 4:
      iconColor = `${classes.icon} ${classes.good}`;
      break;
    case 5:
      iconColor = `${classes.icon} ${classes.excellent}`;
      tabType = `${classes.navItemExcellent}`;
      break;
    default:
      tabType = `${classes.navItem} ${classes.core}`;
  }

  return (
    <div className={tabType}>
      <div className={classes.value}>{value}</div>
      <div className={classes.category}>
        <div className={classes.title}>{title} </div>
        {title !== "core limitations" && <div className={iconColor}></div>}
      </div>
    </div>
  );
};

export default NavItem;
