export const kgToPd = (value) => {
  return Math.round(value * 2.205);
};

export const pdToKg = (value) => {
  return Math.round(value * 0.45359237);
};

export const pdToPdAndOz = (value) => {
  const oz = value * 16;
  return { value1: Math.floor(oz / 16), value2: Math.round(oz % 16) };
};

export const PdAndOzToPd = (value1, value2) => {
  return value1 + value2 * 0.0625;
};

export const cmToIn = (value) => {
  return Math.round(value * 0.3937);
};

export const inToCm = (value) => {
  return Math.round(value * 2.54);
};

export const inToFtAndIn = (value) => {
  return { value1: Math.floor(value / 12), value2: Math.round(value % 12) };
};

export const ftAndInToIn = (value1, value2) => {
  return Math.round(value1 * 12 + value2);
};
