import { useDispatch, useSelector } from "react-redux";
import { answersActions } from "../../store/answers-slice";

import Scale from "../reusable/Scale";
import {
  ftAndInToIn,
  inToFtAndIn,
  PdAndOzToPd,
  pdToPdAndOz,
} from "../../utils";
import {
  MAX_HEIGHT_FT,
  MAX_HEIGHT_IN,
  MAX_WEIGHT_LB,
  MAX_WEIGHT_OZ,
} from "../../constant";

import classes from "./ScaleForm.module.css";

const DoubleScaleForm = ({ scaleType }) => {
  const dispatch = useDispatch();
  const stepper = useSelector((state) => state.stepper.step);
  const formData = useSelector((state) => state.stepper.formData);
  const answers = useSelector((state) => state.answers.biometricQuest);

  let initialValueOne, initialValueTwo;
  if (scaleType === "massUnit") {
    initialValueOne = pdToPdAndOz(
      answers.myAnswers[formData[stepper - 1].category]
    ).value1;
    initialValueTwo = pdToPdAndOz(
      answers.myAnswers[formData[stepper - 1].category]
    ).value2;
  }

  if (scaleType === "lengthUnit") {
    initialValueOne = inToFtAndIn(
      answers.myAnswers[formData[stepper - 1].category]
    ).value1;
    initialValueTwo = inToFtAndIn(
      answers.myAnswers[formData[stepper - 1].category]
    ).value2;
  }
  const dispatchAnswer = (num, id) => {
    if (scaleType === "massUnit") {
      dispatch(
        answersActions.updateBiometricAnswers({
          [formData[stepper - 1].category]: PdAndOzToPd(
            id === 1 ? num : initialValueOne,
            id === 2 ? num : initialValueTwo
          ),
        })
      );
    }
    if (scaleType === "lengthUnit") {
      dispatch(
        answersActions.updateBiometricAnswers({
          [formData[stepper - 1].category]: ftAndInToIn(
            id === 1 ? num : initialValueOne,
            id === 2 ? num : initialValueTwo
          ),
        })
      );
    }
  };

  return (
    <div className={classes.container} style={{ width: "55%" }}>
      <Scale
        id={1}
        dispatchAnswer={dispatchAnswer}
        label={scaleType === "massUnit" ? "lb" : "ft"}
        answer={initialValueOne}
        max={scaleType === "massUnit" ? MAX_WEIGHT_LB : MAX_HEIGHT_FT}
        min={scaleType === "massUnit" ? 0 : 0}
      />
      <Scale
        id={2}
        dispatchAnswer={dispatchAnswer}
        label={scaleType === "massUnit" ? "oz" : "in"}
        answer={initialValueTwo}
        max={scaleType === "massUnit" ? MAX_WEIGHT_OZ : MAX_HEIGHT_IN}
        min={scaleType === "massUnit" ? 0 : 0}
      />
    </div>
  );
};

export default DoubleScaleForm;
