import classes from "./PlatformTable.module.css";
import useTheme from "../../../../common/hooks/useTheme";

type Props = {
  data: any;
  title?: string;
  dataPointColor?: string;
  hasSmallFontInTitle?: boolean;
  hasUnevenColumns?: boolean;
};
const PlatformTable = ({
  data,
  title,
  dataPointColor,
  hasSmallFontInTitle,
  hasUnevenColumns,
}: Props) => {
  const theme = useTheme();

  const headerArray: string[] = Object.values(data[0]);

  const rowArrays = data.slice(1).map((item: any) => Object.values(item));

  const calculateFlexValue = (
    hasUnevenColumns: boolean | undefined,
    index: number
  ): number => {
    if (!hasUnevenColumns) return 1;
    switch (index) {
      case 0:
        return 3;
      case 1:
      case 2:
        return 2;
      default:
        return 1;
    }
  };

  return (
    <div className={classes.container}>
      {title && (
        <div
          className={`${classes.titleContainer} ${
            hasSmallFontInTitle ? classes.smallTitleContainer : ""
          }`}
        >
          {dataPointColor && (
            <div
              className={classes.dataPoint}
              style={{ backgroundColor: dataPointColor }}
            />
          )}
          <div
            className={hasSmallFontInTitle ? classes.smallTitle : classes.title}
            style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
          >
            {title}
          </div>
        </div>
      )}

      <div
        className={classes.tableContainer}
        style={{ borderColor: theme.colors_ds.NEUTRAL_BORDER_WEAK }}
      >
        <div
          className={classes.tableRow}
          style={{
            color: theme.colors_ds.NEUTRAL_TEXT_STRONG,
            border: "none",
            padding: "0px 16px 4px",
          }}
        >
          {headerArray.map((el: string, index: number) => (
            <div
              key={index}
              className={classes.tableHeaderCell}
              style={{
                flex: calculateFlexValue(hasUnevenColumns, index),
              }}
            >
              {el}
            </div>
          ))}
        </div>
        {rowArrays.map((item: string[], index: number) => (
          <div
            key={index}
            className={classes.tableRow}
            style={{
              color: theme.colors_ds.NEUTRAL_TEXT_STRONG,
              borderTopColor: theme.colors_ds.NEUTRAL_GENERAL_WHITE,
            }}
          >
            {item.map((el: string, index: number) => (
              <div
                key={index}
                className={classes.tableCell}
                style={{
                  flex: calculateFlexValue(hasUnevenColumns, index),
                }}
              >
                {el}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlatformTable;
