import React, { useEffect, useRef, useState } from "react";
import classes from "./ClientsList.module.css";
import useTheme from "../../../../common/hooks/useTheme";
import MoreIcon from "../../svg/MoreIcon";
import CheckedIcon from "../../svg/CheckedIcon";
import UncheckedIcon from "../../svg/UncheckedIcon";
import { getCustomers } from "../../../../common/network/Api";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { ClientType } from "../../../../common/entities/profile";

type Props = {
  clientFilter: string;
  clientRowGroupRefs: any;
  onClientSelected: Function;
};

const ClientsList = ({
  clientFilter,
  clientRowGroupRefs,
  onClientSelected,
}: Props) => {
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(false);

  const [clients, setClients] = useState<ClientType[]>([]);

  const fetchClients = async () => {
    try {
      setIsLoading(true);
      const customers = await getCustomers();
      setClients(customers.data);
    } catch (error) {
      // TODO :: UI :: Handle this case
    }
    setIsLoading(false);
  };

  // Fetch client list for the affiliates
  useEffect(() => {
    fetchClients();
  }, []);

  // Filter clients based on the search text
  const filteredClients =
    clients &&
    clients.filter((client) => {
      const fullName = `${client.firstName} ${client.lastName}`.toLowerCase();
      return (
        fullName.includes(clientFilter.toLowerCase()) ||
        client.email.toLowerCase().includes(clientFilter.toLowerCase())
      );
    });

  // Function to render the first letter of the client's name
  const renderClientRowGroupLetter = (
    letter: string,
    shouldDisplayLetter: boolean
  ) => {
    if (shouldDisplayLetter) {
      return (
        <div
          ref={(el) => (clientRowGroupRefs.current[letter] = el)}
          className={classes.tableBodyRowLetter}
          style={{
            borderColor: theme.colors_ds.NEUTRAL_BORDER_DEFAULT,
            color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT,
          }}
        >
          {letter}
        </div>
      );
    }
    return null;
  };

  // Render a single client row
  const renderClientRow = (
    client: any,
    index: number,
    shouldDisplayLetter: boolean
  ) => {
    const firstLetter = client.firstName.charAt(0).toUpperCase();

    // Inner function to render the check/uncheck icon
    const renderCheckIcon = (isChecked: boolean) => {
      const Icon = isChecked ? CheckedIcon : UncheckedIcon;
      return <Icon fill={theme.colors_ds.NEUTRAL_ICON_DEFAULT} />;
    };

    return (
      <div
        key={index}
        className={classes.tableBodyRowContainer}
        onClick={() => onClientSelected(client, index)}
      >
        <div className={classes.tableBodyRowLetterContainer}>
          {renderClientRowGroupLetter(firstLetter, shouldDisplayLetter)}
        </div>
        <div
          className={classes.tableBodyRow}
          style={{
            color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT,
            borderColor: theme.colors_ds.NEUTRAL_BORDER_DEFAULT,
          }}
        >
          <div className={classes.tableBodyRowItem} style={{ flex: "1" }}>
            {client.firstName}
          </div>
          <div className={classes.tableBodyRowItem} style={{ flex: "1" }}>
            {client.lastName}
          </div>
          <div className={classes.tableBodyRowItem} style={{ flex: "2" }}>
            {client.email}
          </div>
          <div
            className={`${classes.tableBodyRowItem} ${classes.tableBodyRowItem3}`}
            style={{ flex: "0.5" }}
          >
            {renderCheckIcon(client.report)}
          </div>
          <div
            className={`${classes.tableBodyRowItem} ${classes.tableBodyRowItem3}`}
            style={{ flex: "0.5" }}
          >
            {renderCheckIcon(client.nutrition)}
          </div>
          <div
            className={`${classes.tableBodyRowItem} ${classes.tableBodyRowItem3}`}
            style={{ flex: "0.5" }}
          >
            {renderCheckIcon(client.workout)}
          </div>
          {/*<div className={`${classes.tableBodyRowItem} ${classes.tableBodyRowItem4}`} style={{ flex: '0.25' }}>*/}
          {/*  <button className={classes.moreButton}>*/}
          {/*    <MoreIcon fill={theme.colors_ds.NEUTRAL_ICON_DEFAULT} />*/}
          {/*  </button>*/}
          {/*</div>*/}
        </div>
      </div>
    );
  };

  // Render all client rows
  const renderClientRows = () => {
    if (isLoading) {
      return (
        <Skeleton
          count={10}
          height={46}
          borderRadius={8}
          style={{ lineHeight: 0, paddingBottom: "16px" }}
        />
      );
    }

    if (!clients || clients.length === 0) {
      return <div className={classes.noClients}>No clients found</div>;
    }

    const displayedFirstLetters = new Set();
    return filteredClients.map((client, index) => {
      const firstLetter = client.firstName.charAt(0).toUpperCase();
      const shouldDisplayLetter = !displayedFirstLetters.has(firstLetter);

      if (shouldDisplayLetter) {
        displayedFirstLetters.add(firstLetter);
      }

      return renderClientRow(client, index, shouldDisplayLetter);
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.table}>
        <div
          className={classes.tableHeader}
          style={{ color: theme.colors_ds.NEUTRAL_TEXT_WEAK }}
        >
          <div className={classes.tableHeaderItem} style={{ flex: "1" }}>
            First Name
          </div>
          <div className={classes.tableHeaderItem} style={{ flex: "1" }}>
            Last Name
          </div>
          <div className={classes.tableHeaderItem} style={{ flex: "2" }}>
            Email Account
          </div>
          <div
            className={`${classes.tableHeaderItem} ${classes.tableHeaderItem3}`}
            style={{ flex: "0.5" }}
          >
            Report
          </div>
          <div
            className={`${classes.tableHeaderItem} ${classes.tableHeaderItem3}`}
            style={{ flex: "0.5" }}
          >
            Nutrition
          </div>
          <div
            className={`${classes.tableHeaderItem} ${classes.tableHeaderItem3}`}
            style={{ flex: "0.5" }}
          >
            Workout
          </div>
          {/*<div*/}
          {/*  className={`${classes.tableHeaderItem} ${classes.tableHeaderItem4}`}*/}
          {/*  style={{ flex: "0.25" }}*/}
          {/*>*/}
          {/*  /!*PLACEHOLDER*!/*/}
          {/*</div>*/}
        </div>
        <div className={classes.tableBody}>{renderClientRows()}</div>
      </div>
    </div>
  );
};

export default ClientsList;
