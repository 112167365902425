import { useEffect, useState } from "react";

import Layout from "../../components/reusable/Layout";
import Title from "../../components/reusable/Title";
import CustomRadarChart from "../../components/reusable/CustomRadarChart";

import classes from "./TrainingProgramTrianglePage.module.css";

type ChartData = { INDEX: number; NAME: string; VALUE: number };

interface Props {
  data: {
    DESCRIPTION: string;
    GRAPH_VALUES: ChartData[];
    INDEX: number;
    TITLE: string;
  };
  onSvgConverter: (
    content: string,
    result: string,
    imgWidth: number
  ) => JSX.Element;
}

const TrainingProgramTrianglePage = ({
  data,
  onSvgConverter,
}: Props): JSX.Element => {
  const [imgLoad, setImgLoad] = useState(false);
  const chartWidth: number = 700;
  const chartHeight: number = 600;

  useEffect(() => {
    onSvgConverter("customRadarChart", "result", 700);
    setImgLoad(true);
  }, [onSvgConverter]);

  let imgChart = (
    <div
      id="customRadarChart"
      className={classes.chartContainer}
      style={{ width: chartWidth, height: chartHeight }}
    >
      <CustomRadarChart
        data={data.GRAPH_VALUES}
        dataLabels={data.GRAPH_VALUES}
        height={chartHeight - 100}
        width={chartWidth}
        typeOfLabelCustom="triangle"
        isAxisCustom={true}
        radius="86%"
        gridStroke="transparent"
        showTicks={false}
        startAngle={90}
        endAngle={-270}
        maxDomain={70}
        tickCount={8}
        title=""
        footer1=""
        footer2=""
      />
    </div>
  );

  return (
    <Layout>
      <Title title={data.TITLE} />
      <div id="result" className={classes.imgRadarChart}>
        {!imgLoad && imgChart}
      </div>
      <div className={classes.description}>{data.DESCRIPTION}</div>
    </Layout>
  );
};

export default TrainingProgramTrianglePage;
