import React from "react";
import validator from "validator";

import X_icon from "../../../common/svgs/X_icon";
import Success_icon from "../../../common/svgs/Success_icon";

import classes from "./PasswordValidator.module.css";
import useTheme from "../../../common/hooks/useTheme";

type Props = {
  curPassword: string;
};
const PasswordValidator = ({ curPassword }: Props) => {
  const theme = useTheme();
  const validateCorrectLength = (value: string) => {
    return (
      value.length <= 20 &&
      validator.isStrongPassword(value, {
        minLength: 8,
        minLowercase: 0,
        minUppercase: 0,
        minNumbers: 0,
        minSymbols: 0,
      })
    );
  };

  const validateMinUppercase = (value: string) => {
    return validator.isStrongPassword(value, {
      minLength: 0,
      minLowercase: 0,
      minUppercase: 1,
      minNumbers: 0,
      minSymbols: 0,
    });
  };

  const validateMinLowercase = (value: string) => {
    return validator.isStrongPassword(value, {
      minLength: 0,
      minLowercase: 1,
      minUppercase: 0,
      minNumbers: 0,
      minSymbols: 0,
    });
  };

  const validateMinNumber = (value: string) => {
    return validator.isStrongPassword(value, {
      minLength: 0,
      minLowercase: 0,
      minUppercase: 0,
      minNumbers: 1,
      minSymbols: 0,
    });
  };

  const validateMinSymbol = (value: string) => {
    return validator.isStrongPassword(value, {
      minLength: 0,
      minLowercase: 0,
      minUppercase: 0,
      minNumbers: 0,
      minSymbols: 1,
    });
  };

  return (
    <div>
      <div
        className={classes.title}
        style={{ color: theme.colors_ds.NEUTRAL_TEXT_STRONG }}
      >
        Your Password must contain
      </div>
      <ul
        className={classes.list}
        style={{ borderColor: theme.colors_ds.NEUTRAL_BORDER_DEFAULT }}
      >
        <li
          className={classes.listItem}
          style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
        >
          {validateCorrectLength(curPassword) ? <Success_icon /> : <X_icon />}
          <div>Between 8 and 20 characters</div>
        </li>
        <li
          className={classes.listItem}
          style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
        >
          {validateMinUppercase(curPassword) ? <Success_icon /> : <X_icon />}{" "}
          <div>One upper case letter</div>
        </li>
        <li
          className={classes.listItem}
          style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
        >
          {validateMinLowercase(curPassword) ? <Success_icon /> : <X_icon />}{" "}
          <div>One lower case letter</div>
        </li>
        <li
          className={classes.listItem}
          style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
        >
          {validateMinSymbol(curPassword) ? <Success_icon /> : <X_icon />}{" "}
          <div>One special character</div>
        </li>
        <li
          className={classes.listItem}
          style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
        >
          {validateMinNumber(curPassword) ? <Success_icon /> : <X_icon />}{" "}
          <div>One number</div>
        </li>
      </ul>
    </div>
  );
};

export default PasswordValidator;
