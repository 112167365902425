import CardDescription from "../../components/reusable/CardDescription";
import CustomCard from "../../components/reusable/CustomCard";

import classes from "./SectionCard.module.css";

type rows = {
  INDEX: number;
  TEXT: string;
  TITLE: string;
  VALUE?: number;
};

interface Props {
  data: { ROWS: rows[]; INDEX: number; TITLE: string };
}

const SectionCard = ({ data }: Props): JSX.Element => {
  return (
    <CustomCard cardPadding="20px 14px">
      <div className={classes.title}>
        <div>{data.TITLE}</div>
      </div>

      <div className={classes.description}>
        <ul className={classes.columns}>
          {data.ROWS.map((item) => (
            <li key={item.INDEX} className={classes.sub}>
              <CardDescription
                title={item.TITLE}
                description={item.TEXT}
                index={item.INDEX}
                customWidth="222px"
                customMargin={0}
                usecustomStyle={true}
              />
            </li>
          ))}
        </ul>
      </div>
    </CustomCard>
  );
};

export default SectionCard;
