import React, { MouseEventHandler } from "react";

const RestDayIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <g clipPath="url(#clip0_870_6796)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.75 0H5.25V1.5H6.75V0ZM10.7203 2.28866L9.65991 1.22756L8.59881 2.28791L9.65916 3.34901L10.7203 2.28866ZM10.5 5.25H12V6.75H10.5V5.25ZM8.65155 9.76457L9.71265 10.8249L10.773 9.76382L9.7119 8.70347L8.65155 9.76457ZM5.25 12V10.5H6.75V12H5.25ZM1.17427 9.71141L2.23462 10.7725L3.29572 9.71216L2.23537 8.65106L1.17427 9.71141ZM0 5.25H1.5V6.75H0V5.25ZM3.34845 2.34025L2.28735 1.2799L1.227 2.341L2.2881 3.40135L3.34845 2.34025ZM3 6C3 4.35 4.35 3 6 3C7.65 3 9 4.35 9 6C9 7.65 7.65 9 6 9C4.35 9 3 7.65 3 6Z"
          fill="#6D7075"
        />
      </g>
      <defs>
        <clipPath id="clip0_870_6796">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RestDayIcon;
