import { Loader } from "semantic-ui-react";

const styleLink = document.createElement("link");
styleLink.rel = "stylesheet";
styleLink.href =
  "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css";
document.head.appendChild(styleLink);

const LoadingSpinner = ({ size, color }) => (
  <Loader active inline size={size} style={{ color: color }} />
);

export default LoadingSpinner;
