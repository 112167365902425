import CustomCard from "../../../components/reusable/CustomCard";
import BiaxialLineChart from "./BiaxialLineChart";
import CustomLabels from "./CustomLabels";
import Memo from "../../../components/reusable/Memo";

import classes from "./LineChartSection.module.css";

type LineData = {
  x: number;
  line1?: number;
  line2?: number;
  line3?: number;
  line4?: number;
  line5?: number;
  line6?: number;
  lineUser?: number;
};

type Legend2Obj = { INDEX: number; VALUE: string };

interface Props {
  data: {
    DESCRIPTION: string;
    INDEX: number;
    LEGEND_1: string;
    LEGEND_2: Legend2Obj[];
    LEGEND_X_1: string;
    LEGEND_Y_1: string;
    LEGEND_Y_2: string;
    TITLE: string;
  };
  id: string;
  biaxialLineChartData: {
    gender: string;
    lines_men: LineData[];
    lines_women: LineData[];
  };
  dataNew:any
}
const LineChartSection = ({
  data,
  id,
  biaxialLineChartData,
    dataNew
}: Props): JSX.Element => {
  const chartLineHeight = 240;
  const chartLineWidth = 650;

  const lineColorHandler = (index: number): string => {
    let lineColor: string;
    switch (index) {
      case 1:
        lineColor = "var(--secondary40)";
        break;
      case 2:
        lineColor = "var(--secondary50)";
        break;
      // case 3:
      //   lineColor = "var(--secondary30)";
      //   break;
      case 3:
        lineColor = "var(--secondary21)";
        break;
      case 4:
        lineColor = "var(--secondary07)";
        break;
      case 5:
        lineColor = "var(--secondary12)";
        break;
      default:
        lineColor = "#666";
    }
    return lineColor;
  };

  return (
    <div id={id} className={classes.section}>
      <CustomCard cardHeight={330} cardWidth="726px">
        <div className={classes.subtitle}>{data.TITLE}</div>
        <div id="BiaxialLineChart" className={classes.biaxial}>
          <BiaxialLineChart
            height={chartLineHeight}
            width={chartLineWidth}
            biaxialLineChartData={biaxialLineChartData}
          />
        </div>
        <CustomLabels data={data} />
        <div className={classes.legend}>
          <Memo
            text={data.LEGEND_1}
            color="var(--primary800)"
            size="10px"
            fontFamily={"AktivGroteskEx_A_Md"}
          />
          <div className={classes.legend2}>
            {dataNew.slice(0,-1).map((item:any) => (
              <Memo
                key={item.INDEX}
                text={item.NAME}
                color={lineColorHandler(item.INDEX)}
                size="7.98px"
                fontFamily="AktivGroteskEx_A_Rg"
              />
            ))}
          </div>
        </div>
      </CustomCard>
    </div>
  );
};

export default LineChartSection;
