import React, { useEffect, useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

import TextInput from "../reusable/TextInput";
import Button from "../../../common/ui/Button";

import classes from "./SignUpForm.module.css";
import { useNavigate } from "react-router-dom";

import {
  LocalStorageVariables,
  useAppStore,
} from "../../../providers/AppStore";
import { loginUser } from "../../../common/network/Api";
import { UserType } from "../../../common/entities/profile";
import useTheme from "../../../common/hooks/useTheme";

type UserDataType = {
  id?: string;
  role?: number;
  email: string;
  password: string;
  client?: string;
};

type Props = {
  id: string | null;
  setIsLoading: (value: boolean) => void;
  setErrorMessage: (value: string) => void;
  setShowError: (value: boolean) => void;
};

const SignInForm = ({
  id,
  setIsLoading,
  setErrorMessage,
  setShowError,
}: Props) => {
  const dispatch = useDispatch();
  const { ASUser, ASSetUser, ASGetUserSettings, ASIsUserLoaded } =
    useAppStore();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const handleShowPassword = () => {
    setShowPassword(() => !showPassword);
  };

  const handleLogin = async (userData: UserDataType) => {
    try {
      let temporaryUser: UserType = await loginUser(userData);
      await ASSetUser(temporaryUser);
      await ASGetUserSettings();
    } catch (e: any) {
      setErrorMessage(e.message);
      setShowError(true);
    }
  };

  useEffect(() => {
    if (ASIsUserLoaded()) {
      if (ASUser.current.role === 1) {
        let paramReport = localStorage.getItem(
          LocalStorageVariables.PARAMS_REPORT
        );
        if (paramReport !== null) {
          const params = JSON.parse(paramReport);
          navigate(
            `/reports?id=${params.idReport}&type=${params.type}&lang=${params.lang}`
          );
          localStorage.removeItem(LocalStorageVariables.PARAMS_REPORT);
        } else {
          if (!ASUser.current.biometrics_questionnaire_completed) {
            navigate("/intro");
          } else if (ASUser.current.biometrics_questionnaire_completed) {
            navigate("/platform/dashboard");
          }
        }
      } else {
        navigate("/client/client-list");
      }
    }
  }, [ASUser]);

  const handleSubmitLogin = async (
    values: UserDataType,
    { setSubmitting }: FormikHelpers<UserDataType>
  ) => {
    setIsLoading(true);
    const user: UserDataType = {
      email: values.email,
      password: values.password,
      client: "WELCOME",
    };

    await handleLogin(user);
    setSubmitting(false);
    setIsLoading(false);
  };

  return (
    <Formik
      initialValues={{
        id: "",
        role: 1,
        email: "",
        password: "",
      }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email("Invalid email address`")
          .required("This is a required field."),
        password: Yup.string()
          .required("This is a required field.")
          .min(6, "Your password is too short."),
      })}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleSubmitLogin}
    >
      <Form className={classes.inner} placeholder={""}>
        <TextInput
          label="Email"
          name="email"
          type="email"
          // placeholder="Enter your Email here"
        />
        <TextInput
          label="Password"
          name="password"
          type={showPassword ? "text" : "password"}
          // placeholder="Enter your Password here"
          onClick={handleShowPassword}
          showPwd={showPassword}
        />

        <div
          className={classes.forgetPassword}
          style={{ color: theme.colors_ds.PRIMARY_ICON_MAIN }}
          onClick={() => {
            setErrorMessage("");
            setShowError(false);
            navigate(`/auth?mode=forgot-password&id=${id}`);
          }}
        >
          Forget Password?
        </div>
        <div className={classes.bottom}>
          <Button
            type="submit"
            text="Sign in"
            customWidth="100%"
            customMargin={"85px auto 0"}
          />
        </div>
      </Form>
    </Formik>
  );
};

export default SignInForm;
