import React from "react";

import { Colors_DS, lightTheme_DS } from "../common/colors/colors_ds";
import {
  ColorTokens,
  lightTheme_Danger,
  lightTheme_Neutral,
  lightTheme_Primary,
  lightTheme_Success,
  lightTheme_Warning,
} from "../common/colors/colorTokens";

export const ThemeContext = React.createContext<Theme>({
  icons: {},
  colors_ds: lightTheme_DS,
  colorTokens: {
    P: lightTheme_Primary,
    N: lightTheme_Neutral,
    W: lightTheme_Warning,
    D: lightTheme_Danger,
    S: lightTheme_Success,
  },
});

export type Theme = {
  icons: any;
  colors_ds: Colors_DS;
  colorTokens: {
    P: ColorTokens;
    N: ColorTokens;
    W: ColorTokens;
    D: ColorTokens;
    S: ColorTokens;
  };
};

const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const theme = {
    colors_ds: lightTheme_DS,
    colorTokens: {
      P: lightTheme_Primary,
      N: lightTheme_Neutral,
      W: lightTheme_Warning,
      D: lightTheme_Danger,
      S: lightTheme_Success,
    },
    icons: {},
  };

  return (
    <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
  );
};

export default ThemeProvider;
