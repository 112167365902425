import okIcon from "../../../../common/images/okIcon.png";
import appstore from "../../../../common/images/appstore.png";
import googleplay from "../../../../common/images/googleplay.png";

import backgroundImg from "../../../../common/images/backgroundImg.png";

import classes from "./SuccessPage.module.css";
import useTheme from "../../../../common/hooks/useTheme";
import { useEffect } from "react";
import { stepperActions } from "../../store/stepper-slice";
import { useNavigate } from "react-router-dom";

const SuccessPageLifestyle = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem("notes");
    setTimeout(() => {
      navigate("/platform");
    }, 5000);
  }, []);

  return (
    <div
      className={classes.container}
      style={{
        backgroundImage: `url('${backgroundImg}')`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: "var(--app-height)",
      }}
    >
      <div className={classes.containerOverlay}>
        <div className={classes.inner}>
          <div className={classes.imgContainer}>
            <img className={classes.logo} src={okIcon} />
          </div>

          <div
            className={classes.title}
            style={{ color: theme.colors_ds.NEUTRAL_TEXT_STRONG }}
          >
            You have successfully completed our lifestyle questionnaire!
          </div>

          <div>
            <div
              className={classes.subTitle}
              style={{ color: theme.colors_ds.NEUTRAL_TEXT_STRONG }}
            >
              Thank you for having all of our questions answered.
            </div>{" "}
            <div className={classes.bottom}>Download the App</div>
            <div className={classes.btnIcons}>
              <a
                href="https://play.google.com/store/apps/details?id=pnoe.rn.precision"
                target="_blank"
              >
                <img
                  className={classes.img}
                  src={googleplay}
                  alt="icon google play"
                />
              </a>
              <a
                href="https://apps.apple.com/us/app/pno%C4%93-precision/id6450449936"
                target="_blank"
              >
                <img
                  className={classes.img}
                  src={appstore}
                  alt="icon appstore"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPageLifestyle;
