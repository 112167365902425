import classes from "./AxisPrediction.module.css";

const AxisPrediction = (): JSX.Element => {
  return (
    <div className={classes.axis}>
      <div className={classes.line1}>
        <div className={classes.dot} style={{ left: "33.4px" }}></div>
        <div className={classes.dot} style={{ left: "66.8px" }}></div>
        <div className={classes.dot} style={{ left: "100.2px" }}></div>
        <div className={classes.dot} style={{ left: "133.6px" }}></div>
        <div className={classes.dot} style={{ left: "167px" }}></div>
      </div>
      <div className={classes.line2}>
        <div className={classes.dot} style={{ left: "33.4px" }}></div>
        <div className={classes.dot} style={{ left: "66.8px" }}></div>
        <div className={classes.dot} style={{ left: "100.2px" }}></div>
        <div className={classes.dot} style={{ left: "133.6px" }}></div>
        <div className={classes.dot} style={{ left: "167px" }}></div>
      </div>
      <div className={classes.line3}>
        <div className={classes.dot} style={{ left: "33.4px" }}></div>
        <div className={classes.dot} style={{ left: "66.8px" }}></div>
        <div className={classes.dot} style={{ left: "100.2px" }}></div>
        <div className={classes.dot} style={{ left: "133.6px" }}></div>
        <div className={classes.dot} style={{ left: "167px" }}></div>
      </div>
    </div>
  );
};

export default AxisPrediction;
