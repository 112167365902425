import Layout from "../../components/reusable/Layout";

import classes from "./CustomCoverPage.module.css";

const CustomCoverPage = () => {
  return (
    <Layout isDarkTheme={true}>
      <div className={classes.title}>MILLER & EVERTON</div>
    </Layout>
  );
};

export default CustomCoverPage;
