import classes from "./NutritionBars.module.css";
import PieChartWithoutSectors from "../common/PieChartWithoutSectors";
import useTheme from "../../../../common/hooks/useTheme";

type Props = {
  data: any;
};

const NutritionBars = ({ data }: Props) => {
  const theme = useTheme();

  const totalBurnCalories =
    data.dailyActivityValue + data.RMRValue + data.workoutValue;

  const totalIntakeCalories = data.calorieIntakeValue;

  const getNormalizedDataForPieChart = (data: any) => {
    const percentage1 = Math.round(
      (data[0].value / (data[0].value + data[1].value + data[2].value)) * 100
    );
    const percentage3 = Math.round(
      (data[2].value / (data[0].value + data[1].value + data[2].value)) * 100
    );

    return data.map((el: any) => {
      if (el.categoryType === 2) {
        return {
          label: el.label,
          value: el.value,
          valueKcal: el.value * 9,
          categoryType: el.categoryType,
          percentage: 100 - percentage1 - percentage3,
        };
      } else {
        return {
          label: el.label,
          value: el.value,
          valueKcal: el.value * 4,
          categoryType: el.categoryType,
          percentage: el.categoryType === 1 ? percentage1 : percentage3,
        };
      }
    });
  };

  return (
    <div style={{ flex: 1 }}>
      <div
        className={classes.title}
        style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
      >
        {data.title}
      </div>
      <div
        className={classes.barsContainer}
        style={{ borderColor: theme.colors_ds.NEUTRAL_BORDER_WEAK }}
      >
        <div
          className={classes.barContainerTitle}
          style={{ color: theme.colors_ds.NEUTRAL_TEXT_STRONG }}
        >
          {totalBurnCalories > totalIntakeCalories ? "Deficit: " : "Surplus: "}
          <span
            className={classes.barContainerValue}
            style={{
              color:
                totalBurnCalories > totalIntakeCalories
                  ? theme.colors_ds.DANGER_GRAPH_DEFICIT
                  : theme.colors_ds.SUCCESS_ICON_MAIN,
            }}
          >
            {`${Math.abs(totalBurnCalories - totalIntakeCalories)} kcal`}
          </span>
        </div>
        <div
          className={classes.barHeader}
          style={{ color: theme.colors_ds.NEUTRAL_TEXT_STRONG }}
        >
          <div className={classes.barText}>Calorie intake</div>
          <div
            className={classes.barText}
          >{`${data.calorieIntakeValue} kcal`}</div>
        </div>
        <div className={classes.bar}>
          {/*<div*/}
          {/*  className={classes.barInner}*/}
          {/*  style={{*/}
          {/*    width: `${100}%`,*/}
          {/*    border: `1px solid ${*/}
          {/*      totalBurnCalories > totalIntakeCalories*/}
          {/*        ? theme.colors_ds.DANGER_GRAPH_DEFICIT*/}
          {/*        : totalIntakeCalories === totalCalories*/}
          {/*        ? "transparent"*/}
          {/*        : theme.colors_ds.SUCCESS_ICON_MAIN*/}
          {/*    }`,*/}
          {/*  }}*/}
          {/*/>*/}
          <div
            className={classes.barInner}
            style={{
              width: `${(totalIntakeCalories * 100) / totalIntakeCalories}%`,
              backgroundColor: theme.colorTokens.P[90],
            }}
          />
        </div>
        <div
          className={classes.barHeader}
          style={{ color: theme.colors_ds.NEUTRAL_TEXT_STRONG }}
        >
          <div className={classes.barText}>Calorie burn</div>
          <div className={classes.barText}>{`${totalBurnCalories} kcal`}</div>
        </div>
        <div className={classes.bar}>
          {/*<div*/}
          {/*  className={classes.barInner}*/}
          {/*  style={{*/}
          {/*    width: `${100}%`,*/}
          {/*    border: `1px solid ${*/}
          {/*      totalBurnCalories > totalIntakeCalories*/}
          {/*        ? theme.colors_ds.DANGER_GRAPH_DEFICIT*/}
          {/*        : totalBurnCalories === totalCalories*/}
          {/*        ? "transparent"*/}
          {/*        : theme.colors_ds.SUCCESS_ICON_MAIN*/}
          {/*    }`,*/}
          {/*  }}*/}
          {/*/>*/}
          {data.workoutValue > 0 && (
            <div
              className={classes.barInner}
              style={{
                width: `${
                  ((data.dailyActivityValue +
                    data.RMRValue +
                    data.workoutValue) *
                    100) /
                  totalBurnCalories
                }%`,
                backgroundColor: theme.colorTokens.P[20],
              }}
            />
          )}
          <div
            className={classes.barInner}
            style={{
              width: `${
                ((data.dailyActivityValue + data.RMRValue) * 100) /
                totalBurnCalories
              }%`,
              backgroundColor: theme.colorTokens.P[40],
            }}
          />
          <div
            className={classes.barInner}
            style={{
              width: `${(data.dailyActivityValue * 100) / totalBurnCalories}%`,
              backgroundColor: theme.colorTokens.P[70],
            }}
          />
        </div>
        <div
          className={classes.legend}
          style={{ color: theme.colors_ds.NEUTRAL_TEXT_STRONG }}
        >
          <div className={classes.legendItem}>
            <div
              className={classes.dataPoint}
              style={{ backgroundColor: theme.colorTokens.P[70] }}
            />
            <div>Daily activity kcal</div>
          </div>
          <div className={classes.legendItem}>
            <div
              className={classes.dataPoint}
              style={{ backgroundColor: theme.colorTokens.P[40] }}
            />
            <div>RMR</div>
          </div>
          {data.workoutValue > 0 && (
            <div className={classes.legendItem}>
              <div
                className={classes.dataPoint}
                style={{ backgroundColor: theme.colorTokens.P[20] }}
              />
              <div>Workout kcal</div>
            </div>
          )}
        </div>
      </div>
      <PieChartWithoutSectors
        title={data.pieTitle}
        pieData={getNormalizedDataForPieChart(data.pieData)}
        type={"nutrition"}
      />
    </div>
  );
};

export default NutritionBars;
