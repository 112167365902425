import CoverPage from "../pages/CoverPage/CoverPage";
import PillarsOfLongevityPage from "../pages/PillarsOfLongevityPage/PillarsOfLongevityPage";
import OverviewMetricBarGraphPage from "../pages/OverviewMetricBarGraphPage/OverviewMetricBarGraphPage";
import CoreMetricsPage from "../pages/CoreMetricsPage/CoreMetricsPage";
import WellnessHealthHistoryPage from "../pages/WellnessHealthHistoryPage/WellnessHealthHistoryPage";
import BiologicalAgePage from "../pages/BiologicalAgePage/BiologicalAgePage";
import MetricPage from "../pages/MetricPage/MetricPage";
import TrainingProgramTrianglePage from "../pages/TrainingProgramTrianglePage/TrainingProgramTrianglePage";
import TrainingProgramWorkoutPage from "../pages/TrainingProgramWorkoutPage/TrainingProgramWorkoutPage";
import TrainingProgramPage from "../pages/TrainingProgramPage/TrainingProgramPage";
import EnergyConsumptionFuelingPage from "../pages/EnergyConsumptionFuelingPage/EnergyConsumptionFuelingPage";
import TestingSchedulePage from "../pages/TestingSchedulePage/TestingSchedulePage";
import PerformancePredictionPage from "../pages/PerformancePredictionPage/PerformancePredictionPage";
import AuxiliaryTreatmentsPage from "../pages/AuxiliaryTreatmentsPage/AuxiliaryTreatmentsPage";
import WorkoutDescriptionPage from "../pages/WorkoutDescriptionPage/WorkoutDescriptionPage";
import AuxiliaryTreatmentsPageV02 from "../pages/AuxiliaryTreatmentsPage_V02/AuxiliaryTreatmentsPageV02";
import CustomCoverPage from "../pages/CustomCoverPage/CustomCoverPage";
import TrainerNotePage from "../pages/TrainerNotePage/TrainerNotePage";

const CustomReportAMR = ({ data, svgConverter, reportType }) => {
  return (
    <>
      <CustomCoverPage />
      <TrainerNotePage data={data.NOTES} />
      <OverviewMetricBarGraphPage
        data={data.PAGES.find((page) => page.PAGE_TYPE === "OVERVIEW")}
        onSvgConverter={svgConverter}
      />
    </>
  );
};

export default CustomReportAMR;
