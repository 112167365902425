import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import CONFIG, { BaseURLType } from "../navigation/Configuration";
import { UserRole, UserType } from "../common/entities/profile";
import {
  getAllIntegrations,
  getHomePage,
  getPlatformData,
  getUserInfo,
  settings,
} from "../common/network/Api";
import { useDispatch, useSelector } from "react-redux";
import {
  platformActions,
  RootStatePlatform,
} from "../modules/platform/store/platform-slice";

const AppStoreContext = createContext({});

type PropsType = {
  children: ReactNode;
};

export const LocalStorageVariables = {
  USER: "user",
  AFFILIATE_USER: "affiliate_user",
  ENVIRONMENT: "env",
  PARAMS_REPORT: "paramReport",
};

export function AppStoreProvider({ children }: PropsType) {
  const [configuration, setConfiguration] = useState<BaseURLType | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [integrations, setIntegrations] = useState([]);
  const dispatch = useDispatch();
  const activeDate = useSelector(
    (state: RootStatePlatform) => state.platform.ASActiveDate
  );

  const userRef = useRef(null);

  const getEnv = async () => {
    let result =
      localStorage.getItem(LocalStorageVariables.ENVIRONMENT) || "dev";

    // console.log("getEnv", result);

    localStorage.setItem(LocalStorageVariables.ENVIRONMENT, result);

    switch (result) {
      case "dev":
        setConfiguration(CONFIG.DEV);
        break;
      case "prod":
        setConfiguration(CONFIG.PROD);
        break;
      case "local":
        setConfiguration(CONFIG.LOCAL);
        break;
      default:
        setConfiguration(CONFIG.PROD);
    }
  };

  const loadUser = async () => {
    try {
      let usr = localStorage.getItem(LocalStorageVariables.USER);
      // console.log("load user", usr);
      if (usr !== null) {
        userRef.current = JSON.parse(usr);
      }
    } catch (error) {
      console.error("Error in loadUser:", error);
    } finally {
    }
  };

  const saveUser = async (newUser: any) => {
    userRef.current = newUser;
    localStorage.setItem(LocalStorageVariables.USER, JSON.stringify(newUser));
  };

  const isUserLoaded = () => {
    return userRef.current !== null;
  };

  const getIntegrationData = async () => {
    let data = await getAllIntegrations();
    setIntegrations(data);
    return data;
  };

  const isIntegrationActive = () => {
    if (integrations.length === 0) {
      return false;
    }
    const activeIntegration: any[] = integrations.find((item) => {
      return item?.is_connected === true;
    });

    return activeIntegration?.is_connected ?? false;
  };

  const getUserSettings = async () => {
    // @ts-ignore
    let temporaryUser: UserType = userRef.current;

    // Check if temporaryUser is null and handle it
    if (!temporaryUser) {
      console.error("Error: temporaryUser is null");
      return;
    }

    let getUserData = await getUserInfo();
    temporaryUser.role = getUserData.role;
    temporaryUser.lastname = getUserData.lastName;
    temporaryUser.firstname = getUserData.firstName;
    temporaryUser.email = getUserData.email;
    temporaryUser.pnoe_user_id = getUserData.id;

    if (temporaryUser.role === UserRole.Customer) {
      let settingsData = await settings();
      temporaryUser.biometrics_questionnaire_completed =
        settingsData?.biometrics_questionnaire_completed;
      temporaryUser.lifestyle_questionnaire_completed =
        settingsData?.lifestyle_questionnaire_completed;
      temporaryUser.has_lifestyle_questionnaire_access =
        settingsData?.has_lifestyle_questionnaire_access;
    }

    await saveUser(temporaryUser);
  };

  const logoutUser = async () => {
    // Remove items from localStorage
    localStorage.removeItem(LocalStorageVariables.USER);
    localStorage.removeItem(LocalStorageVariables.ENVIRONMENT);
    userRef.current = null;
    dispatch(platformActions.clearPlatformState());
    //set window location to /
    window.location.href = "/";
  };

  const getDataFromPlatform = async (id: string) => {
    try {
      setIsLoading(true);
      let dataPlatform = await getPlatformData(id);
      dispatch(platformActions.updateDataPlatformServer(dataPlatform));
    } catch (e: any) {
      console.log("error in loading data from platform", e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (configuration === null) {
      getEnv();
    }
  }, [configuration]);

  useEffect(() => {
    setIsLoading(true);
    if (userRef.current === null) {
      loadUser();
    }

    // getIntegrationData();
    setIsLoading(false);
  }, []);

  const value = {
    ASConfiguration: configuration?.ID ? configuration.ID : "",
    ASSetEnv: getEnv,
    ASisLoading: isLoading,
    ASUser: userRef,
    ASLogoutUser: logoutUser,
    ASSetUser: saveUser,
    ASGetUserSettings: getUserSettings,
    ASIsUserLoaded: isUserLoaded,
    ASIntegrations: integrations,
    ASIsIntegrationActive: isIntegrationActive,
    ASGetIntegrationData: getIntegrationData,
    ASGetDataFromPlatform: getDataFromPlatform,
  };

  return (
    <AppStoreContext.Provider value={value}>
      {children}
    </AppStoreContext.Provider>
  );
}

type ContextValueType = {
  ASConfiguration: string;
  ASSetEnv: Function;
  ASisLoading: boolean;
  ASUser: { current: UserType };
  ASLogoutUser: Function;
  ASSetUser: Function;
  ASGetUserSettings: Function;
  ASIsUserLoaded: Function;
  ASIntegrations: [];
  ASIsIntegrationActive: Function;
  ASGetIntegrationData: Function;
  ASGetDataFromPlatform: Function;
};

export const useConfig = async () => {
  let result = await localStorage.getItem(LocalStorageVariables.ENVIRONMENT);
  if (result === null) {
    localStorage.setItem(LocalStorageVariables.ENVIRONMENT, "dev");
  }
  switch (result) {
    case "dev":
      return CONFIG.DEV;
    case "local":
      return CONFIG.LOCAL;
    default:
      return CONFIG.PROD;
  }
};

export function useAppStore() {
  const context = useContext(AppStoreContext);

  if (!context) {
    throw new Error("useAppStore must be used inside AppStoreProvider");
  }

  return context as ContextValueType;
}
