import React, { MouseEventHandler } from "react";

type Props = { onClick?: MouseEventHandler<SVGSVGElement>; fill?: string };

const ArrowRight = ({ onClick, fill }: Props) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="right-arrow">
        <path
          id="Icon"
          d="M5.49979 15.4999C5.30204 15.4999 5.10874 15.4412 4.94433 15.3313C4.77992 15.2214 4.65178 15.0653 4.57611 14.8826C4.50044 14.6999 4.48064 14.4988 4.5192 14.3049C4.55777 14.1109 4.65298 13.9328 4.79279 13.7929L10.0858 8.49992L4.79279 3.20692C4.61063 3.01832 4.50983 2.76571 4.51211 2.50352C4.51439 2.24132 4.61956 1.99051 4.80497 1.8051C4.99038 1.61969 5.24119 1.51452 5.50339 1.51224C5.76558 1.50997 6.01818 1.61076 6.20679 1.79292L12.2068 7.79292C12.3943 7.98045 12.4996 8.23475 12.4996 8.49992C12.4996 8.76508 12.3943 9.01939 12.2068 9.20692L6.20679 15.2069C6.01929 15.3945 5.76498 15.4999 5.49979 15.4999Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default ArrowRight;
