import CardDescription from "./reusable/CardDescription";
import CustomCard from "./reusable/CustomCard";

import classes from "./SuggestionCard.module.css";

type improveArea = {
  INDEX: number;
  TEXT: string;
  TITLE: string;
  VALUE?: number;
};

interface Props {
  data: { IMPROVE_AREAS: improveArea[]; INDEX: number; TITLE: string };
}

const SuggestionCard = ({ data }: Props): JSX.Element => {
  let colorLabel: string;
  let iconShow: boolean;
  if (data.INDEX === 1) {
    colorLabel = "var(--secondary20)";
    iconShow = true;
  } else if (data.INDEX === 2) {
    colorLabel = "var(--secondary10)";
  } else {
    colorLabel = "var(--secondary40)";
  }

  return (
    <CustomCard cardPadding="10px 14px">
      <div className={classes.title}>
        <div
          className={classes.icon}
          style={{ backgroundColor: colorLabel }}
        ></div>
        <div>{data.TITLE.toUpperCase()}</div>
      </div>

      <div className={classes.description}>
        <ul className={classes.columns}>
          {data.IMPROVE_AREAS.map((item) => (
            <li key={item.INDEX} className={classes.sub}>
              <CardDescription
                title={item.TITLE}
                description={item.TEXT}
                showIcon={iconShow}
                valueIcon={item.VALUE}
                index={item.INDEX}
                customWidth="222px"
                customMargin={0}
                usecustomStyle={true}
              />
            </li>
          ))}
        </ul>
      </div>
    </CustomCard>
  );
};

export default SuggestionCard;
