export type BaseURLType = {
  ID: string;
  ID_BASE_URL: string;
  NUT_BASE_URL: string;
  PLATFORM_BASE_URL: string;
};

export type ConfigType = {
  DEV: BaseURLType;
  PROD: BaseURLType;
  LOCAL: BaseURLType;
};

const CONFIG: ConfigType = {
  DEV: {
    ID: "dev",
    ID_BASE_URL: "https://id-dev.mypnoe.com/",
    NUT_BASE_URL: "https://nut-dev.mypnoe.com/",
    PLATFORM_BASE_URL: "https://pnoeapi-dev.mypnoe.com/",
  },
  PROD: {
    ID: "prod",
    ID_BASE_URL: "https://id.mypnoe.com/",
    NUT_BASE_URL: "https://nut.mypnoe.com/",
    PLATFORM_BASE_URL: "https://pnoeapi.mypnoe.com/",
  },
  LOCAL: {
    ID: "local",
    ID_BASE_URL: "https://id-dev.mypnoe.com/",
    NUT_BASE_URL: "https://nut-go.ngrok.io/",
    PLATFORM_BASE_URL: "https://pnoeapi.mypnoe.com/",
  },
};

export default CONFIG;
