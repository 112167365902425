import Layout from "../../components/reusable/Layout";
import CustomCard from "../../components/reusable/CustomCard";

import classes from "./CoverPage.module.css";

import frontPageBackground from "../../images/frontPageBackground.png";
import coverPageImgLongevity from "../../images/coverPageImgLongevity.png";
import coverPageImgWeightLoss from "../../images/coverPageImgWeightLoss.png";
import coverPageImgPerfomance from "../../images/coverPageImgPerfomance.png";
import coverPageImgStressReport from "../../images/stressReport/coverPageImgStressReport.png";
import logo from "../../images/logo.png";
import instagram from "../../images/instagram.png";
import rectangle200 from "../../images/rectangle200.png";

interface Props {
  data: {
    AFFILIATE_NAME: string;
    COVER_SUBTITLE: string;
    DATE: string;
    DESCRIPTION: string;
    DISCLAIMER: string;
    IMAGE_URL: string;
    INDEX: number;
    SUBJECT_NAME: string;
    TITLE: string;
    TEST_TYPE: string;
    TEST_TYPE_LABEL: string;
    TEST_DATE_LABEL: string;
  };

  trainerName: string;
  trainerMail: string;

  reportType: string;
}

const CoverPage = ({
  data,
  trainerName,
  trainerMail,
  reportType,
}: Props): JSX.Element => {
  const logoSrcHandler = (url: string): string => {
    let logoSrc;
    switch (url) {
      case "/logo.svg":
        logoSrc = logo;
        break;
      case "":
        logoSrc = null;
        break;
      default:
        logoSrc = url;
    }
    return logoSrc;
  };

  const backgroundImgHandler = (type: string): string => {
    let backgroundImg: string;

    switch (type) {
      case "10":
      case "11":
        backgroundImg = coverPageImgLongevity;
        break;
      case "12":
      case "13":
        backgroundImg = coverPageImgWeightLoss;
        break;
      case "14":
      case "15":
        backgroundImg = coverPageImgPerfomance;
        break;
      case "16":
        backgroundImg = coverPageImgStressReport;
        break;
      default:
        backgroundImg = coverPageImgLongevity;
    }
    return backgroundImg;
  };

  return (
    <>
      <Layout customPadding={0}>
        <div className={classes.logoContainer}>
          {/*<img className={classes.logo} src={logo} alt="logo" />*/}
        </div>
        <div className={classes.header}>{data.TITLE}</div>
        <div className={classes.subTitle}>{data.COVER_SUBTITLE}</div>
        <CustomCard cardHeight={77} cardWidth="512px" cardPadding="24px 0">
          <div className={classes.subjectName}>{data.SUBJECT_NAME}</div>
        </CustomCard>
        <div className={classes.imgContainer}>
          <img
            className={classes.imgItem}
            src={backgroundImgHandler(reportType)}
            alt="main image"
          />
        </div>
        <div className={classes.bottom}>
          <div className={classes.bottomLeft}>
            <div className={classes.testTypeLabel}>
              {data.TEST_TYPE_LABEL}
              <span>: </span>
            </div>
            <div>{data.TEST_TYPE}</div>
            <div className={classes.testData}>
              <span>{data.TEST_DATE_LABEL}</span>
              <span>: </span>
              <span className={classes.testDateValue}>{data.DATE}</span>
            </div>
          </div>
          <div className={classes.bottomRight}>
            <div className={classes.logo2Container}>
              {!!data.IMAGE_URL && (
                <img
                  className={classes.logo2}
                  src={logoSrcHandler(data.IMAGE_URL)}
                  alt="logo"
                />
              )}
            </div>
            <div className={classes.affiliateName}>{data.AFFILIATE_NAME}</div>
            <div className={classes.affiliateName}>{trainerMail}</div>
          </div>
        </div>
        {/*<div className={classes.footer}>*/}
        {/*  <div>www.pnoe.com</div>*/}
        {/*  <div className={classes.instagram}>*/}
        {/*    <img src={instagram} alt="logo" />*/}
        {/*    <span>pnoe_analytics</span>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </Layout>
      <Layout customPadding={0}>
        <img
          className={classes.backgroundImg}
          src={frontPageBackground}
          alt="background of the front page"
        />
        <img
          className={classes.rectangle200}
          src={rectangle200}
          alt="background of the description"
        />
        <div id="coverDescription" className={classes.description}>
          <div className={classes.disclaimer}>
            <span>{`${data.DISCLAIMER} `}</span>
            {data.DESCRIPTION}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default CoverPage;
