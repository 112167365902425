import classes from "./CustomLabelsPolygon.module.css";

type tag = { INDEX: number; NAME: string; VALUE: number };

interface Props {
  data: tag[];
}

const CustomLabelsPolygon = ({ data }: Props): JSX.Element => {


  const tagTypeHandler = (index: number) => {
    let tagType: string;
    switch (index) {
      case 1:
        tagType = classes.heartTag;
        break;
      case 2:
        tagType = classes.lungsTag;
        break;
      case 3:
        tagType = classes.sleepTag;
        break;
      case 4:
        tagType = classes.nervousSystemTag;
        break;
      case 5:
        tagType = classes.cellFunctionTag;
        break;
      case 6:
        tagType = classes.boneHealthTag;
        break;
      case 7:
        tagType = classes.metabolismTag;
        break;
      case 8:
        tagType = classes.postureTag;
        break;
      default:
        tagType = "";
    }
    return tagType;
  };
  return (
    <>
      {data.map((item) => (
        <div key={item.INDEX} className={tagTypeHandler(item.INDEX)}>
          <div className={classes.title}>{item.NAME}</div>
          <div className={classes.value}>{item.VALUE}%</div>
        </div>
      ))}
    </>
  );
};

export default CustomLabelsPolygon;
