import DescriptionPanel from "../../../../common/ui/DescriptionPanel";
import classes from "./ListForm.module.css";
import React, { useState } from "react";
import useTheme from "../../../../common/hooks/useTheme";

type Props = {
  data: any;
  value: number;
  category: string;
  answer: string;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  measurement_unit: number;
  labelDescription?: boolean;
};

const ListForm = ({
  data,
  value,
  category,
  answer,
  measurement_unit,
  labelDescription,
  ...props
}: Props) => {
  const theme = useTheme();
  const [showText, setShowText] = useState(false);
  let descriptionContent;

  if (data.index !== 0 && measurement_unit === 2) {
    switch (data.index) {
      case 1:
        descriptionContent = (
          <span
            className={classes.description}
            style={{ color: theme.colors_ds.NEUTRAL_TEXT_WEAK }}
          >
            (4 lbs/Week)
          </span>
        );
        break;
      case 2:
        descriptionContent = (
          <span
            className={classes.description}
            style={{ color: theme.colors_ds.NEUTRAL_TEXT_WEAK }}
          >
            (3.3 lbs/Week)
          </span>
        );
        break;
      case 3:
        descriptionContent = (
          <span
            className={classes.description}
            style={{ color: theme.colors_ds.NEUTRAL_TEXT_WEAK }}
          >
            (2.2 lbs/Week)
          </span>
        );
        break;
      case 4:
        descriptionContent = (
          <span
            className={classes.description}
            style={{ color: theme.colors_ds.NEUTRAL_TEXT_WEAK }}
          >
            (1.1 lbs/Week)
          </span>
        );
        break;
      default:
        descriptionContent = null;
    }
  } else if (data.index !== 0) {
    descriptionContent = (
      <span
        className={classes.description}
        style={{ color: theme.colors_ds.NEUTRAL_TEXT_WEAK }}
      >{`(${2.5 - data.index * 0.5} kg/Week)`}</span>
    );
  }

  const handleMouseEnter = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowText(true);
  };
  const handleMouseLeave = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowText(false);
  };

  return (
    <div className={classes.container}>
      <label
        className={classes.text}
        style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
      >
        <input type="radio" className={classes.dot} value={value} {...props} />
        <span className={classes.label}>{data.label}</span>
        {labelDescription && descriptionContent}
        {showText && data.info && (
          <div
            className={classes.descriptionPanelText}
            style={{
              backgroundColor: theme.colorTokens.P[10],
              width: labelDescription ? "287px" : "",
              color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT,
            }}
          >
            {data.info}
          </div>
        )}
      </label>
      {data.info && (
        <DescriptionPanel
          showText={showText}
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
          data={data.info}
        />
      )}
    </div>
  );
};

export default ListForm;
