import Layout from "../../components/reusable/Layout";

import classes from "./UserServicesPage.module.css";
import ServiceSection from "./ServiceSection";
// import superhumanLogo from '../../images/superhuman_logo.png'

type Props = {
  data: any;
};
const UserServicesPage = ({ data }: Props) => {
  // console.log('USER_SERVICES_PAGE',data)
  const totalItem = [...data.SERVICES, ...data.IVSERVICES];
  const numOfPages = Math.ceil(totalItem.length / 4);

  // const displayedItems=[]
  //
  // for (let i = 0; i<data.SERVICES.length; i++){
  //     if(displayedItems.length<3){
  //         displayedItems.push({ height:316, type:"UserService", ...data.SERVICES[i]})
  //     } else {
  //         displayedItems.push({ height:280,type:"UserService", ...data.SERVICES[i]})
  //     }
  // }
  //
  // for (let i = 0; i<data.IVSERVICES.length; i++){
  //     if(displayedItems.length<3){
  //         displayedItems.push({ height:316, type:"IVService", ...data.IVSERVICES[i]})
  //     } else {
  //         displayedItems.push({ height:280,type:"IVService", ...data.IVSERVICES[i]})
  //     }
  // }
  //
  // const total=displayedItems.reduce((sum,cur)=>sum+cur.height, 175)

  return (
    <Layout customHeight={`${numOfPages * 1123}px`}>
      {data.SERVICES.map((service: any) => (
        <ServiceSection key={service.INDEX} data={service} type="UserService" />
      ))}
      {data.IVSERVICES.map((service: any) => (
        <ServiceSection key={service.INDEX} data={service} type="IVService" />
      ))}
    </Layout>
  );
};

export default UserServicesPage;
