import okIcon from "../../../../common/images/okIcon.png";
import appstore from "../../../../common/images/appstore.png";
import googleplay from "../../../../common/images/googleplay.png";

import backgroundImg from "../../../../common/images/backgroundImg.png";

import classes from "./SuccessPage.module.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageLoader from "../../../../common/ui/PageLoader";
import Button from "../../../../common/ui/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootStateStepper, stepperActions } from "../../store/stepper-slice";
import { useAppStore } from "../../../../providers/AppStore";
import useTheme from "../../../../common/hooks/useTheme";

const SuccessPageBiometric = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ASUser } = useAppStore();
  const theme = useTheme();

  useEffect(() => {
    if (ASUser.current.lifestyle_questionnaire_completed) {
      navigate("/success-lifestyle");
    } else {
      if (!ASUser.current.has_lifestyle_questionnaire_access) {
        localStorage.removeItem("notes");
        setTimeout(() => {
          navigate("/platform");
        }, 5000);
      }
      dispatch(stepperActions.goToStep({ step: 1 }));
      console.log("Lifestyle questionnaire is not completed");
    }
  }, []);

  return (
    <div
      className={classes.container}
      style={{
        backgroundImage: `url('${backgroundImg}')`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: "var(--app-height)",
      }}
    >
      {isLoading && <PageLoader />}
      {!isLoading && (
        <>
          <div className={classes.inner}>
            <div className={classes.imgContainer}>
              <img className={classes.logo} src={okIcon} />
            </div>

            <div
              className={classes.title}
              style={{ color: theme.colors_ds.NEUTRAL_TEXT_STRONG }}
            >
              You have successfully completed our biometrics questionnaire!
            </div>

            {ASUser.current.has_lifestyle_questionnaire_access ? (
              <div>
                <div
                  className={classes.subTitle}
                  style={{ color: theme.colors_ds.NEUTRAL_TEXT_STRONG }}
                >
                  Please proceed with the lifestyle questionnaire
                </div>
                <div className={classes.btn}>
                  <Button
                    text="Continue"
                    customWidth="150px"
                    onClick={() => navigate("/lifestyle-questionnaire")}
                  />
                  <div
                    className={classes.subtitle2}
                    style={{ color: theme.colors_ds.NEUTRAL_GENERAL_WHITE }}
                  >
                    Will only take 10 min
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className={classes.subTitle}>
                  Thank you for having all of our questions answered.
                </div>
                <div className={classes.bottom}>Download the App</div>
                <div className={classes.btnIcons}>
                  <a
                    href="https://play.google.com/store/apps/details?id=pnoe.rn.precision"
                    target="_blank"
                  >
                    <img
                      className={classes.img}
                      src={googleplay}
                      alt="icon google play"
                    />
                  </a>
                  <a
                    href="https://apps.apple.com/us/app/pno%C4%93-precision/id6450449936"
                    target="_blank"
                  >
                    <img
                      className={classes.img}
                      src={appstore}
                      alt="icon appstore"
                    />
                  </a>
                </div>
              </div>
            )}
          </div>
          {ASUser.current.has_lifestyle_questionnaire_access && (
            <div className={classes.btnMobile}>
              <Button
                text="Continue"
                customWidth="100%"
                onClick={() => navigate("/lifestyle-questionnaire")}
              />
              <div
                className={classes.subtitle2}
                style={{ color: theme.colors_ds.NEUTRAL_GENERAL_WHITE }}
              >
                Will only take 10 min
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SuccessPageBiometric;
