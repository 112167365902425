import Button from "../../../../common/ui/Button";
import ProgressBar from "../reusable/ProgressBar";

import classes from "./Footer.module.css";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootStateStepper, stepperActions } from "../../store/stepper-slice";
import { answersActions, RootStateAnswers } from "../../store/answers-slice";

import { cmToIn, inToCm, kgToPd, pdToKg } from "../../utils";
import {
  MIN_WEIGHT_KG,
  MIN_WEIGHT_OZ,
  MIN_HEIGHT_CM,
  MIN_HEIGHT_IN,
} from "../../constant";
import {
  submitBiometricQuestionnaireToNutServer,
  submitBiometricQuestionnaireToNUTServerFailed,
  submitBiometricQuestionnaireToPlatformServer,
} from "../../../../common/network/Api";
import { useAppStore } from "../../../../providers/AppStore";

type Props = {
  setIsLoading: Function;
};

const Footer = ({ setIsLoading }: Props) => {
  const dispatch = useDispatch();
  const stepper = useSelector((state: RootStateStepper) => state.stepper.step);
  const formData = useSelector(
    (state: RootStateStepper) => state.stepper.formData
  );
  const totalSteps = useSelector(
    (state: RootStateStepper) => state.stepper.totalSteps
  );
  const convertMeasurementValues = useSelector(
    (state: RootStateStepper) => state.stepper.convertMeasurementValues
  );
  const answers = useSelector(
    (state: RootStateAnswers) => state.answers.biometricQuest
  );
  const { ASUser } = useAppStore();
  let curStep;
  if (stepper > 10) {
    curStep = stepper - 1;
  } else {
    curStep = stepper;
  }

  const progress = (curStep * 100) / (totalSteps - 1);

  const navigate = useNavigate();

  const handleConvertValues = () => {
    if (
      formData[stepper - 1].category === "mass_measurement_unit" &&
      convertMeasurementValues
    ) {
      if (answers.myAnswers[formData[stepper - 1].category] === 2) {
        dispatch(
          answersActions.updateBiometricAnswers({
            current_weight: kgToPd(answers.myAnswers["current_weight"]),
            target_weight: kgToPd(answers.myAnswers["target_weight"]),
            height: cmToIn(answers.myAnswers.height),
          })
        );
      } else if (answers.myAnswers[formData[stepper - 1].category] === 1) {
        dispatch(
          answersActions.updateBiometricAnswers({
            current_weight: pdToKg(answers.myAnswers["current_weight"]),
            target_weight: pdToKg(answers.myAnswers["target_weight"]),
            height: inToCm(answers.myAnswers.height),
          })
        );
      }
      dispatch(stepperActions.handleConvert());
    }
  };

  const handleSubmitBiometricQuestionnairePlatform = async () => {
    try {
      const { email, firstName, lastName, pnoe_user_id } = ASUser.current;
      const info = { email, firstName, lastName, pnoe_user_id };

      console.log(info);
      const {
        cardio_training_level,
        fat_loss_intensity,
        fat_mass_target,
        muscle_mass_target,
        interval_training_level,
        resistance_training_level,
        ...PlatformAnswers
      } = answers.myAnswers;

      const dataForPlatform = { ...PlatformAnswers, ...info };

      let responsePlatform = await submitBiometricQuestionnaireToPlatformServer(
        dataForPlatform
      );
      console.log(
        "RESPONSE SUBMIT TO MANOS",
        dataForPlatform,
        responsePlatform
      );
    } catch (e: any) {
      console.log(e.response);
    }
  };

  const sendResponseFailed = async (errorResponse: any) => {
    try {
      // console.log(errorResponse);
      const { email, firstName, lastName, pnoe_user_id } = ASUser.current;
      const info = { email, firstName, lastName, pnoe_user_id };
      const {
        cardio_training_level,
        fat_loss_intensity,
        fat_mass_target,
        muscle_mass_target,
        interval_training_level,
        resistance_training_level,
        ...platformAnswers
      } = answers.myAnswers;

      const dataForPlatform = {
        ...platformAnswers,
        ...info,
        error: errorResponse,
      };

      console.log("TEST RESPONSE FAIL", JSON.stringify(dataForPlatform));

      let responseManos = await submitBiometricQuestionnaireToNUTServerFailed(
        dataForPlatform
      );
      console.log("RESPONSE FAIL", dataForPlatform, responseManos);
    } catch (e) {
      console.log("MANOS FAIL", e);
    }
  };

  const handleSubmitBiometricQuestionnaire = async () => {
    try {
      setIsLoading(true);
      await handleSubmitBiometricQuestionnairePlatform();

      let response = await submitBiometricQuestionnaireToNutServer(
        answers.myAnswers
      );
      console.log(response);
      navigate("/success-biometric");

      setIsLoading(false);
    } catch (e: any) {
      // const error = e;

      const errorObj = {
        canonical_name: e.response.data.http_error_code.canonical_name,
        message: e.response.data.message,
        status: e.response.status,
      };

      await sendResponseFailed(JSON.stringify(errorObj));

      dispatch(stepperActions.handleResponseStatus(e.response.status));
      // console.log("FAILED TO MAKIS", JSON.stringify(e.response));
      setIsLoading(false);
      navigate("/error");
    }
  };

  const nextStep = async () => {
    handleConvertValues();

    if (
      stepper === 7 &&
      answers.myAnswers.current_weight === answers.myAnswers.target_weight
    ) {
      dispatch(
        answersActions.updateBiometricAnswers({
          fat_loss_intensity: 0,
        })
      );
      dispatch(stepperActions.nextSkip());
      return;
    }
    if (
      stepper === 10 &&
      answers.myAnswers[formData[stepper - 1].category] === 0
    ) {
      dispatch(
        answersActions.updateBiometricAnswers({
          secondary_fitness_goal: 0,
        })
      );
      dispatch(stepperActions.nextSkip());
      return;
    }

    if (stepper >= totalSteps) {
      await handleSubmitBiometricQuestionnaire();
    } else {
      dispatch(stepperActions.next());
    }
  };
  const prevStep = () => {
    if (
      stepper === 9 &&
      answers.myAnswers.current_weight === answers.myAnswers.target_weight
    ) {
      dispatch(
        answersActions.updateBiometricAnswers({
          fat_loss_intensity: "",
        })
      );
      dispatch(stepperActions.prevSkip());
      return;
    }

    if (
      stepper === 12 &&
      answers.myAnswers[formData[stepper - 3].category] === 0
    ) {
      dispatch(
        answersActions.updateBiometricAnswers({
          secondary_fitness_goal: "",
        })
      );
      dispatch(stepperActions.prevSkip());
      return;
    }
    stepper > 1 && dispatch(stepperActions.prev());
  };

  const handleNextIsDisabled = () => {
    if (
      formData[stepper - 1].category === "current_weight" ||
      formData[stepper - 1].category === "target_weight"
    ) {
      if (answers.myAnswers.mass_measurement_unit === 1) {
        return (
          answers.myAnswers[formData[stepper - 1].category] < MIN_WEIGHT_KG
        );
      } else if (answers.myAnswers.mass_measurement_unit === 2) {
        return (
          answers.myAnswers[formData[stepper - 1].category] < MIN_WEIGHT_OZ
        );
      }
    }

    if (formData[stepper - 1].category === "height") {
      if (answers.myAnswers.mass_measurement_unit === 1) {
        return (
          answers.myAnswers[formData[stepper - 1].category] < MIN_HEIGHT_CM
        );
      } else if (answers.myAnswers.mass_measurement_unit === 2) {
        return (
          answers.myAnswers[formData[stepper - 1].category] < MIN_HEIGHT_IN
        );
      }
    }

    return (
      answers.myAnswers[formData[stepper - 1].category] === "" ||
      answers.myAnswers[formData[stepper - 1].category] === undefined
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.inner}>
        <Button text="Back" onClick={prevStep} isDisabled={stepper === 1} />
        <ProgressBar
          value={progress}
          curStep={curStep}
          totalSteps={totalSteps}
        />
        <Button
          text="Next"
          onClick={nextStep}
          isDisabled={handleNextIsDisabled()}
        />
      </div>
      <div className={classes.innerMobile}>
        <ProgressBar
          value={progress}
          curStep={curStep}
          totalSteps={totalSteps}
          prevStep={prevStep}
        />
      </div>
      <div className={classes.bottom}>
        <Button
          text="Next"
          customWidth="100%"
          onClick={nextStep}
          isDisabled={handleNextIsDisabled()}
        />
      </div>
    </div>
  );
};
export default Footer;
