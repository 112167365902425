import {
  lightTheme_Danger,
  lightTheme_Neutral,
  lightTheme_Primary,
  lightTheme_Success,
  lightTheme_Warning,
} from './colorTokens';

export type Colors_DS = {
  NEUTRAL_BG_MAIN: string; //Main background
  NEUTRAL_BG_ELEVATION1: string; //1st level of elevation
  NEUTRAL_BG_ELEVATION2: string; //2nd level of elevation. E.g. selector UI

  NEUTRAL_BORDER_DEFAULT: string; //Goes with “Main” bg colour
  NEUTRAL_BORDER_WEAK: string; //Goes with “Elevation 2” bg colour
  NEUTRAL_BORDER_GRAPH: string; //Used for background border in Graphs

  NEUTRAL_TEXT_STRONG: string; //Headers, Titles, Body Text Values,
  NEUTRAL_TEXT_DEFAULT: string; //Headers, Titles, Body Text Values,
  NEUTRAL_TEXT_WEAK: string; //Inactive state of icon
  NEUTRAL_TEXT_INACTIVE: string; //Inactive state of text

  NEUTRAL_ICON_DEFAULT: string; //Default state of icon
  NEUTRAL_ICON_WEAK: string; //Inactive state of icon
  NEUTRAL_ICON_STRONG: string; //Icon as a CTA. E.g. “Close”, “Heart” etc.

  NEUTRAL_GENERAL_WHITE: string; //General white colour
  NEUTRAL_BG_OVERLAY: string; //General white colour

  //PRIMARY
  PRIMARY_GRAPH_MAIN: string; //Used for foreground border in Graphs
  PRIMARY_TEXT_MAIN: string; //Used for text as CTA
  PRIMARY_ICON_MAIN: string; //CTA state of icon

  //WARNING
  WARNING_GRAPH_MAIN: string; //Used for foreground border in Graphs

  //DANGER
  DANGER_BG_DEFAULT: string; //Used for background elements. e.g. delete item
  DANGER_GRAPH_DEFICIT: string; //Used for foreground border in Graphs

  //SUCCESS
  SUCCESS_ICON_MAIN: string; //CTA state of icon
};

export const lightTheme_DS: Colors_DS = {
  NEUTRAL_BG_MAIN: '#FFFFFF',
  NEUTRAL_BG_ELEVATION1: lightTheme_Neutral[50],
  NEUTRAL_BG_ELEVATION2: '#FFFFFF',

  NEUTRAL_TEXT_STRONG: lightTheme_Neutral[100],
  NEUTRAL_TEXT_DEFAULT: lightTheme_Neutral[90],
  NEUTRAL_TEXT_WEAK: lightTheme_Neutral[80],
  NEUTRAL_TEXT_INACTIVE: lightTheme_Neutral[60],

  NEUTRAL_BORDER_DEFAULT: lightTheme_Neutral[60],
  NEUTRAL_BORDER_WEAK: lightTheme_Neutral[50],
  NEUTRAL_BORDER_GRAPH: lightTheme_Neutral[50],

  NEUTRAL_ICON_STRONG: lightTheme_Neutral[100],
  NEUTRAL_ICON_DEFAULT: lightTheme_Neutral[90],
  NEUTRAL_ICON_WEAK: lightTheme_Neutral[80],

  NEUTRAL_GENERAL_WHITE: '#FFFFFF',

  NEUTRAL_BG_OVERLAY: `${lightTheme_Neutral[100]}80`, //General white colour

  //PRIMARY

  PRIMARY_GRAPH_MAIN: lightTheme_Primary[60],
  PRIMARY_TEXT_MAIN: lightTheme_Primary[80],
  PRIMARY_ICON_MAIN: lightTheme_Primary[80],

  //WARNING

  WARNING_GRAPH_MAIN: lightTheme_Warning[70],

  //DANGER

  DANGER_BG_DEFAULT: lightTheme_Danger[80],
  DANGER_GRAPH_DEFICIT: lightTheme_Danger[70],

  // SUCCESS
  SUCCESS_ICON_MAIN: lightTheme_Success[60],
};
