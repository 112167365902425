import { MouseEventHandler, useState } from "react";

import classes from "./Button.module.css";
import useTheme from "../hooks/useTheme";

type Props = {
  text: string;
  onClick?: MouseEventHandler;
  isDisabled?: boolean;
  customWidth?: string;
  customMargin?: string;
  customHeight?: string;
  type?: any;
};
const OutlineButton = ({
  text,
  onClick,
  isDisabled,
  customWidth,
  customMargin,
  customHeight,
  type,
}: Props) => {
  const [showHover, setShowHover] = useState(false);
  const theme = useTheme();
  const handleMouseEnter = () => {
    setShowHover(true);
  };
  const handleMouseLeave = () => {
    setShowHover(false);
  };

  return (
    <button
      type={type}
      className={`${classes.btn} ${classes.btnOutline}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={
        isDisabled
          ? {
              cursor: "initial",
              width: customWidth,
              height: customHeight,
              borderColor: theme.colors_ds.NEUTRAL_BORDER_DEFAULT,
              color: theme.colors_ds.NEUTRAL_TEXT_INACTIVE,
            }
          : showHover
          ? {
              borderColor: theme.colors_ds.PRIMARY_ICON_MAIN,
              backgroundColor: theme.colorTokens.P[20],
              width: customWidth,
              margin: customMargin,
              height: customHeight,
              color: theme.colors_ds.PRIMARY_ICON_MAIN,
            }
          : {
              width: customWidth,
              margin: customMargin,
              height: customHeight,
              borderColor: theme.colors_ds.PRIMARY_ICON_MAIN,
              color: theme.colors_ds.PRIMARY_ICON_MAIN,
            }
      }
      onClick={onClick}
      disabled={isDisabled}
    >
      <span>{text}</span>
    </button>
  );
};

export default OutlineButton;
