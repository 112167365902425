import classes from "./ScaleItem.module.css";

const ScaleItem = ({data, color}) => {

    return (
        <div className={classes.inner} style={{backgroundColor: color}}>
            <div className={classes.title}>{data.TITLE}</div>
            <div
                className={`${classes.subTitle} ${color === "var(--secondary09)" && classes.subTitleBigFonts} ${data.INDEX === 3 && color === "var(--secondary09)" && classes.highlighted} `}>{data.TEXT}</div>
        </div>
    );
};

export default ScaleItem;
