import classes from "./BodyLinksImgBox.module.css";
import bodyImg from "../../images/bodyImgFull.png";
import useTheme from "../../../../common/hooks/useTheme";

const BodyLinksImgBox = () => {
  const theme = useTheme();

  return (
    <div
      className={classes.imgContainer}
      style={{ backgroundColor: theme.colorTokens.N[20] }}
    >
      <div className={classes.container}>
        <img src={bodyImg} alt="human figure" className={classes.img} />
      </div>
    </div>
  );
};
export default BodyLinksImgBox;
