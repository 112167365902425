import MetricCard from "./MetricCard";
import { useSelector } from "react-redux";
import { RootStatePlatform } from "../../store/platform-slice";
import useTheme from "../../../../common/hooks/useTheme";

import classes from "./ScoreScreenSidebar.module.css";

const ScoreScreenSidebar = () => {
  const theme = useTheme();
  const data = useSelector(
    (state: RootStatePlatform) => state.platform.data.score
  );

  const reportData = useSelector(
    (state: RootStatePlatform) => state.platform.dataPlatformServer
  );

  const sectionOne = [
    {
      type: 100,
      label: "Fat/Carb burn",
      value: `${reportData.core.fat.value}%/${reportData.core.carbs.value}%`,
      unit: "",
      scoreCategory: reportData.core.fat.score,
    },
    {
      type: 101,
      label: "Metabolic rate",
      value: reportData.core.metabolic_rate.value,
      unit: "kcal / day",
      scoreCategory: reportData.core.metabolic_rate.score,
    },
    {
      type: 102,
      label: "VO2max",
      value: reportData.core.vo2max.value,
      unit: "ml/min/kg",
      scoreCategory: reportData.core.vo2max.score,
    },
  ];

  return (
    <div className={classes.container}>
      <div
        className={classes.title}
        style={{
          color: theme.colors_ds.NEUTRAL_TEXT_STRONG,
        }}
      >
        Core function
      </div>
      {sectionOne.map((item: any, index: any) => (
        <MetricCard item={item} key={index} categories={data.scoreCategories} />
      ))}
      {reportData.metrics.length > 0 && (
        <div
          className={classes.title}
          style={{
            color: theme.colors_ds.NEUTRAL_TEXT_STRONG,
          }}
        >
          Organs & Systems
        </div>
      )}
      {reportData.metrics.map((item: any) => (
        <MetricCard
          item={item}
          key={item.type}
          categories={data.scoreCategories}
        />
      ))}
    </div>
  );
};

export default ScoreScreenSidebar;
