import classes from "./BiomarkerSection.module.css";
import useTheme from "../../../../common/hooks/useTheme";
import { useSelector } from "react-redux";
import { RootStatePlatform } from "../../store/platform-slice";
import ArrowDown from "../../svg/ArrowDown";
import BiomarkerCard from "./BiomarkerCard";
import PieChartWithoutSectors from "../common/PieChartWithoutSectors";
import { useState } from "react";

const BiomarkerSection = () => {
  const theme = useTheme();
  const [expandedBloodBiomarker, setExpandedBloodBiomarker] =
    useState<number>(-1);
  const [expandedBreathBiomarker, setExpandedBreathBiomarker] =
    useState<number>(-1);

  const reportData = useSelector(
    (state: RootStatePlatform) => state.platform.dataPlatformServer
  );
  const selectedMetric = useSelector(
    (state: RootStatePlatform) => state.platform.selectedMetric
  );

  const selectedMetricData = reportData.metrics.find(
    (el: any) => el.type === selectedMetric
  );

  const allBiomarkers = [
    ...selectedMetricData.breathBiomarkers,
    // ...selectedMetricData.BloodBiomarkers,
  ];

  const normalizedData = allBiomarkers.map((el: any) => {
    return {
      label: el.label,
      value: el.pieChartValue,
      categoryType: el.scoreCategory,
    };
  });

  return (
    <div
      className={classes.container}
      style={{ borderColor: theme.colors_ds.NEUTRAL_BORDER_WEAK }}
    >
      <div
        className={classes.sectionTitle}
        style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
      >
        Breath biomarkers related to your respiratory function health system:
      </div>
      <div className={classes.biomarkersContainer}>
        {[
          ...selectedMetricData.breathBiomarkers,
          ...selectedMetricData.breathBiomarkers,
        ].map((el: any, index: number) => (
          <BiomarkerCard
            item={el}
            key={index}
            setExpandedBiomarker={setExpandedBreathBiomarker}
            expandedBiomarker={expandedBreathBiomarker}
            index={index}
          />
        ))}
      </div>

      {selectedMetricData.BloodBiomarkers &&
        selectedMetricData.BloodBiomarkers.length > 0 && (
          <div
            className={classes.sectionTitle}
            style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
          >
            Blood biomarkers related to your respiratory function health system:
          </div>
        )}
      {selectedMetricData.BloodBiomarkers &&
        selectedMetricData.BloodBiomarkers.length > 0 && (
          <div className={classes.biomarkersContainer}>
            {selectedMetricData.BloodBiomarkers.map(
              (el: any, index: number) => (
                <BiomarkerCard
                  item={el}
                  key={index}
                  setExpandedBiomarker={setExpandedBloodBiomarker}
                  expandedBiomarker={expandedBloodBiomarker}
                  index={index}
                />
              )
            )}
          </div>
        )}
      <PieChartWithoutSectors
        title="Pie chart analysis of biomarkers"
        pieData={normalizedData}
        type="biomarkers"
      />
    </div>
  );
};

export default BiomarkerSection;
