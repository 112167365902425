import Layout from "../../components/reusable/Layout";

import BenefitTab from "./BenefitTab";

import classes from "./AuxiliaryTreatmentsPageV02.module.css";

type ListData = { INDEX: number; TEXT: string; TITLE: string; VALUE: number };

type Services = {
  TITLE: string;
  IMAGE_URL: string;
  INDEX: number;
  TABS_SECTION_1: ListData[];
  TABS_SECTION_2: ListData[];
};

interface Props {
  data: any;
}



const AuxiliaryTreatmentsPageV02 = ({ data }: Props) => {

  return (
    <>
      {data.SERVICES.map((service: any) => (
        <Layout key={service.INDEX}>
          <div className={classes.imgTherapyContainer}>
            {service.IMAGE_URL &&<img
                className={classes.imgTherapy}
                src={service.IMAGE_URL}
                alt="auxiliary treatment service"
            />}
            <div className={classes.titleContainer}>
              <div className={classes.title}>{service.TITLE}</div>
            </div>
          </div>
          <div className={classes.subTitleContainer}>
            <div className={classes.subTitle1}>{data.SUBTITLE_LEFT}</div>
            <div className={classes.subTitle2}>{data.SUBTITLE_RIGHT}</div>
          </div>
          <div className={classes.section}>
            <div className={classes.sectionTitle}>{data.SECTION_TITLE_1}</div>
            {service.TABS[0].SECTION_1.map((el: any) => (
              <BenefitTab key={el.INDEX} type={1} data={el} />
            ))}
          </div>
          {service.TABS[0].SECTION_2.length>0 && <div className={classes.section}>
            <div className={classes.sectionTitle}>{data.SECTION_TITLE_2}</div>
            {service.TABS[0].SECTION_2.map((el: any) => (
                <BenefitTab key={el.INDEX} type={2} data={el}/>
            ))}
          </div>}
        </Layout>
      ))}
    </>
  );
};

export default AuxiliaryTreatmentsPageV02;
