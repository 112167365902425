import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { platformActions, RootStatePlatform } from "../store/platform-slice";
import bodyImgFull from "../images/bodyImgFull.png";
import AgeClock from "../components/dashboard/BiologicalAgeGraph/AgeClock";
import useTheme from "../../../common/hooks/useTheme";
import DailyTasks from "../components/dashboard/DailyTasks";

import classes from "./DashboardScreen.module.css";
import { getHomePage } from "../../../common/network/Api";
import { useAppStore } from "../../../providers/AppStore";
import { UserRole } from "../../../common/entities/profile";

type Props = {};

type PersonalInfoItem = {
  item: {
    label: string;
    value: {
      value: number;
      unit: string;
    }[];
  };
};

const PersonalDetailsCard = ({ item }: PersonalInfoItem) => {
  const theme = useTheme();

  return (
    <div className={classes.personalDetailCard}>
      <div className={classes.personalDetailCardInner}>
        {item.value.map((el, index: number) => (
          <div
            key={index}
            className={classes.personalDetailValue}
            style={{ color: theme.colors_ds.NEUTRAL_TEXT_STRONG }}
          >
            {el.value}
            <span
              className={classes.personalDetailUnit}
              style={{ color: theme.colors_ds.NEUTRAL_TEXT_STRONG }}
            >
              {el.unit}
            </span>
          </div>
        ))}
      </div>
      <div
        className={classes.personalDetailLabel}
        style={{ color: theme.colors_ds.NEUTRAL_TEXT_WEAK }}
      >
        {item.label}
      </div>
    </div>
  );
};

const DashboardScreen = () => {
  const theme = useTheme();
  const [isLoadingDataNut, setIsLoadingDataNut] = useState(true);
  const { ASUser } = useAppStore();
  const reportData = useSelector(
    (state: RootStatePlatform) => state.platform.dataPlatformServer.dashboard
  );
  const ASActiveDate = useSelector(
    (state: RootStatePlatform) => state.platform.ASActiveDate
  );
  const dispatch = useDispatch();

  const inToFtAndIn = (value: number) => {
    return [
      { value: Math.floor(value / 12), unit: "ft" },
      { value: Math.round(value % 12), unit: "in" },
    ];
  };

  const personalInfo: {
    label: string;
    value: { value: number; unit: string }[];
  }[] = [
    {
      label: "Height",
      value:
        reportData.measurement_unit === 1
          ? inToFtAndIn(reportData.height)
          : [{ value: reportData.height, unit: "cm" }],
    },
    {
      label: "Current weight",
      value: [
        {
          value: reportData.weight,
          unit: reportData.measurement_unit === 1 ? "lb" : "kg",
        },
      ],
    },
    {
      label: "Target weight",
      value: [
        {
          value: reportData.target_weight,
          unit: reportData.measurement_unit === 1 ? "lb" : "kg",
        },
      ],
    },
  ];

  const getDataFromNut = async () => {
    try {
      setIsLoadingDataNut(true);
      let dataNut = await getHomePage(ASActiveDate);
      dispatch(platformActions.updateDataNutServer(dataNut));
    } catch (e: any) {
      console.log(e);
    } finally {
      setIsLoadingDataNut(false);
    }
  };

  useEffect(() => {
    getDataFromNut();
  }, [ASActiveDate]);

  return (
    <div className={classes.container}>
      <div className={classes.inner}>
        {ASUser.current?.role === UserRole.Customer && (
          <div
            className={classes.title}
            style={{
              color: theme.colors_ds.NEUTRAL_TEXT_STRONG,
            }}
          >
            {`Welcome ${reportData.name}!`}
          </div>
        )}
        <div className={classes.personalDetails}>
          {personalInfo.map(
            (
              item: {
                label: string;
                value: { value: number; unit: string }[];
              },
              index
            ) => (
              <PersonalDetailsCard item={item} key={index} />
            )
          )}
        </div>
        <DailyTasks
          isLoadingDataNut={isLoadingDataNut}
          setIsLoadingDataNut={setIsLoadingDataNut}
        />
        <AgeClock />
      </div>
      <div
        className={classes.imgContainer}
        style={{ backgroundColor: theme.colorTokens.N[20] }}
      >
        <img src={bodyImgFull} alt="human figure" className={classes.img} />
      </div>
    </div>
  );
};

export default DashboardScreen;
