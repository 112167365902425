import classes from "./DailyTasks.module.css";
import useTheme from "../../../../common/hooks/useTheme";
import { useSelector } from "react-redux";
import { RootStatePlatform } from "../../store/platform-slice";
import CustomDatePicker from "./CustomDatePicker";
import RestDayIcon from "../../svg/RestDayIcon";
import IsCompletedIcon from "../../svg/IsCompletedIcon";
import React from "react";
import { Skeleton } from "@mui/material";
import InfoIcon from "../../svg/InfoIcon";
import CustomTooltip from "../common/CustomTooltip";

type Props = {
  isLoadingDataNut: boolean;
  setIsLoadingDataNut: Function;
};

const DailyTasks = ({ isLoadingDataNut, setIsLoadingDataNut }: Props) => {
  const theme = useTheme();
  const resources = useSelector(
    (state: RootStatePlatform) => state.platform.data.dashboard.dailyTasks
  );

  const homeData = useSelector(
    (state: RootStatePlatform) => state.platform.dataNutServer
  );

  const completedTasks = homeData.overview_tab.daily_tasks.filter(
    (el: any) => el.is_completed === true
  ).length;

  const totalTasks = homeData.overview_tab.daily_tasks.length;

  const RestDayCard = () => {
    return (
      <div
        className={classes.taskInner}
        style={{
          borderColor: theme.colors_ds.NEUTRAL_BORDER_WEAK,
        }}
      >
        <RestDayIcon />
        <div
          className={classes.taskText}
          style={{ color: theme.colors_ds.NEUTRAL_TEXT_STRONG }}
        >
          {resources.restDayText}
        </div>
        <CustomTooltip message={resources.descriptionText}>
          <InfoIcon />
        </CustomTooltip>
      </div>
    );
  };

  const DailyTasksHeader = () => {
    return (
      <div className={classes.header}>
        <div
          className={classes.headerText}
          style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
        >
          {resources.completedText}
        </div>
        <div className={classes.valueContainer}>
          <div
            className={classes.value}
            style={{ color: theme.colors_ds.NEUTRAL_TEXT_STRONG }}
          >
            {completedTasks}
          </div>
          <div
            className={classes.maxValue}
            style={{ color: theme.colors_ds.NEUTRAL_TEXT_STRONG }}
          >{`/${totalTasks}`}</div>
        </div>
        <div
          className={classes.barContainer}
          style={{ backgroundColor: theme.colorTokens.S[10] }}
        >
          <div
            className={classes.barInner}
            style={{
              width: `${(completedTasks * 100) / totalTasks}%`,
              backgroundColor: theme.colorTokens.S[60],
            }}
          />
        </div>
        <CustomTooltip message={resources.descriptionText}>
          <InfoIcon />
        </CustomTooltip>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <CustomDatePicker isLoadingDataNut={isLoadingDataNut} />
      <div
        className={classes.title}
        style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
      >
        {resources.title}
      </div>

      <div
        className={classes.box}
        style={{ borderColor: theme.colors_ds.NEUTRAL_BORDER_WEAK }}
      >
        {isLoadingDataNut ? (
          <Skeleton
            variant="rounded"
            sx={{ bgcolor: theme.colorTokens.N[30] }}
            width={"100%"}
            height={53}
            animation="pulse"
          />
        ) : (
          <div>
            {totalTasks > 0 ? (
              <div>
                <DailyTasksHeader />
                <div
                  className={classes.tasksContainer}
                  style={{
                    gridTemplateColumns:
                      homeData.overview_tab.daily_tasks.length > 1
                        ? "1fr 1fr"
                        : "1fr",
                  }}
                >
                  {homeData.overview_tab.daily_tasks.map(
                    (task: any, index: number) => (
                      <div
                        key={index}
                        className={classes.taskInner}
                        style={{
                          borderColor: theme.colors_ds.NEUTRAL_BORDER_WEAK,
                        }}
                      >
                        <IsCompletedIcon
                          fill={
                            task.is_completed
                              ? theme.colors_ds.SUCCESS_ICON_MAIN
                              : theme.colors_ds.NEUTRAL_ICON_WEAK
                          }
                        />
                        <div
                          className={classes.taskText}
                          style={{ color: theme.colors_ds.NEUTRAL_TEXT_STRONG }}
                        >
                          {task.name}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            ) : (
              <RestDayCard />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DailyTasks;
