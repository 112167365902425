import classes from "./CheckBox.module.css";
import { useField } from "formik";
import React, { MouseEventHandler } from "react";
import useTheme from "../../../common/hooks/useTheme";

type Props = {
  type: string;
  name?: string;
  onClick?: MouseEventHandler;
};

const CheckBox = ({ type, onClick, ...props }: Props) => {
  // @ts-ignore
  const [field, meta] = useField({ ...props, type: "checkbox" });
  const theme = useTheme();
  return (
    <div>
      <div className={classes.container}>
        <div className={classes.inner}>
          <label className={classes.label}>
            <input {...field} {...props} type="checkbox" />
            <span
              className={classes.checkmark}
              style={{ borderColor: theme.colors_ds.PRIMARY_ICON_MAIN }}
            ></span>
            <span className={classes.subLabel}>I agree to</span>
          </label>
        </div>

        <a
          href={
            type === "terms"
              ? "https://pnoe.com/tcs/"
              : "https://pnoe.com/privacy-policy/"
          }
          target="_blank"
          className={classes.subLabelLink}
          style={{ color: theme.colors_ds.PRIMARY_ICON_MAIN }}
          onClick={onClick}
        >
          {type === "terms" ? "Terms and conditions" : "Data Processing"}
        </a>
      </div>
      <div
        className={classes.error}
        style={{ color: theme.colors_ds.DANGER_GRAPH_DEFICIT }}
      >
        {meta.error}
      </div>
    </div>
  );
};

export default CheckBox;
