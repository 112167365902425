import classes from "./CustomLabels.module.css";

type Legend2Obj = { INDEX: number; VALUE: string };

interface Props {
  data: {
    DESCRIPTION: string;
    INDEX: number;
    LEGEND_1: string;
    LEGEND_2: Legend2Obj[];
    LEGEND_X_1: string;
    LEGEND_Y_1: string;
    LEGEND_Y_2: string;
    TITLE: string;
  };
}

const CustomLabels = ({ data }: Props): JSX.Element => {
  return (
    <>
      <div className={classes.xAxisLabel}>{data.LEGEND_X_1}</div>
      <div className={classes.yAxisLabelLeft}>
        <div className={classes.legend1}>{data.LEGEND_Y_1}</div>
        <div className={classes.legend2}>{data.LEGEND_Y_2}</div>
      </div>
      <div className={classes.yAxisLabelRight}>
        <div className={classes.legend1}>{data.LEGEND_Y_1}</div>
        <div className={classes.legend2}>{data.LEGEND_Y_2}</div>
      </div>
    </>
  );
};

export default CustomLabels;
