export const MAX_AGE = 100;
export const MAX_WEIGHT_KG = 200;
export const MIN_WEIGHT_KG = 10;
export const MAX_WEIGHT_LB = 441;
export const MIN_WEIGHT_LB = 0;
export const MAX_WEIGHT_OZ = 15;
export const MIN_WEIGHT_OZ = 22;
export const MAX_HEIGHT_CM = 241;
export const MIN_HEIGHT_CM = 100;

export const MAX_HEIGHT_FT = 9;
export const MIN_HEIGHT_FT = 0;
export const MAX_HEIGHT_IN = 11;
export const MIN_HEIGHT_IN = 39;
