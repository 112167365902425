import Layout from "../../components/reusable/Layout";
import TableItem from "../../components/reusable/TableItem";
import Table from "../../components/reusable/Table";
import Title from "../../components/reusable/Title";

import classes from "./EnergyConsumptionFuelingPage.module.css";

type cell = { INDEX: number; VALUE: string };
type column = cell[];
type row = { ROW: number; VALUES: column };

interface Props {
  data: {
    DESCRIPTION: null;
    INDEX: number;
    SUBTITLE: string;
    SECTION1: { INDEX: number; TABLE: row[] };
    SECTION2: {
      INDEX: number;
      TABLE: row[];
      TABLE_FOOTER: row[];
      TITLE: string;
    };
    TITLE: string;
  };
  reportType: string;
}

const EnergyConsumptionFuelingPage = ({ data, reportType }: Props): JSX.Element => {
  return (
    <Layout>
      <Title title={data.TITLE} />
      <TableItem
        data={data.SECTION1}
        type="simpleTitle"
        evenColumns={true}
        minHeightTitle="60px"
        minHeight="80px"
        cardHeight={0}
        rowHeight={""}
        thresholdsTable={false}
      />
      {reportType!=="14" &&<div className={classes.section2}>
        <TableItem
            data={data.SECTION2}
            type="sectionTitle"
            minHeightTitle="35px"
            minHeight="35px"
            thresholdsTable={true}
            cardHeight={0}
            evenColumns={false}
            rowHeight={""}
        />
        <div className={classes.footer}>
          <Table
              data={data.SECTION2.TABLE_FOOTER}
              footer={true}
              minHeight="80px"
              minHeightTitle="20px"
              customAlign="flex-start"
              rowHeight={""}
          />
        </div>
      </div>}
    </Layout>
  );
};

export default EnergyConsumptionFuelingPage;
