import { useSelector } from "react-redux";

import Quest from "../layout/Quest";
import { useEffect } from "react";
import Layout from "../layout/Layout";
import { useNavigate } from "react-router-dom";
import LifestyleQuest from "../layout/LifestyleQuest";
import { RootStateStepper } from "../../store/stepper-slice";
import { useAppStore } from "../../../../providers/AppStore";

const LifestyleQuestionnaire = () => {
  const stepper = useSelector((state: RootStateStepper) => state.stepper.step);
  const navigate = useNavigate();
  const { ASIsUserLoaded } = useAppStore();

  useEffect(() => {
    if (!ASIsUserLoaded()) {
      navigate("/auth");
    }
  }, [stepper]);

  return (
    <Layout>
      <LifestyleQuest />
    </Layout>
  );
};

export default LifestyleQuestionnaire;
