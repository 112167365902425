import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Footer from "./Footer";
import CustomDatePicker from "../form/CustomDatePicker";
import BtnForm from "../form/BtnForm";
import ListForm from "../form/ListForm";
import ScaleForm from "../form/ScaleForm";
import DoubleScaleForm from "../form/DoubleScaleForm";
import PageLoader from "../../../../common/ui/PageLoader";

import { answersActions, RootStateAnswers } from "../../store/answers-slice";
import { RootStateStepper, stepperActions } from "../../store/stepper-slice";

import classes from "./Quest.module.css";
import useTheme from "../../../../common/hooks/useTheme";

type Props = {
  flexType?: any;
  scaleType?: string;
  labelDescription?: boolean;
};

const Quest = ({ flexType, scaleType, labelDescription }: Props) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const stepper = useSelector((state: RootStateStepper) => state.stepper.step);
  const formData = useSelector(
    (state: RootStateStepper) => state.stepper.formData
  );
  const totalSteps = useSelector(
    (state: RootStateStepper) => state.stepper.totalSteps
  );
  const answers = useSelector(
    (state: RootStateAnswers) => state.answers.biometricQuest
  );
  const [isLoading, setIsLoading] = useState(false);

  const curAnswer = answers.myAnswers[formData[stepper - 1].category];

  const handleChange = (e: { target: { value: string | number } }) => {
    if (formData[stepper - 1].category === "mass_measurement_unit") {
      dispatch(stepperActions.handleConvert());
      dispatch(
        answersActions.updateBiometricAnswers({
          length_measurement_unit: +e.target.value,
        })
      );
    }
    dispatch(
      answersActions.updateBiometricAnswers({
        [formData[stepper - 1].category]: +e.target.value,
      })
    );
  };

  useEffect(() => {
    // save answers to local storage

    const json = JSON.stringify(answers.myAnswers);
    localStorage.setItem("notes", json);
  }, [answers.myAnswers]);

  const handleQuestContent = (type: string) => {
    if (type === "datepicker") {
      return <CustomDatePicker />;
    }
    if (type === "btn") {
      return (
        <>
          {formData[stepper - 1].choices.map(
            (choice: {
              index: number;
              label: string;
              lengthUnit?: string;
              massUnit?: string;
              info?: string;
            }) => (
              <BtnForm
                key={choice.index}
                data={choice}
                value={choice.index}
                category={formData[stepper - 1].category}
                answer={curAnswer}
                checked={curAnswer === choice.index}
                onChange={handleChange}
              />
            )
          )}
        </>
      );
    }
    if (
      (type === "scale" && answers.myAnswers.mass_measurement_unit === 1) ||
      ((formData[stepper - 1].category === "current_weight" ||
        formData[stepper - 1].category === "target_weight") &&
        answers.myAnswers.mass_measurement_unit === 2)
    ) {
      return <ScaleForm scaleType={scaleType} />;
    }

    if (type === "scale" && answers.myAnswers.mass_measurement_unit === 2) {
      return <DoubleScaleForm scaleType={scaleType} />;
    }

    if (type === "list") {
      return (
        <>
          {formData[stepper - 1].choices.map(
            (choice: {
              index: number;
              label: string;
              lengthUnit?: string;
              massUnit?: string;
              info?: string;
            }) => (
              <ListForm
                key={choice.index}
                data={choice}
                value={choice.index}
                category={formData[stepper - 1].category}
                answer={curAnswer}
                checked={curAnswer === choice.index}
                onChange={handleChange}
                measurement_unit={answers.myAnswers.mass_measurement_unit}
                labelDescription={labelDescription}
              />
            )
          )}
        </>
      );
    }
  };

  return (
    <div className={classes.container}>
      {isLoading && <PageLoader />}
      {!isLoading && (
        <div>
          <div className={classes.inner}>
            <div className={classes.footerMobile}>
              <Footer setIsLoading={setIsLoading} />
            </div>
            <div
              className={classes.stepper}
              style={{ color: theme.colors_ds.NEUTRAL_TEXT_WEAK }}
            >
              {stepper <= 10 ? stepper : stepper - 1}/
              {totalSteps <= 10 ? totalSteps : totalSteps - 1}
            </div>
            <div
              className={classes.label}
              style={{
                color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT,
                borderLeftColor: theme.colors_ds.NEUTRAL_TEXT_DEFAULT,
              }}
            >
              {formData[stepper - 1].question}
            </div>
            <div
              className={classes.options}
              style={{ flexDirection: flexType }}
            >
              {handleQuestContent(formData[stepper - 1].type)}
            </div>
            <div className={classes.footerWeb}>
              <Footer setIsLoading={setIsLoading} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Quest;
