import { useEffect, useState } from "react";

import Layout from "../../components/reusable/Layout";
import Title from "../../components/reusable/Title";

import ChartSection from "./ChartSection";

import classes from "./MacronutrientBalancePage.module.css";

const MacronutrientBalancePage = ({ data, onSvgConverter }) => {
  const [imgLoad, setImgLoad] = useState(false);

  useEffect(() => {
    onSvgConverter("main", "result", 760);
    setImgLoad(true);
  }, [onSvgConverter]);

  let imgMain = (
    <div id="main">
      {data.SECTIONS.map((section) => (
        <div
          key={section.INDEX}
          id="customRadarChart_section1"
          className={classes.section}
        >
          <ChartSection data={section} />
        </div>
      ))}
    </div>
  );

  return (
    <Layout>
      <Title title={data.TITLE} />
      <div id="result" className={classes.imgMain}>
        {!imgLoad && imgMain}
      </div>
    </Layout>
  );
};

export default MacronutrientBalancePage;
