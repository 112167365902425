export const metricType: { [key: number]: string } = {
  1: "fatCarbBurn",
  2: "metabolicRate",
  3: "VO2max",
  4: "lungs",
  5: "brain",
  6: "sleep",
  8: "sexualWellness",
  9: "respiratoryFunction",
  10: "metabolism",
  11: "metabolicDysfunctionRisk",
  12: "nervousSystem",
  13: "posture",
  14: "heart",
  15: "endurance",
  16: "recovery",
  17: "gut",
  20: "growthHormones",
  21: "stressHormones",
  22: "inflammation",
  23: "liver",
  24: "pancreas",
  25: "kidneys",
  26: "bones",
  28: "hungerHormones",
  29: "autoImmunity",
};

export const dataPointsIds = {
  fatCarbBurn: "fatCarbBurn",
  metabolicRate: "metabolicRate",
  VO2max: "VO2max",
  pituitaryGland: "pituitaryGland",
  intersect: "intersect",
  cell: "cell",
  brain: "brain",
  thyroid: "thyroid",
  posture: "posture",
  heart: "heart",
  lungs: "lungs",
  liver: "liver",
  gut: "gut",
  diaphragm: "diaphragm",
  stomach: "stomach",
  bones: "bones",
  kidneys: "kidneys",
};

export const organConnectionsWithMetric = {
  lungs: ["cell", "heart", "brain", "VO2max"],
  respiratoryFunction: ["metabolicRate", "brain", "posture"],
  metabolism: ["cell", "pituitaryGland"],
  metabolicDysfunctionRisk: ["heart", "fatCarbBurn"],
  nervousSystem: ["lungs", "heart", "cell", "gut", "pituitaryGland"],
  posture: ["lungs", "brain", "cell"],
  heart: ["lungs", "cell", "pituitaryGland", "VO2max"],
  endurance: ["VO2max"],
  recovery: ["lungs", "brain", "cell", "pituitaryGland"],
  brain: ["lungs", "pituitaryGland", "cell"],
  gut: ["cell", "brain", "pituitaryGland"],
  sleep: ["cell", "brain"],
  sexualWellness: ["brain", "cell"],
  hungerHormones: ["cell", "brain", "liver", "bones"],
  growthHormones: ["metabolicRate", "cell", "brain", "bones"],
  stressHormones: ["lungs", "brain", "heart", "cell", "gut"],
  inflammation: ["lungs", "heart", "cell", "brain", "gut"],
  liver: ["heart", "brain", "kidneys", "bones", "cell"],
  pancreas: ["lungs", "cell", "gut", "bones"],
  kidneys: ["heart", "brain", "gut", "cell"],
  bones: ["posture"],
  autoImmunity: ["brain", "gut", "liver", "bones"],
};

export const handleSelectedMetricKeyForDuplicateOrgans = (key: string) => {
  switch (key) {
    case "sleep":
    case "sexualWellness":
    case "growthHormones":
    case "stressHormones":
    case "hungerHormones":
      return dataPointsIds.pituitaryGland;
    case "metabolism":
      return dataPointsIds.metabolicRate;
    case "respiratoryFunction":
      return dataPointsIds.diaphragm;
    case "nervousSystem":
      return dataPointsIds.brain;
    case "metabolicDysfunctionRisk":
    case "endurance":
    case "recovery":
    case "inflammation":
    case "autoImmunity":
      return dataPointsIds.cell;
    case "pancreas":
      return dataPointsIds.liver;
    default:
      return key;
  }
};

export const programmingTabs = [
  { label: "Nutrition plan", key: 0 },
  { label: "Workout plan", key: 1 },
  { label: "Treatment plan", key: 2 },
];

export const getCategoryColor = (
  unit: string,
  value: number,
  theme: any,
  scoreCategory?: number
) => {
  if (unit === "%") {
    if (value < 20) {
      return theme.colorTokens.D[60];
    } else if (value < 40) {
      return theme.colorTokens.W[60];
    } else if (value < 60) {
      return theme.colorTokens.P[60];
    } else if (value < 80) {
      return theme.colorTokens.S[50];
    } else {
      return theme.colorTokens.S[60];
    }
  }

  switch (scoreCategory) {
    case 1:
      return theme.colorTokens.D[60];
    case 2:
      return theme.colorTokens.W[60];
    case 3:
      return theme.colorTokens.P[60];
    case 4:
      return theme.colorTokens.S[50];
    case 5:
      return theme.colorTokens.S[60];
  }
  return theme.colors_ds.NEUTRAL_TEXT_DEFAULT;
};

export const getBiomarkerCategoryColor = (
  type: number,
  theme: any,
  category: string
) => {
  if (category === "biomarkers") {
    switch (type) {
      case 1:
        return theme.colorTokens.D[60];
      case 2:
        return theme.colorTokens.W[60];
      default:
        return theme.colorTokens.S[50];
    }
  }
  if (category === "nutrition") {
    switch (type) {
      case 1:
        return theme.colorTokens.W[60];
      case 2:
        return theme.colorTokens.P[60];
      default:
        return theme.colorTokens.S[60];
    }
  }

  if (category === "trainingBreakdown") {
    switch (type) {
      case 1:
        return theme.colorTokens.W[40];
      case 2:
        return theme.colorTokens.P[60];
      default:
        return theme.colorTokens.D[50];
    }
  }
};

export const getNutritionProgrammingCategoryColor = (
  type: number,
  theme: any
) => {
  switch (type) {
    case 1:
      return theme.colorTokens.P[60];
    case 2:
      return theme.colorTokens.W[40];
    default:
      return theme.colorTokens.N[50];
  }
};

export const getCategoryLabel = (
  unit: string,
  value: number,
  categories: {
    [key: string]: string;
  },
  scoreCategory?: number
) => {
  if (unit === "%") {
    if (value < 20) {
      return categories[1];
    } else if (value < 40) {
      return categories[2];
    } else if (value < 60) {
      return categories[3];
    } else if (value < 80) {
      return categories[4];
    } else {
      return categories[5];
    }
  }
  if (scoreCategory) {
    return categories[scoreCategory];
  }
  return "--";
};

export enum tabs {
  NONE = 0,
  CLIENTS = 1,
  DASHBOARD = 2,
  SCORE = 3,
  PROGRAMMING = 4,
  SCHEDULING = 5,
  CLIENT_INFO = 6,
}
