import classes from "./Memo.module.css";

interface Props {
  text: string;
  icon?: string;
  color?: string;
  size?: string;
  fontFamily?: string;
}

const Memo = ({ text, icon, color, size, fontFamily }: Props): JSX.Element => {
  let iconStyle: JSX.Element;
  if (icon) {
    iconStyle = (
      <img
        className={classes.arrow}
        src={icon}
        alt="arrow shows how the factor affects the biological age"
      />
    );
  } else {
    iconStyle = (
      <div
        className={classes.dot}
        style={{ backgroundColor: color, width: size, height: size }}
      ></div>
    );
  }
  return (
    <div className={classes.inner}>
      {iconStyle}
      <div
        className={classes.label}
        style={{ fontFamily: fontFamily, fontSize: size }}
      >
        {text}
      </div>
    </div>
  );
};

export default Memo;
