import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
} from "recharts";

import Axis from "./Axis";
import AxisPrediction from "./AxisPrediction";
import CustomLabelsTriangle from "../../pages/TrainingProgramTrianglePage/CustomLabelsTriangle";
import CustomLabelsPolygon from "../../pages/AuxillaryTreatmentChartPage/CustomLabelsPolygon";
import CustomLabelsTrianglePrediction from "../../pages/PerformancePredictionPage/CustomLabelsTrianglePrediction";
import CustomLabelsMacronutrient from "../../pages/MacronutrientBalancePage/CustomLabelsMacronutrient";

import classes from "./CustomRadarChart.module.css";
import CustomLabelsTriangleFollicular from "../../pages/StressReportChartsPage/CustomLabelsTriangleFollicular";
import CustomLabelsTriangleFollicular02 from "../../pages/StressReportChartsPage/CustomLabelsTriangleFollicular02";

type ChartData = { INDEX: number; NAME: string; VALUE: number };

type LabelData = {
  INDEX: number;
  NAME?: string;
  TITLE?: string;
  VALUE?: number;
  PERCENT?: string;
  G?: string;
  KCAL?: string;
};

interface Props {
  data: ChartData[];
  dataLabels: LabelData[];
  title: string;
  footer1: string;
  footer2: string;
  width: number;
  height: number;
  typeOfLabelCustom: string;
  isAxisCustom?: boolean;
  isAxisPredictionCustom?: boolean;
  radius: string;
  gridStroke: string;
  showTicks: boolean;
  startAngle: number;
  endAngle: number;
  maxDomain: number;
  tickCount: number;
  marginTop?: number;
}

const CustomRadarChart = ({
  data,
  dataLabels,
  title,
  footer1,
  footer2,
  width,
  height,
  typeOfLabelCustom,
  isAxisCustom,
  isAxisPredictionCustom,
  radius,
  gridStroke,
  showTicks,
  startAngle,
  endAngle,
  maxDomain,
  tickCount,
  marginTop,
}: Props): JSX.Element => {
  return (
    <div className={classes.container} style={{ marginTop: marginTop }}>
      {typeOfLabelCustom === "triangle" && <CustomLabelsTriangle data={data} />}
      {typeOfLabelCustom === "trianglePrediction" && (
        <CustomLabelsTrianglePrediction
          data={data}
          title={title}
          footer1={footer1}
          footer2={footer2}
        />
      )}
      {typeOfLabelCustom === "triangleFollicular" && (
        <CustomLabelsTriangleFollicular data={data} title={title} />
      )}
      {typeOfLabelCustom === "polygon" && <CustomLabelsPolygon data={data} />}
      {typeOfLabelCustom === "macronutrient" && (
        <CustomLabelsMacronutrient data={dataLabels} />
      )}

      <div className={classes.innerContainer}>
        {isAxisCustom && <Axis />}
        {isAxisPredictionCustom && <AxisPrediction />}

        <RadarChart
          className={classes.chart}
          height={height}
          width={width}
          outerRadius={radius}
          data={data}
          startAngle={startAngle} // 90 default
          endAngle={endAngle} //-270 default
          // margin={margin}
        >
          <PolarGrid stroke={gridStroke} gridType="polygon" />
          <PolarAngleAxis dataKey="NAME" tick={showTicks} />
          <PolarRadiusAxis
            domain={[0, maxDomain]}
            opacity={0}
            tickCount={tickCount}
          />
          <defs>
            <radialGradient id="color" gradientUnits="userSpaceOnUse">
              <stop
                offset="0%"
                stopColor="var(--secondary19)"
                stopOpacity={0.6}
              />
              <stop
                offset="70%"
                stopColor="var(--secondary09)"
                stopOpacity={0.6}
              />
            </radialGradient>
          </defs>

          <Radar
            isAnimationActive={false}
            dataKey="VALUE"
            stroke="transparent"
            fill="url(#color)"
            dot={{
              stroke: "var(--primary400)",
              strokeWidth: 1,
              r: 8,
              fill: "white",
            }}
          />
        </RadarChart>
      </div>
      {typeOfLabelCustom === "triangleFollicular02" && (
        <CustomLabelsTriangleFollicular02 data={dataLabels} />
      )}
    </div>
  );
};

export default CustomRadarChart;
