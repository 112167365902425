import React from "react";

import classes from "./CircularGraph.module.css";
import useTheme from "../../../../common/hooks/useTheme";

type Props = {
  size: number;
  strokeWidth: number;
  value: number;
  color?: string;
};

const CircularGraph = ({ size, strokeWidth, value, color }: Props) => {
  const theme = useTheme();
  const radius = (size - strokeWidth - 1) / 2;
  const circumference = radius * 2 * Math.PI;

  const svgProgress = 100 - (value / 100) * 100;

  return (
    <div style={{ position: "relative", width: size, height: size }}>
      <svg width={size} height={size}>
        <circle
          stroke={theme.colorTokens.N[40]}
          fill="none"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={strokeWidth}
        />
        <circle
          stroke={color}
          fill="none"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={circumference * (svgProgress / 100)}
          strokeLinecap="round"
          strokeWidth={strokeWidth}
          style={{
            transition: "stroke-dashoffset 0.35s",
            transform: "rotate(-90deg)",
            transformOrigin: "50% 50%",
          }}
        />
      </svg>
      <div className={classes.textContainer}>
        <div
          className={classes.text}
          style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
        >{`${value}%`}</div>
      </div>
    </div>
  );
};

export default CircularGraph;
