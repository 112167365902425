import classes from "./RecommendationCard.module.css";
import ArrowDown from "../../svg/ArrowDown";
import useTheme from "../../../../common/hooks/useTheme";
import { useState } from "react";
import ArrowUp from "../../svg/ArrowUp";

type Props = {
  item: any;
  index: number;
  color: string;
  expandedRecommendation: number;
  setExpandedRecommendation: (value: number) => void;
  cardHasNoColorCoding?: boolean;
};

const RecommendationCard = ({
  item,
  index,
  color,
  expandedRecommendation,
  setExpandedRecommendation,
  cardHasNoColorCoding,
}: Props) => {
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className={classes.recommendationCardContainer}>
      <div
        className={classes.recommendationCard}
        style={{
          borderColor:
            expandedRecommendation === index
              ? theme.colors_ds.PRIMARY_ICON_MAIN
              : isHovered
              ? theme.colors_ds.NEUTRAL_BORDER_DEFAULT
              : theme.colors_ds.NEUTRAL_BORDER_WEAK,
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {!cardHasNoColorCoding && (
          <div
            className={classes.categoryIcon}
            style={{ backgroundColor: color }}
          />
        )}
        <div className={classes.recommendationCardLabel}>{item.name}</div>

        <div
          onClick={() => {
            if (expandedRecommendation === index) {
              setExpandedRecommendation(-1);
            } else {
              setExpandedRecommendation(index);
            }
          }}
        >
          <div
            style={{
              transform:
                expandedRecommendation === index ? "rotate(180deg)" : "",
              transition: "transform 0.9s ease",
              height: "16px",
            }}
          >
            <ArrowDown width={16} height={16} />
          </div>
        </div>
      </div>
      <div
        className={`${classes.recommendationCardDescription} ${
          expandedRecommendation === index ? classes.expanded : ""
        }`}
        style={index % 2 !== 0 ? { marginLeft: "-250px" } : {}}
      >
        <div className={classes.descriptionTitle}>{item.name}</div>
        <div className={classes.descriptionText}>{item.description}</div>
      </div>
    </div>
  );
};

export default RecommendationCard;
