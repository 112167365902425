import classes from "./MetricCard.module.css";
import CircularGraph from "../common/CircularGraph";
import { getCategoryColor, getCategoryLabel } from "../../store/helper";
import { useDispatch, useSelector } from "react-redux";
import { platformActions, RootStatePlatform } from "../../store/platform-slice";
import useTheme from "../../../../common/hooks/useTheme";
import { useState } from "react";

type Props = { item: any; categories: any };

const MetricCard = ({ item, categories }: Props) => {
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const dispatch = useDispatch();
  let categoryColor = getCategoryColor(
    item.unit,
    item.value,
    theme,
    item.scoreCategory
  );

  const selectedMetric = useSelector(
    (state: RootStatePlatform) => state.platform.selectedMetric
  );

  return (
    <div
      className={classes.cardContainer}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => dispatch(platformActions.setSelectedMetric(item.type))}
      style={
        selectedMetric === item.type
          ? { borderColor: theme.colors_ds.PRIMARY_ICON_MAIN }
          : isHovered
          ? { borderColor: theme.colors_ds.NEUTRAL_BORDER_DEFAULT }
          : { borderColor: theme.colors_ds.NEUTRAL_BORDER_WEAK }
      }
    >
      <div
        className={classes.label}
        style={
          item.unit === "%"
            ? { width: "80%", color: theme.colors_ds.NEUTRAL_TEXT_STRONG }
            : { color: theme.colors_ds.NEUTRAL_TEXT_STRONG }
        }
      >
        {item.label}
      </div>
      <div className={classes.details}>
        <div className={classes.category} style={{ color: categoryColor }}>
          {getCategoryLabel(
            item.unit,
            item.value,
            categories,
            item.scoreCategory
          )}
        </div>
        {item.unit === "%" ? (
          <div className={classes.circularGraphContainer}>
            <CircularGraph
              size={49}
              strokeWidth={4}
              value={item.value}
              color={categoryColor}
            />
          </div>
        ) : (
          <div className={classes.valueContainer}>
            <div
              className={classes.value}
              style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
            >
              {item.value}
            </div>
            <div
              className={classes.unit}
              style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
            >
              {item.unit}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MetricCard;
