import CustomCard from "./CustomCard";

import classes from "./Qrcode.module.css";

interface Props {
  data: { FOOTER1: string; HEADER1: string; HEADER2: string; QRCODE: string };
  sources?: any;
}

let column1: [], column2: [];

const Qrcode = ({ data, sources }: Props): JSX.Element => {
  if (sources.length > 0) {
    const half = Math.round(sources.length / 2);

    column1 = sources.slice(0, half);
    column2 = sources.slice(half);
  }

  return (
    <CustomCard cardWidth="95%" cardMargin="0 20px" cardPadding="5px 10px">
      <div className={classes.qrcode}>
        <div className={classes.qrcodeInner2}>
          <img
            className={classes.qrcodeImg}
            src={data.QRCODE}
            alt="QR code"
          ></img>
          <div className={classes.header2}>{data.HEADER2}</div>
        </div>
        {sources.length > 0 && (
          <div className={classes.qrcodeInner}>
            <div className={classes.footer1}>{data.FOOTER1}</div>
            <div className={classes.sourcesStyle}>
              <div className={classes.column}>
                {column1.map(
                  (el: {
                    VALUES: {
                      VALUE: string;
                    }[];
                  }) => (
                    <div className={classes.listItem} key={el.VALUES[0].VALUE}>
                      <div className={classes.point}></div>
                      <div style={{ width: "285px" }}>{el.VALUES[0].VALUE}</div>
                    </div>
                  )
                )}
              </div>
              <div className={classes.column}>
                {column2.map(
                  (el: {
                    VALUES: {
                      VALUE: string;
                    }[];
                  }) => (
                    <div className={classes.listItem} key={el.VALUES[0].VALUE}>
                      <div className={classes.point}></div>
                      <div style={{ width: "285px" }}>{el.VALUES[0].VALUE}</div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </CustomCard>
  );
};

export default Qrcode;
