import SimpleBar from "simplebar-react";

import "simplebar-react/dist/simplebar.min.css";
import classes from "./CustomPicker.module.css";
import "./CustomPicker.css";

import useTheme from "../../../../common/hooks/useTheme";
import Arrow from "../../../../common/svgs/arrow";

type Props = {
  type: string;
  data: any;
  value: number | string;
  setValue: Function;
  showPanel: string;
  setShowPanel: Function;
  columns: number;
  showError: boolean;
};

const CustomPicker = ({
  type,
  data,
  value,
  setValue,
  showPanel,
  setShowPanel,
  columns,
  showError,
}: Props) => {
  const theme = useTheme();
  const onChangeHandler = (e: any) => {
    setValue(e.target.id);
    setShowPanel("");
  };

  return (
    <div
      className={`${classes.container} ${
        type === "Month" && classes.monthContainer
      }`}
    >
      <div
        className={classes.inputContainer}
        onClick={() => setShowPanel(showPanel === type ? "" : type)}
      >
        <input
          readOnly
          className={`${classes.input} ${value !== "" && classes.inputOk}`}
          placeholder={type}
          type="text"
          value={value}
          name="birth_date"
          onChange={(e) => setValue(() => e.target.value)}
          onClick={() => setShowPanel(showPanel === type ? "" : type)}
          style={{
            backgroundColor: theme.colors_ds.NEUTRAL_BG_MAIN,
            // color: theme.colors_ds.NEUTRAL_TEXT_WEAK,
            border: showError
              ? `1px solid ${theme.colors_ds.DANGER_GRAPH_DEFICIT}`
              : "",
          }}
        />
        <div
          style={{
            transform: showPanel === type ? "" : "rotate(180deg)",
            transition: "transform 150ms ease",
          }}
          className={classes.icon}
        >
          <Arrow />
        </div>
      </div>
      {showPanel === "Day" && type === "Day" && (
        <div className={`${classes.panel} ${classes.panelGridDay}`}>
          {data.map((el: number, index: number) => (
            <div
              key={index}
              className={classes.selections}
              style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
              id={el.toString()}
              onClick={onChangeHandler}
            >
              {el}
            </div>
          ))}
        </div>
      )}
      {showPanel === "Month" && type === "Month" && (
        <>
          <div
            className={`${classes.panel} ${classes.panelGridMonth} ${classes.web} `}
          >
            {data.map(
              (
                el: { index: number; MM: string; MMMM: string },
                index: number
              ) => (
                <div
                  key={index}
                  className={classes.selections}
                  style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
                  id={el.MMMM}
                  onClick={onChangeHandler}
                >
                  {el.MM}
                </div>
              )
            )}
          </div>
          <div className={classes.mobile}>
            <SimpleBar
              forceVisible="y"
              autoHide={false}
              className={`${classes.panel} ${classes.panelMonth} `}
              style={{ height: 188, gridTemplateColumns: `repeat(1 ,1fr)` }}
            >
              {data.map(
                (
                  el: { index: number; MM: string; MMMM: string },
                  index: number
                ) => (
                  <div
                    key={index}
                    className={`${classes.selections}  ${classes.year}`}
                    style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
                    id={el.MMMM}
                    onClick={onChangeHandler}
                  >
                    {el.MM}
                  </div>
                )
              )}
            </SimpleBar>
          </div>
        </>
      )}
      {showPanel === "Year" && type === "Year" && (
        <SimpleBar
          forceVisible="y"
          autoHide={false}
          className={`${classes.panel} ${
            type === "Year" && classes.panelYear
          } `}
          style={{
            gridTemplateColumns: `repeat(${columns},1fr)`,
            height: 188,
          }}
        >
          {data.map((el: number, index: number) => (
            <div
              key={index}
              className={`${classes.selections} ${classes.year}`}
              style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
              id={el.toString()}
              onClick={onChangeHandler}
            >
              {el}
            </div>
          ))}
        </SimpleBar>
      )}
    </div>
  );
};

export default CustomPicker;
