import classes from "./ClientInfoScreen.module.css";
import { useSelector } from "react-redux";
import { RootStatePlatform } from "../store/platform-slice";
import useTheme from "../../../common/hooks/useTheme";

const ProfileDetail = ({ label, value }: { label: string; value: string }) => {
  const theme = useTheme();

  return (
    <div className={classes.infoDetail}>
      <div
        className={classes.infoDetailLabel}
        style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
      >
        {label}
      </div>
      <div
        className={classes.infoDetailValue}
        style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
      >
        {value}
      </div>
    </div>
  );
};

const ClientInfoScreen = () => {
  const theme = useTheme();
  const selectedCustomer = useSelector(
    (state: RootStatePlatform) => state.platform.selectedCustomer
  );

  const programOptions = [
    selectedCustomer.report ? "Report" : "",
    selectedCustomer.workout ? "Workout" : "",
    selectedCustomer.nutrition ? "Nutrition" : "",
  ]
    .filter(Boolean)
    .join(", ");

  return (
    <div className={classes.clientInfoContainer}>
      <div
        className={classes.clientInfoTitle}
        style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
      >{`${selectedCustomer.firstName} ${selectedCustomer.lastName}`}</div>
      <div
        className={classes.clientInfoDetailsContainer}
        style={{ borderColor: theme.colors_ds.NEUTRAL_BORDER_WEAK }}
      >
        <ProfileDetail label="First name" value={selectedCustomer.firstName} />
        <ProfileDetail label="Last name" value={selectedCustomer.lastName} />
        <ProfileDetail label="Gender" value={selectedCustomer.gender} />
        <ProfileDetail
          label="Date of birth"
          value={selectedCustomer.birthday}
        />
        <ProfileDetail label="Email account" value={selectedCustomer.email} />
        <ProfileDetail label="Phone number" value={selectedCustomer.phone} />
        <ProfileDetail label="Country" value={selectedCustomer.country} />
        <ProfileDetail label="Address" value={selectedCustomer.address} />
        <ProfileDetail label="Zip code" value={selectedCustomer.zipcode} />
        <ProfileDetail label="Program options" value={programOptions} />
      </div>
    </div>
  );
};

export default ClientInfoScreen;
