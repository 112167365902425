import AgeClock from "./AgeClock";
import Factor from "../Factor";
import Memo from "../../../components/reusable/Memo";

import classes from "./BiologicalAge.module.css";

import arrowDown from "../../../images/arrowDown.png";
import arrowUp from "../../../images/arrowUp.png";

interface Props {
  data: {
    BIOLOGICAL_AGE: string;
    BIOLOGICAL_AGE_VALUE: number;
    CHRONOLOGICAL_AGE: string;
    CHRONOLOGICAL_AGE_VALUE: number;
    COMPARED_TO: string;
    DESCRIPTION: string;
    FAT_BURNING_EFFICIENCY_VALUE: number;
    FAT_BURN_EFFICIENCY: string;
    INCREASES_YOUR_AGE: string;
    INDEX: number;
    METABOLIC_RATE: string;
    METABOLIC_RATE_VALUE: number;
    REDUCES_YOUR_AGE: string;
    TITLE: string;
    VO2MAX: string;
    VO2MAX_VALUE: number;
  };
}

const BiologicalAge = ({ data }: Props): JSX.Element => {
  return (
    <div className={classes.container}>
      <AgeClock data={data} />

      <div className={classes.inner}>
        <div className={classes.title}>{data.TITLE}</div>
        <Factor factor={data.VO2MAX} valueArrow={data.VO2MAX_VALUE} />
        <Factor
          factor={data.FAT_BURN_EFFICIENCY}
          valueArrow={data.FAT_BURNING_EFFICIENCY_VALUE}
        />
        <Factor
          factor={data.METABOLIC_RATE}
          valueArrow={data.METABOLIC_RATE_VALUE}
        />
        <div className={classes.memo1}>
          <Memo text={data.REDUCES_YOUR_AGE} icon={arrowDown} />
          <Memo text={data.INCREASES_YOUR_AGE} icon={arrowUp} />
        </div>
        <div className={classes.memo2}>
          <Memo text={data.BIOLOGICAL_AGE} color="var(--secondary21)" />
          <Memo text={data.CHRONOLOGICAL_AGE} color="var(--secondary30)" />
        </div>
      </div>
    </div>
  );
};

export default BiologicalAge;
