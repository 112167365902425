type Props = { fill?: string };

const SearchIcon = ({ fill }: Props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M0 7C0 10.86 3.14 14 7 14C10.86 14 14 10.86 14 7C14 3.14 10.86 0 7 0C3.14 0 0 3.14 0 7ZM2 7C2 4.243 4.243 2 7 2C9.757 2 12 4.243 12 7C12 9.757 9.757 12 7 12C4.243 12 2 9.757 2 7ZM13.3139 11.9L15.7069 14.293C16.0979 14.684 16.0979 15.316 15.7069 15.707C15.5119 15.902 15.2559 16 14.9999 16C14.7439 16 14.4879 15.902 14.2929 15.707L11.8999 13.314C12.4289 12.903 12.9029 12.429 13.3139 11.9Z"
        fill={fill}
      />
    </svg>
  );
};

export default SearchIcon;