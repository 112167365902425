import React, { useState } from "react";
import classes from "./TabMenuPicker.module.css";
import useTheme from "../../../../common/hooks/useTheme"; // Make sure to create a corresponding CSS file

type Data = {
  label: string;
  key: number;
};

type Props = {
  data: Array<Data>;
  selectedItem: number;
  setSelectedItem: (key: number) => void;
};

type TabPickerProps = {
  item: Data;
  setSelectedItem: (key: number) => void;
  selectedItem: number;
};

const TabPicker = ({ item, setSelectedItem, selectedItem }: TabPickerProps) => {
  const [showHover, setShowHover] = useState(false);
  const theme = useTheme();

  const handleMouseEnter = () => {
    setShowHover(true);
  };
  const handleMouseLeave = () => {
    setShowHover(false);
  };
  return (
    <div
      onClick={() => setSelectedItem(item.key)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={classes.tab}
      style={{
        backgroundColor:
          selectedItem === item.key
            ? theme.colors_ds.NEUTRAL_BG_ELEVATION1
            : showHover
            ? theme.colorTokens.N[40]
            : "transparent",
        color:
          selectedItem === item.key
            ? theme.colors_ds.NEUTRAL_TEXT_STRONG
            : theme.colors_ds.NEUTRAL_TEXT_WEAK,
      }}
    >
      {item.label}
    </div>
  );
};

const TabMenuPicker = ({ data, selectedItem, setSelectedItem }: Props) => {
  const theme = useTheme();

  return (
    <div
      className={classes.container}
      style={{ borderColor: theme.colors_ds.NEUTRAL_BORDER_WEAK }}
    >
      {data.map((item: Data) => (
        <TabPicker
          key={item.key}
          item={item}
          setSelectedItem={setSelectedItem}
          selectedItem={selectedItem}
        />
      ))}
    </div>
  );
};

export default TabMenuPicker;
