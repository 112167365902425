import ChartBars from "../../components/reusable/ChartBars";

import classes from "./MetricsSection.module.css";

type label = { INDEX: number; NAME: string; VALUE: number };

type improveArea = { INDEX: number; TEXT: string; TITLE: string };

type Metric = {
  HOW_IT_IS_MEASURED: string;
  HOW_IT_IS_MEASURED_TEXT: string;
  INDEX: number;
  SUGGESTIONS: { IMPROVE_AREAS: improveArea[]; INDEX: number; TITLE: string }[];
  TITLE1: string;
  TITLE2: string;
  TOP: string;
  VALUE: number;
  WHAT_IT_IS: string;
  WHAT_IT_IS_TEXT: string;
  WHY_ITS_IMPORTANT: string;
  WHY_ITS_IMPORTANT_TEXT: string;
};

interface Props {
  data: Metric[];
  labels: label[];
  customGap?: string;
}

const MetricsSection = ({ data, customGap, labels }: Props): JSX.Element => {
  return (
    <div>
      <ul className={classes.main} style={{ gap: customGap }}>
        {data.map((item) => (
          <li key={item.INDEX}>
            <ChartBars
              title1={item.TITLE1}
              value={item.VALUE}
              title2={item.TITLE2}
              labelsIsVisible={true}
              useLargerFont={false}
              labels={labels}
              customGap={false}
              showIcon={false}
              iconLabel={""}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MetricsSection;
