import classes from "./Layout.module.css";

interface Props {
  children: any;
  customPadding?: number;
  customHeight?: string;
  isDarkTheme?: boolean;
}

const Layout = ({
  children,
  customPadding,
  customHeight,
  isDarkTheme,
}: Props): JSX.Element => {
  return (
    <div
      className={classes.container}
      style={
        isDarkTheme
          ? {
              padding: customPadding,
              backgroundColor: "var(--NEUTRAL_BG_MAIN)",
              borderBottom: "1px solid var(--NEUTRAL_BG_MAIN)",
            }
          : { padding: customPadding, height: customHeight }
      }
    >
      {children}
    </div>
  );
};

export default Layout;
