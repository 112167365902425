import Layout from "../../components/reusable/Layout";
import ChartBars from "../../components/reusable/ChartBars";
import CardDescription from "../../components/reusable/CardDescription";
import SuggestionCard from "../../components/SuggestionCard";
import Qrcode from "../../components/reusable/Qrcode";

import classes from "./MetricPage.module.css";

type Tab = { INDEX: number; NAME: string; VALUE: number };

type improveArea = { INDEX: number; TEXT: string; TITLE: string };

type Metric = {
  HOW_IT_IS_MEASURED: string;
  HOW_IT_IS_MEASURED_TEXT: string;
  INDEX: number;
  SUGGESTIONS: { IMPROVE_AREAS: improveArea[]; INDEX: number; TITLE: string }[];
  TITLE1: string;
  TITLE2: string;
  TOP: string;
  VALUE: number;
  WHAT_IT_IS: string;
  WHAT_IT_IS_TEXT: string;
  WHY_ITS_IMPORTANT: string;
  WHY_ITS_IMPORTANT_TEXT: string;
  RECCOMENDATIONS_TO_IMPROVE: string;
  SOURCES?: any;
};

interface Props {
  data: {
    DESCRIPTION: string;
    INDEX: number;
    METRICS: Metric[];
    NAVBAR: Tab[];
    SOURCES: any;
    RISK_METRICS: Metric[];
    PERFORMANCE_METRICS: Metric[];
    QRCODE: {
      FOOTER1: string;
      HEADER1: string;
      HEADER2: string;
      QRCODE: string;
    };
    TITLE: string;
    TITLE_PERFORMANCE_METRICS: string;
  };
}

const MetricPage = ({ data }: Props): JSX.Element => {
  const metricsArray = [...data.METRICS, ...data.PERFORMANCE_METRICS];

  return (
    <>
      {metricsArray.map((metric) => (
        <Layout key={`${metric.INDEX} ${metric.TITLE1}`}>
          <div className={classes.chart}>
            <ChartBars
              title1={metric.TITLE1}
              value={metric.VALUE}
              title2={metric.TITLE2}
              labelsIsVisible={true}
              useLargerFont={true}
              customGap={true}
              labels={data.NAVBAR.slice(0, -1)}
              showIcon={true}
              iconLabel={metric.TOP}
            />
          </div>

          <ul className={classes.inner}>
            <li className={classes.sub}>
              <CardDescription
                title={metric.WHAT_IT_IS}
                description={metric.WHAT_IT_IS_TEXT}
                useLargerFont={true}
                index={metric.INDEX}
              />
            </li>
            <li className={classes.sub}>
              <CardDescription
                title={metric.HOW_IT_IS_MEASURED}
                description={metric.HOW_IT_IS_MEASURED_TEXT}
                useLargerFont={true}
                index={metric.INDEX}
              />
            </li>
          </ul>
          <div className={classes.card_container}>
            <div className={classes.suggestion_tables_title}>
              {metric.RECCOMENDATIONS_TO_IMPROVE}
            </div>
            <ul className={classes.suggestion_tables}>
              {metric.SUGGESTIONS.map((item) => (
                <SuggestionCard key={item.INDEX} data={item} />
              ))}
            </ul>
          </div>
          <ul className={classes.bottom}>
            <li className={classes.sub}>
              <CardDescription
                title={metric.WHY_ITS_IMPORTANT}
                description={metric.WHY_ITS_IMPORTANT_TEXT}
                useLargerFont={true}
                index={metric.INDEX}
              />
            </li>
          </ul>

          <Qrcode data={data.QRCODE} sources={metric.SOURCES} />
        </Layout>
      ))}
    </>
  );
};

export default MetricPage;
