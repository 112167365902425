import Layout from "../../components/reusable/Layout";
import TableItem from "../../components/reusable/TableItem";
import Title from "../../components/reusable/Title";

import classes from "./TrainingProgramWorkoutPage.module.css";

type cell = { INDEX: number; VALUE: string };
type column = cell[];
type row = { ROW: number; VALUES: column };

type TableItemProps = {
  INDEX: number;
  TITLE1: string;
  TITLE2: string;
  TABLE: row[];
};

interface Props {
  data: {
    DESCRIPTION: null;
    INDEX: number;
    SECTIONS: TableItemProps[];
    TITLE: string;
  };
}

const TrainingProgramWorkoutPage = ({ data }: Props): JSX.Element => {
  return (
    <Layout>
      <Title title={data.TITLE} />
      <ul className={classes.inner}>
        {data.SECTIONS.map((item) => (
          <TableItem
            key={item.INDEX}
            data={item}
            type="customTitle"
            cardHeight={280}
            evenColumns={true}
            rowHeight="45px"
            minHeightTitle={""}
            minHeight={""}
            thresholdsTable={false}
          />
        ))}
      </ul>
    </Layout>
  );
};

export default TrainingProgramWorkoutPage;
