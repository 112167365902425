import classes from "./ChartBars.module.css";
import useTheme from "../../../../common/hooks/useTheme";
import ArrowDown from "../../svg/ArrowDown";
import { getCategoryColor, getCategoryLabel } from "../../store/helper";
import React, { useState } from "react";
import CustomLineChart from "./CustomLineChart";
import { Popover, Popper } from "@mui/material";

interface Props {
  data: any;
  categories: any;
}

const ChartBars = ({ data, categories }: Props): JSX.Element => {
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "metric-bar-popper" : undefined;

  return (
    <div>
      <div
        className={classes.box}
        style={{
          borderColor: open
            ? theme.colors_ds.PRIMARY_ICON_MAIN
            : isHovered
            ? theme.colors_ds.NEUTRAL_BORDER_DEFAULT
            : theme.colors_ds.NEUTRAL_BORDER_WEAK,
        }}
      >
        <div className={classes.barContainer}>
          <div className={classes.header}>
            <div
              className={classes.title}
              style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
            >
              {data.label}
            </div>
            <div
              className={classes.valueContainer}
              style={{ color: theme.colors_ds.NEUTRAL_TEXT_STRONG }}
            >
              <div className={classes.value}>
                {data.value}
                {data.unit}
              </div>
              <div
                className={classes.category}
                style={{ color: theme.colors_ds.NEUTRAL_TEXT_WEAK }}
              >
                {getCategoryLabel(data.unit, data.value, categories)}
              </div>
            </div>
          </div>

          <div
            className={classes.bar}
            style={{ backgroundColor: theme.colors_ds.NEUTRAL_BORDER_GRAPH }}
          >
            <div
              className={classes.barInner}
              style={{
                width: `${data.value}%`,
                backgroundColor: getCategoryColor(data.unit, data.value, theme),
              }}
            />
          </div>
        </div>
        <div
          onClick={handleClick}
          style={{
            transform: open ? "rotate(180deg)" : "",
            transition: "transform 0.5s ease-in-out",
            height: "24px",
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <ArrowDown width={24} height={24} />
        </div>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{
          top: anchorEl?.getBoundingClientRect().bottom || 0,
          left: 24,
        }} // Align to the left of the viewport
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPaper-root": {
            boxShadow: "none",
            backgroundColor: "transparent",
          },
        }}
      >
        <CustomLineChart />
      </Popover>
    </div>
  );
};

export default ChartBars;
