import classes from "./CustomLabelsTrianglePrediction.module.css";

type tag = { INDEX: number; NAME: string; VALUE: number };

interface Props {
  data: tag[];
  title: string;
  footer1: string;
  footer2: string;
}

const CustomLabelsTrianglePrediction = ({
  data,
  title,
  footer1,
  footer2,
}: Props): JSX.Element => {
  const tagTypeHandler = (index: number) => {
    let tagType: string;
    switch (index) {
      case 1:
        tagType = classes.cardioTag;
        break;
      case 2:
        tagType = classes.resistanceTag;
        break;
      case 3:
        tagType = classes.intervalTag;
        break;
      default:
        tagType = "";
    }
    return tagType;
  };

  return (
    <>
      <div className={classes.chartTitle}>{title}</div>
      {data.map((item) => (
        <div key={item.INDEX} className={tagTypeHandler(item.INDEX)}>
          <div className={classes.title}>{item.NAME}</div>
          <div>|</div>
          <div className={classes.value}>{item.VALUE}%</div>
        </div>
      ))}
      <div className={classes.legendTag1}>1</div>
      <div className={classes.legendTag2}>2</div>
      <div className={classes.legend}>
        <div className={classes.legend1}>{footer1}</div>
        <div className={classes.legend2}>{footer2}</div>
      </div>
    </>
  );
};

export default CustomLabelsTrianglePrediction;
