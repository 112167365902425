import classes from "./ProgrammingScreen.module.css";
import useTheme from "../../../common/hooks/useTheme";
import NutritionPlanTab from "../components/programming/NutritionPlanTab";
import TabMenuPicker from "../components/common/TabMenuPicker";
import { programmingTabs } from "../store/helper";
import { useState } from "react";
import WorkoutPlanTab from "../components/programming/WorkoutPlanTab";
import TreatmentPlanTab from "../components/programming/TreatmentPlanTab";
import { useSelector } from "react-redux";
import { RootStatePlatform } from "../store/platform-slice";

const ProgrammingScreen = () => {
  const [selectedItem, setSelectedItem] = useState<number>(0);

  return (
    <div className={classes.container}>
      <TabMenuPicker
        data={programmingTabs}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
      />
      {selectedItem === 0 && <NutritionPlanTab />}
      {selectedItem === 1 && <WorkoutPlanTab />}
      {selectedItem === 2 && <TreatmentPlanTab />}
    </div>
  );
};

export default ProgrammingScreen;
