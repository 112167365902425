export const COMPANY_MAIL_WITH_CUSTOM_REPORT = [
  "paul.gualano@equinox.com",
  "alanna.bradley@equinox.com",
  "matt.potter@equinox.com",
  "brandon.russell@equinox.com",
  "equinoxla1@equinox.com",
  "equinoxny1@equinox.com",
  "jess.bieda@equinox.com",
  "casey.sheppard@equinox.com",
  "evan.velez@equinox.com",
  "sean.coll@equinox.com",
  "pnoe.ehyny@gmail.com",
  "eqx137.pnoe@equinox.com",
  "ross.rubin@equinox.com",
];
