import classes from "./Points.module.css";

import axisPoint from "../../../images/pointBiologicalAge.png";

const Points = (): JSX.Element => {
  return (
    <div className={classes.pointsAge}>
      <img
        className={classes.pointAge0}
        src={axisPoint}
        alt="Point 0 of radial age chart"
      />
      <div className={classes.pointAge10}></div>
      <div className={classes.pointAge20}></div>
      <div className={classes.pointAge30}></div>
      <div className={classes.pointAge40}></div>
      <div className={classes.pointAge50}></div>
      <div className={classes.pointAge60}></div>
      <div className={classes.pointAge70}></div>
      <div className={classes.pointAge80}></div>
      <div className={classes.pointAge90}></div>

      <img
        className={classes.pointAge100}
        src={axisPoint}
        alt="Point 0 of radial age chart"
      />
    </div>
  );
};

export default Points;
