import classes from "./Table.module.css";

type cell = { INDEX: number; VALUE: string };
type column = cell[];
type row = { ROW: number; VALUES: column };

interface Props {
  data: row[];
  evenColumns?: boolean;
  minHeightTitle: string;
  minHeight: string;
  rowHeight: string;
  noTitle?: boolean;
  fontSize?: number;
  columnBorder?: boolean;
  customGap?: string;
  thresholdsTable?: boolean;
  footer?: boolean;
  customAlign?: string;
}

const Table = ({
  data,
  evenColumns,
  minHeightTitle,
  minHeight,
  rowHeight,
  noTitle,
  fontSize,
  columnBorder,
  customGap,
  thresholdsTable,
  footer,
  customAlign,
}: Props): JSX.Element => {
  const rowStyleHandler = (row: number): {} => {
    let rowStyle;
    if (noTitle) {
      return (rowStyle = {
        fontSize: `${fontSize}px`,
        border: "none",
      });
    }

    if (thresholdsTable) {
      switch (row) {
        case 1:
          rowStyle = {
            fontFamily: "AktivGroteskEx_A_Bd",
            minHeight: minHeightTitle,
            fontSize: "14px",
          };
          break;
        default:
          rowStyle = {
            fontFamily: "AktivGroteskEx_A_Rg",
            minHeight: minHeight,
            fontSize: "9.44px",
          };
      }
      return rowStyle;
    }

    if (footer) {
      switch (row) {
        case 1:
          rowStyle = {
            minHeight: minHeightTitle,
            fontSize: "10px",
          };
          break;
        default:
          rowStyle = {
            minHeight: minHeight,
            fontSize: "8px",
          };
      }
      return rowStyle;
    }

    switch (row) {
      case 1:
        rowStyle = {
          fontFamily: "AktivGroteskEx_A_Bd",
          minHeight: minHeightTitle,
          fontSize: "10px",
        };
        break;
      default:
        rowStyle = {
          fontFamily: "AktivGroteskEx_A_Rg",
          minHeight: minHeight,
          fontSize: "9.44px",
        };
    }
    return rowStyle;
  };

  const columnStyleHandler = (column: number) => {
    let columnStyle: {};
    if (evenColumns) {
      return;
    }

    if (footer) {
      return (columnStyle = {
        flex: 1,
        textAlign: "left",
      });
    }

    if (columnBorder) {
      switch (column) {
        case 1:
          columnStyle = {
            flex: 2,
            textAlign: "right",
            borderRight: "3px solid var(--primary100)",
            height: "50px",
            paddingTop: "18.5px",
            paddingRight: "15px",
          };
          break;
        default:
          columnStyle = { flex: 1.5 };
      }
      return columnStyle;
    }

    if (thresholdsTable) {
      switch (column) {
        case 1:
          columnStyle = {
            flex: 2,
            textAlign: "left",
            paddingLeft: "15px",
          };
          break;
        default:
          columnStyle = { flex: 2 };
      }
      return columnStyle;
    }

    switch (column) {
      case 1:
        columnStyle = { flex: 1 };
        break;
      case 2:
      case 3:
      case 4:
        columnStyle = { flex: 2 };
        break;
      default:
        columnStyle = { flex: 3, textAlign: "left" };
    }
    return columnStyle;
  };

  return (
    <ul className={classes.rowItems}>
      {data.map((row: row) => (
        <li
          className={classes.rowItem}
          key={row.ROW}
          style={rowStyleHandler(row.ROW)}
        >
          <ul
            className={classes.cellItem}
            style={{
              height: rowHeight,
              gap: customGap,
              alignItems: customAlign,
            }}
          >
            {row.VALUES.map((cell) => (
              <li
                className={classes.cell}
                key={cell.INDEX}
                style={columnStyleHandler(cell.INDEX)}
              >
                {cell.VALUE}
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  );
};

export default Table;
