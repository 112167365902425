import { useState } from "react";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import useTheme from "../../../../common/hooks/useTheme";
import { useAppStore } from "../../../../providers/AppStore";
import Logo from "../../../../common/svgs/logo";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { tabs } from "../../store/helper";
import { NavLink } from "react-router-dom";

export const pagesPlatform = [
  {
    id: tabs.CLIENT_INFO,
    role: 1,
    label: "Clients info",
    link: "/platform/client-info",
  },
  {
    id: tabs.DASHBOARD,
    role: 1,
    label: "Dashboard",
    link: "/platform/dashboard",
  },
  { id: tabs.SCORE, role: 1, label: "Score", link: "/platform/score" },
  {
    id: tabs.PROGRAMMING,
    role: 1,
    label: "Programming",
    link: "/platform/programming",
  },
  { id: tabs.CLIENTS, role: 2, label: "Clients", link: "/client/client-list" },
  {
    id: tabs.SCHEDULING,
    role: 2,
    label: "Scheduling",
    link: "/client/scheduling",
  },
];
const settings = ["Logout"];

type Props = {};

const PlatformAppBar = () => {
  const theme = useTheme();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const { ASUser, ASLogoutUser, ASIsUserLoaded } = useAppStore();
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: theme.colors_ds.PRIMARY_TEXT_MAIN,
        // height: "80px",
        boxShadow: "none",
      }}
    >
      <Container maxWidth={false} style={{ padding: "0 16px" }}>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { md: "flex", lg: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            {ASUser.current?.role && (
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { md: "block", lg: "none" },
                  "& .MuiMenu-paper": {
                    backgroundColor: theme.colors_ds.NEUTRAL_BG_MAIN,
                    boxShadow: "var(--box-shadow-general)",
                  },
                }}
              >
                {pagesPlatform
                  .slice(1)
                  .filter((item: any) =>
                    ASUser.current.role === 1
                      ? item.role === ASUser.current.role
                      : item.role !== 1
                  )
                  .map((page) => (
                    <MenuItem
                      key={page.id}
                      onClick={handleCloseNavMenu}
                      sx={{
                        borderRadius: "8px",
                        margin: "4px",
                        padding: 0,
                        overflow: "hidden",
                      }}
                    >
                      <NavLink
                        to={page.link}
                        style={({ isActive }) => {
                          return {
                            width: "100%",
                            height: "48px",
                            padding: "8px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: isActive
                              ? theme.colorTokens.P[90]
                              : "transparent",
                            color: isActive
                              ? theme.colors_ds.NEUTRAL_GENERAL_WHITE
                              : theme.colors_ds.NEUTRAL_TEXT_DEFAULT,
                          };
                        }}
                      >
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontFamily: "var(--font-Medium)",

                            fontSize: "14px",
                          }}
                        >
                          {page.label}
                        </Typography>
                      </NavLink>
                    </MenuItem>
                  ))}
              </Menu>
            )}
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "none", md: "none", lg: "flex" },
              gap: "8px",
              height: "80px",
            }}
          >
            {!ASUser.current?.role ? (
              <div style={{ flex: 1 }} />
            ) : (
              pagesPlatform
                .slice(1)
                .filter((item: any) =>
                  ASUser.current.role === 1
                    ? item.role === ASUser.current.role
                    : item.role !== 1
                )
                .map((page) => (
                  <NavLink
                    key={page.id}
                    style={({ isActive }) => {
                      return {
                        backgroundColor: isActive
                          ? theme.colorTokens.P[90]
                          : "transparent",
                        textDecoration: "none",
                        color: theme.colors_ds.NEUTRAL_GENERAL_WHITE,
                        fontFamily: "var(--font-Medium)",
                        fontSize: "16px",
                        height: "100%",
                        display: "flex",
                        padding: "0 12px",
                        borderRadius: "8px",
                        alignItems: "center",
                      };
                    }}
                    to={page.link}
                  >
                    <div>{page.label}</div>
                  </NavLink>
                ))
            )}
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Logo
              width={116}
              height={30}
              color={theme.colors_ds.NEUTRAL_GENERAL_WHITE}
            />
          </Box>

          <Box
            sx={{
              flexGrow: 0,
              backgroundColor: "transparent",
            }}
          >
            <Tooltip title="Open settings">
              <IconButton
                onClick={handleOpenUserMenu}
                sx={{ p: 0, "&:hover": { backgroundColor: "transparent" } }}
                disableRipple={true}
              >
                <Avatar
                  alt={ASUser.current?.firstname?.toUpperCase()}
                  src="/static/images/avatar/2.jpg"
                  sx={{
                    backgroundColor: "transparent",
                    border: `1px solid ${theme.colors_ds.NEUTRAL_BG_MAIN}`,
                    color: theme.colors_ds.NEUTRAL_GENERAL_WHITE,
                  }}
                />
                <Typography
                  sx={{
                    fontFamily: "var(--font-Medium)",
                    fontSize: "16px",
                    color: theme.colors_ds.NEUTRAL_GENERAL_WHITE,
                    padding: "0 8px",
                    display: { xs: "none", sm: "flex" },
                  }}
                >
                  {`${ASUser.current?.firstname || ""} ${
                    ASUser.current?.lastname || ""
                  }`}
                </Typography>
                <KeyboardArrowDownIcon
                  sx={{
                    marginLeft: { xs: "4px", sm: "0px" },
                    fontSize: "26px",
                    color: theme.colors_ds.NEUTRAL_GENERAL_WHITE,
                  }}
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{
                mt: "45px",
                "& .MuiMenu-paper": {
                  backgroundColor: theme.colors_ds.NEUTRAL_BG_MAIN,
                  boxShadow: "var(--box-shadow-general)",
                },
              }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting}
                  onClick={() => ASLogoutUser()}
                  sx={{
                    borderRadius: "8px",
                    margin: "4px",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontFamily: "var(--font-Medium)",
                      color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT,
                      fontSize: "14px",
                      width: "100px",
                    }}
                  >
                    {setting}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default PlatformAppBar;
