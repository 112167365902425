import classes from "./PieChartWithoutSectors.module.css";
import useTheme from "../../../../common/hooks/useTheme";
import { PieChart, Pie, Cell } from "recharts";
import { useSelector } from "react-redux";
import { RootStatePlatform } from "../../store/platform-slice";
import { getBiomarkerCategoryColor } from "../../store/helper";

type renderCustomizedLabelProps = {
  cx: number;
  cy: number;
  midAngle: number;
  outerRadius: number;
  label: string;
  percentage?: number;
  value?: number;
  valueKcal?: number;
};

type Props = {
  title: string;
  pieData: {
    label: string;
    value: number;
    categoryType: number;
    percentage?: number;
  }[];
  type: string;
};

const PieChartWithoutSectors = ({ title, pieData, type }: Props) => {
  const theme = useTheme();

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    outerRadius,
    label,
    percentage,
    value,
    valueKcal,
  }: renderCustomizedLabelProps) => {
    const radius = outerRadius + 20; // Adjust this value to position the labels further out
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    if (type === "nutrition") {
      return (
        <text
          x={x}
          y={y}
          fill={theme.colors_ds.NEUTRAL_TEXT_STRONG}
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
        >
          <tspan x={x} dy={"0em"} className={classes.pieChartLabelFirstLine}>
            {`${label} - ${percentage}%`}
          </tspan>
          <tspan x={x} dy={"1.2em"} className={classes.pieChartLabelSecondLine}>
            {`${valueKcal} kcal - ${value} gr`}
          </tspan>
        </text>
      );
    } else if (type === "trainingBreakdown") {
      return null;
    } else {
      return (
        <text
          x={x}
          y={y}
          fill={theme.colors_ds.NEUTRAL_TEXT_STRONG}
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
          className={classes.pieChartLabel}
        >
          {label}
        </text>
      );
    }
  };

  return (
    <div
      className={classes.container}
      style={type === "trainingBreakdown" ? { marginTop: 0 } : {}}
    >
      <div
        className={classes.title}
        style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
      >
        {title}
      </div>
      <div className={classes.inner}>
        <div
          className={classes.pieChartContainer}
          style={{ borderColor: theme.colors_ds.NEUTRAL_BORDER_WEAK }}
        >
          <PieChart
            width={
              type === "nutrition"
                ? 500
                : type === "trainingBreakdown"
                ? 110
                : 640
            }
            height={
              type === "nutrition"
                ? 350
                : type === "trainingBreakdown"
                ? 110
                : 360
            }
          >
            <Pie
              data={pieData}
              startAngle={90}
              endAngle={450}
              innerRadius={type === "trainingBreakdown" ? 43 : 110}
              outerRadius={type === "trainingBreakdown" ? 50 : 130}
              paddingAngle={2}
              dataKey="value"
              stroke="transparent"
              label={renderCustomizedLabel}
              labelLine={false}
            >
              {pieData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={getBiomarkerCategoryColor(
                    entry.categoryType,
                    theme,
                    type
                  )}
                />
              ))}
            </Pie>
          </PieChart>
          {type === "trainingBreakdown" && (
            <div className={classes.trainingBreakdownLegend}>
              {pieData.map((entry, index) => (
                <div
                  key={index}
                  className={classes.trainingBreakdownLegendItem}
                >
                  <div className={classes.trainingBreakdownLabel}>
                    <div
                      className={classes.trainingBreakdownLegendDataPoint}
                      style={{
                        backgroundColor: getBiomarkerCategoryColor(
                          entry.categoryType,
                          theme,
                          type
                        ),
                      }}
                    />
                    <div
                      className={classes.trainingBreakdownText}
                      style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
                    >
                      {entry.label}
                    </div>
                  </div>
                  <div
                    className={classes.trainingBreakdownLegendValue}
                    style={{ color: theme.colors_ds.NEUTRAL_TEXT_STRONG }}
                  >{`${entry.percentage}% - ${entry.value} kcal`}</div>
                </div>
              ))}
            </div>
          )}
        </div>
        {type === "biomarkers" && (
          <div
            className={classes.pieChartLegend}
            style={{ borderColor: theme.colors_ds.NEUTRAL_BORDER_WEAK }}
          >
            {pieData.map((entry, index) => (
              <div key={index} className={classes.pieChartLegendItem}>
                <div
                  className={classes.dataPoint}
                  style={{
                    backgroundColor: getBiomarkerCategoryColor(
                      entry.categoryType,
                      theme,
                      type
                    ),
                  }}
                />
                <div className={classes.text}>{entry.label}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default PieChartWithoutSectors;
