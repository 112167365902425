import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import classes from "../App.module.css";
import AuthPage from "../modules/authentication/screens/AuthPage";
import ResetPasswordPage from "../modules/authentication/screens/ResetPasswordPage";
import IntroPage from "../modules/questionnaire/components/pages/IntroPage";
import BiometricQuestionnaire from "../modules/questionnaire/components/pages/BiometricQuestionnaire";
import LifestyleQuestionnaire from "../modules/questionnaire/components/pages/LifestyleQuestionnaire";
import SuccessPageBiometric from "../modules/questionnaire/components/pages/SuccessPageBiometric";
import SuccessPageLifestyle from "../modules/questionnaire/components/pages/SuccessPageLifestyle";
import ErrorPage from "../modules/questionnaire/components/pages/ErrorPage";
import Report from "../modules/reports/reports/Report";
import React from "react";
// @ts-ignore
import { Helmet } from "react-helmet";
import PlatformScreenWrapper from "../modules/platform/ScreenWrappers/PlatformScreenWrapper";
import ClientsScreen from "../modules/platform/screens/ClientsScreen";
import ClientInfoScreen from "../modules/platform/screens/ClientInfoScreen";
import DashboardScreen from "../modules/platform/screens/DashboardScreen";
import ScoreScreen from "../modules/platform/screens/ScoreScreen";
import ProgrammingScreen from "../modules/platform/screens/ProgrammingScreen";
import SchedulingScreen from "../modules/platform/screens/SchedulingScreen";
import ErrorScreen from "../modules/platform/screens/ErrorScreen";

const router = createBrowserRouter([
  { path: "/auth", element: <AuthPage /> },
  { path: "/reset-password", element: <ResetPasswordPage /> },
  { path: "/intro", element: <IntroPage /> },
  { path: "/biometric-questionnaire", element: <BiometricQuestionnaire /> },
  {
    path: "/lifestyle-questionnaire",
    element: (
      <>
        <Helmet>
          <title>PNOĒ - Lifestyle Questionnaire</title>
        </Helmet>
        <LifestyleQuestionnaire />
      </>
    ),
  },
  { path: "/success-biometric", element: <SuccessPageBiometric /> },
  { path: "/success-lifestyle", element: <SuccessPageLifestyle /> },
  { path: "/error", element: <ErrorPage /> },
  {
    path: "/reports",
    element: (
      <>
        <Helmet>
          <title>PNOĒ - Report</title>
        </Helmet>
        <Report />
      </>
    ),
  },
  {
    path: "/platform",
    element: <PlatformScreenWrapper />,
    // loader: async () => {
    //   const data = await getPlatformData(
    //     "7d62884c-7043-4966-88f6-ae47013d26b7"
    //   );
    //   console.log(data);
    //   return data;
    // },
    children: [
      { path: "/platform/client-info", element: <ClientInfoScreen /> },
      { path: "/platform/dashboard", element: <DashboardScreen /> },
      { path: "/platform/score", element: <ScoreScreen /> },
      { path: "/platform/programming", element: <ProgrammingScreen /> },
      { path: "/platform", element: <Navigate to="/platform/dashboard" /> },
    ],
  },
  {
    path: "/client",
    element: <PlatformScreenWrapper />,
    children: [
      { path: "/client/client-list", element: <ClientsScreen /> },
      { path: "/client/scheduling", element: <SchedulingScreen /> },
      { path: "/client", element: <ClientsScreen /> },
    ],
  },
  {
    path: "*",
    element: <AuthPage />,
  },
]);

const RootNavigation = () => {
  return (
    <div className={classes.app}>
      <RouterProvider router={router} />
    </div>
  );
};

export default RootNavigation;
