import classes from "./CustomLabelsTriangleFollicular02.module.css";

type LabelData = {
  INDEX: number;
  TITLE?: string;
  PERCENT?: string;
  G?: string;
  KCAL?: string;
};

interface Props {
  data: LabelData[];
}

const CustomLabelsTriangleFollicular02 = ({ data }: Props): JSX.Element => {
  const tagTypeHandler = (index: number) => {
    let tagType: string;
    switch (index) {
      case 1:
        tagType = `${classes.labels} ${classes.proteinTag}`;
        break;
      case 2:
        tagType = `${classes.labels} ${classes.carbsTag}`;
        break;
      case 3:
        tagType = `${classes.labels} ${classes.fatsTag}`;
        break;
      default:
        tagType = "";
    }
    return tagType;
  };

  return (
    <>
      {data.map((item) => (
        <div key={item.INDEX} className={tagTypeHandler(item.INDEX)}>
          <div className={classes.top}>
            <div className={classes.tag} style={{ width: "50px" }}>
              {item.KCAL}
            </div>
          </div>
          <div className={classes.bottom}>
            <div className={classes.tag}>{item.PERCENT}</div>
            <div className={classes.title}>{item.TITLE}</div>
            <div className={classes.tag}>{item.G}</div>
          </div>
        </div>
      ))}
    </>
  );
};

export default CustomLabelsTriangleFollicular02;
