import Layout from "../../components/reusable/Layout";
import Title from "../../components/reusable/Title";
import CustomCard from "../../components/reusable/CustomCard";

import classes from "./WellnessHealthHistoryPage.module.css";

type Section = { INDEX: number; TITLE: string; TEXT: string };

interface Props {
  data: {
    DESCRIPTION: string;
    INDEX: number;
    SECTIONS: Section[];
    TITLE: string;
  };
}

const WellnessHealthHistoryPage = ({ data }: Props): JSX.Element => {
  const iconColorHandler = (item: number): string => {
    let iconColor;
    switch (item) {
      case 1:
        iconColor = "var(--secondary20)";
        break;
      case 2:
        iconColor = "var(--secondary40)";
        break;
      default:
        iconColor = "var(--secondary10)";
    }
    return iconColor;
  };

  return (
    <Layout>
      <Title title={data.TITLE} />
      <ul className={classes.inner}>
        {data.SECTIONS.map((item) => (
          <li key={item.INDEX} className={classes.sub}>
            <CustomCard>
              <div className={classes.header}>
                <div
                  className={classes.icon}
                  style={{ backgroundColor: iconColorHandler(item.INDEX) }}
                ></div>
                <div className={classes.subtitle}>{item.TITLE}</div>
              </div>
              <div className={classes.description}>{item.TEXT}</div>
            </CustomCard>
          </li>
        ))}
      </ul>
    </Layout>
  );
};

export default WellnessHealthHistoryPage;
