import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import useTheme from "../../../common/hooks/useTheme";
import { useAppStore } from "../../../providers/AppStore";
import PlatformAppBar from "../components/common/PlatformAppBar";
import { RootStatePlatform } from "../store/platform-slice";
import { useSelector } from "react-redux";
import { UserRole } from "../../../common/entities/profile";
import PageLoader from "../../reports/components/UI/PageLoader";
import SecondaryClientMenu from "../components/common/SecondaryClientMenu";
import { Box } from "@mui/material";

const PlatformScreenWrapper = () => {
  const theme = useTheme();

  const {
    ASUser,
    ASisLoading,
    ASGetDataFromPlatform,
    ASIsUserLoaded,
    ASGetIntegrationData,
  } = useAppStore();
  const reportData = useSelector(
    (state: RootStatePlatform) => state.platform.dataPlatformServer
  );
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!ASIsUserLoaded()) {
      navigate("/auth");
    }
  }, []);

  useEffect(() => {
    if (ASUser.current?.role === UserRole.Customer) {
      ASGetDataFromPlatform(ASUser.current?.pnoe_user_id);
      ASGetIntegrationData();
    }
  }, [ASUser, ASIsUserLoaded()]);

  return (
    <div
      style={{
        width: "100%",
        overflowX: "auto",
        height: "100%",
        backgroundColor: theme.colors_ds.NEUTRAL_GENERAL_WHITE,
      }}
    >
      <PlatformAppBar />
      <Box
        sx={{
          height: "100%",
          paddingTop: {
            xs: "50px",
            sm: "60px",
            md: "60px",
            lg: "80px",
          },
        }}
      >
        {location.pathname.includes("/platform/") &&
          ASUser.current?.role !== UserRole.Customer &&
          Object.keys(reportData).length > 0 && <SecondaryClientMenu />}

        {ASisLoading && Object.keys(reportData).length === 0 && <PageLoader />}
        {!ASisLoading &&
          (ASUser.current?.role !== UserRole.Customer ||
            Object.keys(reportData).length !== 0) && <Outlet />}
      </Box>
    </div>
  );
};

export default PlatformScreenWrapper;
