import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import PageLoader from "../components/UI/PageLoader";
import GeneratePDF from "../components/UI/GeneratePDF.js";
import ReportNotFound from "./ReportNotFound";

import ReportAMR from "./ReportAMR";
import ReportRMR from "./ReportRMR";

import classes from "./Report.module.css";
import StressReport from "./StressReport";
import CustomReportRMR from "./CustomReportRMR";
import CustomReportAMR from "./CustomReportAMR";
import html2canvas from "html2canvas";
import {
  getReportDataPlatform,
  getReportDataUser,
} from "../../../common/network/Api";

const Report = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [allPagesToVertical, setAllPagesToVertical] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let id = searchParams.get("id");
  let type = searchParams.get("type");
  let lang = searchParams.get("lang");
  let isNativeApp = searchParams.get("isNativeApp");
  let isPlatformApp = searchParams.get("P");

  const getReportDataForPlatformUser = async (
    id: string,
    type: string,
    lang: string
  ) => {
    try {
      let response = await getReportDataPlatform(id, type, lang);
      setData(response);
      setIsLoading(false);
    } catch (e: any) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const getReportDataForUser = async (
    id: string,
    type: string,
    lang: string
  ) => {
    try {
      let response = await getReportDataUser(id, type, lang);
      setData(response);
      setIsLoading(false);
    } catch (e: any) {
      console.log(e);
      setIsLoading(false);
      const paramReport = JSON.stringify({
        idReport: id,
        type: type,
        lang: lang,
      });
      localStorage.setItem("paramReport", paramReport);
      window.location.href = "/";
    }
  };

  // useEffect(() => {
  //   // getReportData(
  //   //   id,
  //   //   type,
  //   //   lang,
  //   //   setData,
  //   //   setIsLoading,
  //   //   setError,
  //   //   navigate,
  //   //   isPlatformApp
  //   // );
  //   getReportDataPlatform(id, type, lang);
  //   // if (isPlatformApp === "1") {
  //   //   console.log("is platform");
  //   //   getReportDataPlatform(id, type, lang);
  //   // } else {
  //   //   console.log("is client");
  //   //   getReportDataPlatform(id, type, lang);
  //   // }
  // }, []);

  useEffect(() => {
    if (id && type && lang) {
      if (isPlatformApp === "1") {
        getReportDataForPlatformUser(id, type, lang);
      } else {
        getReportDataForUser(id, type, lang);
      }
    }
  }, [id, type, lang, isPlatformApp]);

  const svgConverter = useCallback(
    async (content: string, result: string, imgWidth: number) => {
      const element = document.getElementById(content),
        canvas = await html2canvas(element, {
          useCORS: true,
          backgroundColor: "transparent",
          allowTaint: true,
          scale: 1.5,
        });
      const img = new Image();
      img.src = canvas.toDataURL("image/jpg");
      img.width = imgWidth;
      // @ts-ignore
      document.getElementById(result).appendChild(img);
    },
    []
  );

  return (
    <React.Fragment>
      {isLoading && <PageLoader />}
      {data && isNativeApp !== "1" && (
        <GeneratePDF
          userName={data.PAGES.find((page) => page.INDEX === 1).SUBJECT_NAME}
          type={type}
          setAllPagesToVertical={setAllPagesToVertical}
        />
      )}
      {error && <ReportNotFound error={error} />}
      <div id="content" className={classes.content}>
        {data &&
          (type === "10" || type === "12" || type === "14") &&
          data.TRAINER_MAIL === "rick@millerandeverton.co.uk" && (
            <CustomReportAMR
              data={data}
              svgConverter={svgConverter}
              reportType={type}
            />
          )}
        {data &&
          (type === "10" || type === "12" || type === "14") &&
          data.TRAINER_MAIL !== "rick@millerandeverton.co.uk" && (
            <ReportAMR
              data={data}
              svgConverter={svgConverter}
              reportType={type}
              allPagesToVertical={allPagesToVertical}
              customerMail={data.TRAINER_MAIL}
            />
          )}
        {data &&
          (type === "11" || type === "13" || type === "15") &&
          data.TRAINER_MAIL === "rick@millerandeverton.co.uk" && (
            <CustomReportRMR
              data={data}
              svgConverter={svgConverter}
              reportType={type}
            />
          )}
        {data &&
          (type === "11" || type === "13" || type === "15") &&
          data.TRAINER_MAIL !== "rick@millerandeverton.co.uk" && (
            <ReportRMR
              data={data}
              svgConverter={svgConverter}
              reportType={type}
              allPagesToVertical={allPagesToVertical}
            />
          )}
        {/*{data && type === "16" && (*/}
        {/*  <StressReport*/}
        {/*    data={data}*/}
        {/*    svgConverter={svgConverter}*/}
        {/*    reportType={type}*/}
        {/*  />*/}
        {/*)}*/}
      </div>
    </React.Fragment>
  );
};

export default Report;
