export type UserType = {
  access_token: string;
  has_active_plan?: boolean;
  email: string;
  firstName?: string;
  firstname?: string;
  id: string;
  token: string;
  lastName?: string;
  lastname?: string;
  pnoe_auth_token: string;
  pnoe_user_id: string;
  refresh_token: string;
  trainer_id: string;
  feedback_status?: string;
  welcome_screen_displayed?: boolean;
  biometrics_questionnaire_completed?: boolean;
  lifestyle_questionnaire_completed?: boolean;
  nutrition_questionnaire_completed?: boolean;
  has_lifestyle_questionnaire_access?: boolean;
  has_advanced_app_access?: boolean;
  role: UserRole;
};

export enum UserRole {
  None,
  Customer,
  Company,
  Store,
  Employee,
  CompanyAdmin,
  Admin = 10,
}

export type ClientType = {
  id: string;
  firstName: string;
  lastName: string;
  gender: string;
  birthday: string;
  email: string;
  phone: string;
  country: string;
  address: string;
  zipcode: string;
  nutrition: boolean;
  workout: boolean;
  report: boolean;
  dateCreated: string;
};
