import Layout from "../../components/reusable/Layout";
import TableItem from "../../components/reusable/TableItem";
import Title from "../../components/reusable/Title";

type cell = { INDEX: number; VALUE: string };
type column = cell[];
type row = { ROW: number; VALUES: column };

interface Props {
  data: {
    DESCRIPTION: null;
    INDEX: number;
    SUBTITLE: string;
    TABLE: row[];
    TITLE: string;
  };
}

const TrainingProgramPage = ({ data }: Props): JSX.Element => {
  return (
    <Layout>
      <Title title={data.TITLE} />
      <TableItem
        data={data}
        type="simpleTitle"
        evenColumns={false}
        minHeightTitle="60px"
        minHeight="110px"
        cardHeight={0}
        rowHeight={""}
        thresholdsTable={false}
      />
    </Layout>
  );
};

export default TrainingProgramPage;
