import classes from "./TabChartBar.module.css";

interface Props {
    label: string;
    value: number;
}

const ChartBars = ({
                       label, value
                   }: Props): JSX.Element => {
    let fillColor: string;
    if (value <= 22) {
        fillColor = `${classes.fill} ${classes.severeLimitation}`;

    } else if (value <= 40) {
        fillColor = `${classes.fill} ${classes.limitations}`;

    } else if (value <= 60) {
        fillColor = `${classes.fill} ${classes.neutral}`;

    } else if (value <= 80) {
        fillColor = `${classes.fill} ${classes.good}`;

    } else {
        fillColor = `${classes.fill} ${classes.excellent}`;

    }

    return (
        <div className={classes.container}>
            <div className={classes.label}>{label} </div>

            <div className={classes.inner}>
                <div className={classes.bar}>
                    <div className={fillColor} style={{width: `${value}%`}}></div>
                </div>
                <div>
                </div>
            </div>


        </div>
    );
};

export default ChartBars;
