import classes from "./DescriptionPanel.module.css";
import { bool } from "yup";
import useTheme from "../hooks/useTheme";
import InfoIcon from "../../modules/platform/svg/InfoIcon";
import React from "react";

type Props = {
  data: any;
  showText: boolean;
  handleMouseEnter: any;
  handleMouseLeave: any;
};

const DescriptionPanel = ({
  data,
  showText,
  handleMouseEnter,
  handleMouseLeave,
}: Props) => {
  const theme = useTheme();
  return (
    <div
      className={classes.descriptionPanelContainer}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <InfoIcon />
      {showText && (
        <div className={classes.descriptionPanel}>
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={classes.pointer}
          >
            <rect
              x="8.48535"
              width="12"
              height="12"
              transform="rotate(45 8.48535 0)"
              fill={theme.colorTokens.P[10]}
            />
          </svg>
        </div>
      )}
    </div>
  );
};

export default DescriptionPanel;
