import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";

import TextInput from "../reusable/TextInput";
import Button from "../../../common/ui/Button";
import OutlineButton from "../../../common/ui/OutlineButton";

import classes from "./SignUpForm.module.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { requestPasswordReset } from "../../../common/network/Api";

type Props = {
  enteredEmail: string;
  setEnteredEmail: Function;
  setIsLoading: Function;
  setErrorMessage: Function;
  setShowError: Function;
};

const ForgotPasswordForm = ({
  enteredEmail,
  setEnteredEmail,
  setIsLoading,
  setErrorMessage,
  setShowError,
}: Props) => {
  const [isLinkRequested, setIsLinkRequested] = useState(false);

  const navigate = useNavigate();

  const handleSendPasswordReset = async (userData: { email: string }) => {
    try {
      await requestPasswordReset(userData);

      setErrorMessage("");
      setIsLinkRequested(true);
      navigate(`/auth?mode=login`);
    } catch (e: any) {
      setErrorMessage(e.message);
      setShowError(true);
    }
  };

  const handleSendRequest = async (
    values: { email: string },
    { setSubmitting }: FormikHelpers<{ email: string }>
  ) => {
    setIsLoading(true);
    setEnteredEmail(values.email);
    let emailData: string = values.email;

    // @ts-ignore
    await handleSendPasswordReset(emailData);
    setSubmitting(false);
    setIsLoading(false);
  };
  return (
    <Formik
      initialValues={{
        email: enteredEmail,
      }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email("Invalid email address.")
          .required("This is a required field."),
      })}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleSendRequest}
    >
      <Form placeholder={""}>
        <div className={classes.inner}>
          {!isLinkRequested && (
            <TextInput label="Email" name="email" type="email" />
          )}
        </div>

        <div className={classes.bottom}>
          {!isLinkRequested && (
            <Button
              type="submit"
              text="Request Reset Password Link"
              customWidth="100%"
              customMargin={"80px auto 0"}
              customHeight="48px"
            />
          )}

          {isLinkRequested && (
            <OutlineButton
              type="submit"
              text="Resend Verification Link"
              customWidth="100%"
              customMargin={"50px auto 0"}
              customHeight="48px"
            />
          )}

          <OutlineButton
            type="Button"
            text="Cancel"
            customWidth="100%"
            customMargin={"16px auto 0"}
            customHeight="48px"
            onClick={() => {
              setIsLinkRequested(false);
              setEnteredEmail("");
              setErrorMessage("");
              setShowError(false);
              navigate(`/auth?mode=login`);
            }}
          />
        </div>
      </Form>
    </Formik>
  );
};

export default ForgotPasswordForm;
