import classes from "./LifestyleProgressBar.module.css";
import { useState } from "react";
import useTheme from "../../../../common/hooks/useTheme";

type Props = {
  value: number;
};

const LifestyleProgressBar = ({ value }: Props) => {
  const [showText, setShowText] = useState(false);
  const theme = useTheme();
  const handleMouseEnter = () => {
    setShowText(true);
  };
  const handleMouseLeave = () => {
    setShowText(false);
  };
  // @ts-ignore
  return (
    <div
      className={classes.container}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ borderColor: theme.colors_ds.NEUTRAL_BORDER_WEAK }}
    >
      <div
        className={classes.title}
        style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
      >
        Lifestyle
      </div>
      <div
        className={classes.bar}
        style={{ backgroundColor: theme.colorTokens.P[10] }}
      >
        <div className={classes.inner}>
          <div
            className={classes.fill}
            style={{
              width: `${value}%`,
              backgroundColor: theme.colors_ds.PRIMARY_ICON_MAIN,
            }}
          ></div>
        </div>
        <div className={classes.pointers}>
          {Array.from({ length: 33 }, (_, i) => (
            <div
              key={i}
              className={`${classes.pointer} ${classes.point__q}`}
              style={{ "--i": `${i + 1}` } as React.CSSProperties}
            ></div>
          ))}
        </div>
        {showText && (
          <div className={classes.descriptionPanelContainer}>
            <div className={classes.descriptionPanel}>
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="8.48535"
                  width="12"
                  height="12"
                  transform="rotate(45 8.48535 0)"
                  fill={theme.colorTokens.P[10]}
                />
              </svg>
            </div>
            <div
              className={classes.descriptionPanelText}
              style={{
                color: theme.colors_ds.NEUTRAL_TEXT_WEAK,
                backgroundColor: theme.colorTokens.P[10],
              }}
            >
              <span className={classes.text1}>{`${Math.floor(value)}%`}</span>{" "}
              <span className={classes.text2}>
                complete of the lifestyle questionnaire! Keep it up!
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LifestyleProgressBar;
