import React, { useEffect, useRef, useState } from "react";
import classes from "./ClientsScreen.module.css";
import ClientsSearchBar from "../components/clients/ClientsSearchBar";
import ClientsList from "../components/clients/ClientsList";
import ClientsListLetterNavigation from "../components/clients/ClientsListLetterNavigation";
import PlusIcon from "../svg/PlusIcon";
import { useNavigate } from "react-router-dom";

import { useAppStore } from "../../../providers/AppStore";
import { useDispatch, useSelector } from "react-redux";
import { platformActions, RootStatePlatform } from "../store/platform-slice";

type Props = {};

const ClientsScreen = () => {
  const [clientFilter, setClientFilter] = useState("");
  const clientRowGroupRefs = useRef({});
  const { ASGetDataFromPlatform, ASGetIntegrationData } = useAppStore();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  // Handle client list row click
  const onClientSelected = (client: any) => {
    dispatch(platformActions.updateSelectedCustomer(client));
    ASGetDataFromPlatform(client.id);
    ASGetIntegrationData();

    navigate("/platform/client-info");
  };

  useEffect(() => {
    dispatch(platformActions.updateDataPlatformServer({}));
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.headerSection}>
        <ClientsSearchBar setClientFilter={setClientFilter} />
        {/*
        <div className={classes.plusButton}>
          <PlusIcon
            fill={theme.colors_ds.NEUTRAL_GENERAL_WHITE}
          />
        </div>
        */}
      </div>
      <div className={classes.mainContent}>
        <ClientsList
          clientFilter={clientFilter}
          clientRowGroupRefs={clientRowGroupRefs}
          onClientSelected={onClientSelected}
        />
        <ClientsListLetterNavigation clientRowGroupRefs={clientRowGroupRefs} />
      </div>
    </div>
  );
};

export default ClientsScreen;
