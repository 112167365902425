import { createSlice } from "@reduxjs/toolkit";

// @ts-ignore
import data from "./dataJSON.json";

export interface RootStateStepper {
  stepper: RootStateStepperObj;
}

export interface RootStateStepperObj {
  step: number;
  formData: any;
  lifeStyleFormData: any;
  totalSteps: number;
  lifeStyleTotalSteps: number;
  convertMeasurementValues: boolean;
  isCompleted: boolean;
  isLifestyleCompleted: boolean;
  hasLifestyleQuestionnaireAccess: boolean;
  responseStatus: string;
  personalData: any;
}

const initialStepperState: RootStateStepperObj = {
  step: 1,
  formData: data.allData.Biometrics,
  lifeStyleFormData: data.allData.Lifestyle,
  totalSteps: data.allData.Biometrics.length,
  lifeStyleTotalSteps: data.allData.Lifestyle.length,
  convertMeasurementValues: true,
  hasLifestyleQuestionnaireAccess: false,
  isCompleted: false,
  isLifestyleCompleted: false,
  responseStatus: "",
  personalData: {},
};

const stepperSlice = createSlice({
  name: "stepper",
  initialState: initialStepperState,
  reducers: {
    next(state) {
      state.step++;
    },
    prev(state) {
      state.step--;
    },
    nextSkip(state) {
      state.step = state.step + 2;
    },
    goToStep(state, action) {
      state.step = action.payload.step;
    },
    prevSkip(state) {
      state.step = state.step - 2;
    },
    handleConvert(state) {
      state.convertMeasurementValues = !state.convertMeasurementValues;
    },
    handleQuestionnaireCompleted(state, action) {
      state.isCompleted = action.payload;
    },
    handleLifestyleQuestionnaireCompleted(state, action) {
      state.isLifestyleCompleted = action.payload;
    },
    handleHasLifestyleQuestionnaireAccess(state, action) {
      state.hasLifestyleQuestionnaireAccess = action.payload;
    },
    handleResponseStatus(state, action) {
      state.responseStatus = action.payload;
    },
    updateAnswers(state, action) {
      state.personalData = { ...state.personalData, ...action.payload };
    },
  },
});

export const stepperActions = stepperSlice.actions;

export default stepperSlice;
