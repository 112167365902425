export type ColorTokens = {
  10: string;
  20: string;
  30: string;
  40: string;
  50: string;
  60: string;
  70: string;
  80: string;
  90: string;
  100: string;
};

export const lightTheme_Neutral: ColorTokens = {
  10: '#FCFEFF',
  20: '#F9FBFC',
  30: '#F6F8FA',
  40: '#EDEFF2',
  50: '#E1E3E5',
  60: '#C5C8CC',
  70: '#9A9EAE',
  80: '#6D7075',
  90: '#3B3F42',
  100: '#1F2224',
};

export const lightTheme_Primary: ColorTokens = {
  10: '#E6F0FF',
  20: '#C6DEFE',
  30: '#A7CBFE',
  40: '#8BBBFD',
  50: '#6FAAFC',
  60: '#438EF8',
  70: '#2479F0',
  80: '#1064DB',
  90: '#04439C',
  100: '#002457',
};

export const lightTheme_Warning: ColorTokens = {
  10: '#FFF9E6',
  20: '#FEF0C6',
  30: '#FEE8A7',
  40: '#FDE08B',
  50: '#FCD96F',
  60: '#F8CB43',
  70: '#F0BD24',
  80: '#DBA810',
  90: '#9C7604',
  100: '#574100',
};

export const lightTheme_Danger: ColorTokens = {
  10: '#FFF0F1',
  20: '#FFC7CD',
  30: '#FF9EA8',
  40: '#FF7583',
  50: '#FF4D5F',
  60: '#F5223E',
  70: '#CF1332',
  80: '#A80722',
  90: '#820016',
  100: '#5C000F',
};

export const lightTheme_Success: ColorTokens = {
  10: '#DCF9ED',
  20: '#B5F2D9',
  30: '#8EECC5',
  40: '#62E4AE',
  50: '#2EDC93',
  60: '#20BF7D',
  70: '#1A9964',
  80: '#126E48',
  90: '#0D4D32',
  100: '#083020',
};
