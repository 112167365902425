import React from "react";

const CheckWithCircle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M10 0.5C4.50867 0.5 0 4.95087 0 10.5C0 15.9913 4.45087 20.5 10 20.5C15.4913 20.5 20 16.0491 20 10.5C19.9422 5.00867 15.4913 0.5 10 0.5ZM10 18.9393C5.37572 18.9393 1.6185 15.1821 1.6185 10.5578C1.6185 5.93353 5.37572 2.1763 10 2.1763C14.6243 2.1763 18.3815 5.93353 18.3815 10.5578C18.3815 15.1243 14.6243 18.9393 10 18.9393Z"
        fill="#10DB86"
      />
      <path
        d="M14.6823 6.51179L8.38173 12.8124L5.26034 9.69098C4.97132 9.40196 4.45109 9.40196 4.16208 9.69098C3.87306 9.98 3.87306 10.5002 4.16208 10.7892L7.8615 14.4887C8.03491 14.6621 8.20832 14.7199 8.43953 14.7199C8.61294 14.7199 8.84416 14.6621 9.01757 14.4887L15.7806 7.66786C16.0696 7.37884 16.0696 6.85861 15.7806 6.56959C15.4916 6.22277 14.9713 6.22277 14.6823 6.51179Z"
        fill="#10DB86"
      />
    </svg>
  );
};

export default CheckWithCircle;
