import CustomRadarChart from "../../components/reusable/CustomRadarChart";

import classes from "./ChartSection.module.css";

const ChartSection = ({ data }) => {
  const chartWidth = 600;
  const chartHeight = 450;

  const chartDataArray = [];
  data.GRAPH.forEach((element) =>
    chartDataArray.push({
      INDEX: element.INDEX,
      TITLE: element.TITLE,
      VALUE: +element.PERCENT.slice(0, -1),
    })
  );

  return (
    <div className={classes.section}>
      <div className={classes.sectionTitle}>{data.TITLE}</div>
      <div
        className={classes.chartContainer}
        style={{ width: chartWidth, height: chartHeight }}
      >
        <CustomRadarChart
          data={chartDataArray}
          dataLabels={data.GRAPH}
          height={400}
          width={440}
          typeOfLabelCustom="macronutrient"
          isAxisPredictionCustom={false}
          radius="86%"
          gridStroke="black"
          showTicks={false}
          startAngle={90}
          endAngle={-270}
          maxDomain={65}
          tickCount={0}
        />
      </div>
    </div>
  );
};

export default ChartSection;
