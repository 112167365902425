import CustomCard from "./CustomCard";
import Table from "./Table";

import classes from "./TableItem.module.css";

type cell = { INDEX: number; VALUE: string };
type column = cell[];
type row = { ROW: number; VALUES: column };

type TableItemProps = {
  INDEX?: number;
  TITLE1?: string;
  TITLE2?: string;
  SUBTITLE?: string;
  TITLE?: string;
  TABLE: row[];
};

interface Props {
  data: TableItemProps;
  type: string;
  cardHeight: number;
  evenColumns: boolean;
  minHeightTitle: string;
  minHeight: string;
  rowHeight: string;
  thresholdsTable: boolean;
  key?: number;
}

const TableItem = ({
  data,
  type,
  cardHeight,
  evenColumns,
  minHeightTitle,
  minHeight,
  rowHeight,
  thresholdsTable,
}: Props): JSX.Element => {
  return (
    <div className={classes.container}>
      {type === "customTitle" && (
        <div className={classes.tableTitles}>
          <div className={classes.tableTitle1}>{data.TITLE1}</div>
          <span>-</span>
          <div className={classes.tableTitle2}>{data.TITLE2}</div>
        </div>
      )}
      {type === "simpleTitle" && (
        <div className={classes.tableTitles} style={{ paddingTop: 0 }}>
          <div className={classes.tableTitle1}>{data.SUBTITLE}</div>
        </div>
      )}
      {type === "sectionTitle" && (
        <div className={classes.tableTitles} style={{ paddingTop: 0 }}>
          <div className={classes.tableTitle1}>{data.TITLE}</div>
        </div>
      )}

      <CustomCard cardHeight={cardHeight} cardPadding="0px">
        {
          <Table
            data={data.TABLE}
            evenColumns={evenColumns}
            rowHeight={rowHeight}
            minHeightTitle={minHeightTitle}
            minHeight={minHeight}
            thresholdsTable={thresholdsTable}
          />
        }
      </CustomCard>
    </div>
  );
};

export default TableItem;
