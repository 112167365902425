import classes from "./CustomLineChart.module.css";
import useTheme from "../../../../common/hooks/useTheme";
import { useSelector } from "react-redux";
import { RootStatePlatform } from "../../store/platform-slice";
import React, { PureComponent } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { format } from "date-fns";

const DUMMY_DATA = [
  {
    name: "2023-09-10",
    pv: 55,
  },
  {
    name: "2023-10-10",
    pv: 80,
  },
  {
    name: "2023-11-10",
    pv: 90,
  },
  {
    name: "2024-01-10",
    pv: 10,
  },
  {
    name: "2024-03-10",
    pv: 30,
  },
  {
    name: "2024-03-20",
    pv: 50,
  },
  {
    name: "2024-06-10",
    pv: 52,
  },
];

type TooltipProps = {
  active: boolean;
  payload: any;
  label: string;
};

const CustomLineChart = () => {
  const theme = useTheme();
  const data = useSelector(
    (state: RootStatePlatform) => state.platform.data.score
  );
  const selectedMetric = useSelector(
    (state: RootStatePlatform) => state.platform.selectedMetric
  );

  const selectedMetricData = data.sectionTwo.find(
    (el: any) => el.type === selectedMetric
  );

  const CustomTooltip = ({ active, payload, label }: TooltipProps) => {
    if (active && payload && payload.length) {
      return (
        <div className={classes.legendItemContainer}>
          <p
            className={classes.legendItemText}
            style={{ color: theme.colors_ds.NEUTRAL_TEXT_WEAK }}
          >
            {format(label, "EEE dd/MM")}
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <div
      className={classes.container}
      style={{
        borderColor: theme.colors_ds.PRIMARY_ICON_MAIN,
        backgroundColor: theme.colors_ds.NEUTRAL_BG_MAIN,
      }}
    >
      <div
        className={classes.date}
        style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
      >
        Mon 20/10
      </div>
      <div className={classes.legendCardsContainer}>
        <div className={classes.legendCard}>
          <div
            className={classes.value}
            style={{ color: theme.colors_ds.NEUTRAL_ICON_DEFAULT }}
          >
            15%
          </div>
          <div className={classes.detailsContainer}>
            <div
              className={classes.dataPoint}
              style={{ backgroundColor: theme.colors_ds.PRIMARY_ICON_MAIN }}
            />
            <div
              className={classes.text}
              style={{ color: theme.colors_ds.NEUTRAL_ICON_WEAK }}
            >
              Achieved
            </div>
          </div>
        </div>
        {/*<div className={classes.legendCard}>*/}
        {/*  <div*/}
        {/*    className={classes.value}*/}
        {/*    style={{ color: theme.colors_ds.NEUTRAL_ICON_DEFAULT }}*/}
        {/*  >*/}
        {/*    {data.TestIncludedTitle}*/}
        {/*  </div>*/}
        {/*  <div className={classes.detailsContainer} style={{ gap: "16px" }}>*/}
        {/*    {data.TestIncludedTable.map((el: any) => (*/}
        {/*      <div*/}
        {/*        className={classes.text}*/}
        {/*        style={{ color: theme.colors_ds.NEUTRAL_ICON_WEAK }}*/}
        {/*      >*/}
        {/*        {el}*/}
        {/*      </div>*/}
        {/*    ))}*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
      <div>
        <div
          className={classes.yAxisTitle}
          style={{ color: theme.colors_ds.NEUTRAL_TEXT_WEAK }}
        >
          %/Day
        </div>
        <ResponsiveContainer width="100%" height={270}>
          <LineChart
            width={500}
            height={300}
            data={DUMMY_DATA}
            margin={{
              top: 8,
              right: 20,
              left: -20,
              bottom: 5,
            }}
          >
            <XAxis
              dataKey="name"
              domain={[0, 100]}
              tickFormatter={(value) => format(value, "dd/MM")}
              tick={{
                fontSize: "12px",
                fontFamily: "var(--font-Regular)",
              }}
              padding={{ left: 50, right: 50 }}
              stroke={theme.colors_ds.NEUTRAL_TEXT_WEAK}
            />
            <YAxis
              tick={{
                fontSize: "12px",
                fontFamily: "var(--font-Regular)",
              }}
              tickFormatter={(value) => (value === 0 ? "" : value)}
              stroke={theme.colors_ds.NEUTRAL_TEXT_WEAK}
            />
            <Tooltip
              content={<CustomTooltip />}
              defaultIndex={DUMMY_DATA.length - 1}
              active={true}
            />
            <Line
              type="monotone"
              dataKey="pv"
              stroke={theme.colors_ds.PRIMARY_ICON_MAIN}
              dot={{
                stroke: theme.colors_ds.PRIMARY_ICON_MAIN,
                fill: theme.colors_ds.PRIMARY_ICON_MAIN,
                strokeWidth: 0,
              }}
              activeDot={{
                stroke: theme.colors_ds.PRIMARY_ICON_MAIN,
                fill: theme.colors_ds.PRIMARY_ICON_MAIN,
                strokeWidth: 1,
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default CustomLineChart;
