import Layout from "../../components/reusable/Layout";
import Title from "../../components/reusable/Title";

import classes from "./WorkoutDescriptionPage.module.css";

import SectionCard from "./SectionCard";
const WorkoutDescriptionPage = ({ data }) => {
  return (
    <Layout>
      <Title title={data.TITLE} />
      <div className={classes.section}>
        {data.SECTIONS.map((section) => (
          <SectionCard data={section} key={section.INDEX} />
        ))}
      </div>
    </Layout>
  );
};
export default WorkoutDescriptionPage;
