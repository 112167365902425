import classes from "./TreatmentPlanCard.module.css";
import useTheme from "../../../../common/hooks/useTheme";

type Props = {
  title: string;
  details: { label: string; value: string }[];
};

const TreatmentPlanCard = ({ title, details }: Props) => {
  const theme = useTheme();
  return (
    <div
      className={classes.treatmentCardContainer}
      style={{ borderColor: theme.colors_ds.NEUTRAL_BORDER_WEAK }}
    >
      <img
        src={
          "https://mypnoe.blob.core.windows.net/images-diet/2fe5f34e-1f40-433d-b9c6-7b3fba51acac.jpg"
        }
        alt="IV Therapy"
        className={classes.treatmentCardImage}
      />
      <div
        className={classes.treatmentCardDetails}
        style={{ backgroundColor: theme.colors_ds.NEUTRAL_BG_MAIN }}
      >
        <div
          className={classes.treatmentCardDetailsTitle}
          style={{
            color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT,
            borderBottomColor: theme.colors_ds.NEUTRAL_BORDER_WEAK,
          }}
        >
          {title}
        </div>
        <div className={classes.treatmentCardDetailsContainer}>
          {details.map(
            (item: { label: string; value: string }, index: number) => (
              <div className={classes.treatmentCardDetailsRow} key={index}>
                <div
                  className={classes.treatmentCardDetailsRowLabel}
                  style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
                >
                  {item.label}
                </div>
                <div
                  className={classes.treatmentCardDetailsRowValue}
                  style={{ color: theme.colors_ds.NEUTRAL_TEXT_STRONG }}
                >
                  {item.value}
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default TreatmentPlanCard;
