import classes from "./PieChartWithSectors.module.css";
import useTheme from "../../../../common/hooks/useTheme";
import { PieChart, Pie, Cell } from "recharts";
import { useSelector } from "react-redux";
import { RootStatePlatform } from "../../store/platform-slice";
import {
  getBiomarkerCategoryColor,
  getNutritionProgrammingCategoryColor,
} from "../../store/helper";
import InfoIcon from "../../svg/InfoIcon";
import React from "react";
import { Tooltip } from "@mui/material";
import styled from "@emotion/styled";
import CustomTooltip from "./CustomTooltip";

type renderCustomizedLabelProps = {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius?: number;
  outerRadius?: number;
  label: string;
};

type Props = {
  chartData: {
    title: string;
    sectorNames: { type: number; label: string }[];
    pieData: { label: string; value: number; categoryType: number }[];
  };
};

const PieChartWithSectors = ({ chartData }: Props) => {
  const theme = useTheme();

  const sortedData = chartData.pieData.sort(
    (a, b) => a.categoryType - b.categoryType
  );

  const sectorNormalizedData = [
    {
      label: chartData.sectorNames.find(
        (el: { type: number; label: string }) => el.type === 1
      )?.label,
      value: sortedData
        .filter((item) => item.categoryType === 1)
        .reduce((acc, curr) => acc + curr.value, 0),
      categoryType: 1,
    },
    {
      label: chartData.sectorNames.find(
        (el: { type: number; label: string }) => el.type === 2
      )?.label,
      value: sortedData
        .filter((item) => item.categoryType === 2)
        .reduce((acc, curr) => acc + curr.value, 0),
      categoryType: 2,
    },
  ];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    outerRadius = 0,
    label,
  }: renderCustomizedLabelProps) => {
    const radius = outerRadius + 18; // Adjust this value to position the labels further out
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text
        x={x}
        y={y}
        fill={theme.colors_ds.NEUTRAL_TEXT_STRONG}
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        className={classes.pieChartLabel}
      >
        {label}
      </text>
    );
  };

  const renderCustomizedSectorLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius = 0,
    outerRadius,
    label,
  }: renderCustomizedLabelProps) => {
    const radius = innerRadius - 25; // Adjust this value to position the labels further in

    // Split the label into words
    const words = label.split(" ");

    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text
        x={x}
        y={y}
        fill={theme.colors_ds.NEUTRAL_TEXT_STRONG}
        textAnchor={x < cx ? "start" : "end"}
        dominantBaseline="central"
        className={classes.pieChartSectorLabel}
      >
        {words.map((lineText, i) => (
          <tspan key={i} x={x} dy={i === 0 ? "0em" : "1.2em"}>
            {lineText}
          </tspan>
        ))}
      </text>
    );
  };

  return (
    <div className={classes.container}>
      <div
        className={classes.title}
        style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
      >
        {chartData.title}
      </div>
      <div className={classes.inner}>
        <div
          className={classes.pieChartContainer}
          style={{ borderColor: theme.colors_ds.NEUTRAL_BORDER_WEAK }}
        >
          <PieChart width={500} height={349}>
            <Pie
              data={sortedData}
              startAngle={90}
              endAngle={450}
              innerRadius={110}
              outerRadius={130}
              paddingAngle={1.3}
              dataKey="value"
              stroke="transparent"
              label={renderCustomizedLabel}
              labelLine={false}
            >
              {sortedData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={getNutritionProgrammingCategoryColor(
                    entry.categoryType,
                    theme
                  )}
                />
              ))}
            </Pie>
            <Pie
              data={sectorNormalizedData}
              startAngle={90}
              endAngle={450}
              innerRadius={98}
              outerRadius={105}
              paddingAngle={2}
              dataKey="value"
              stroke="transparent"
              label={renderCustomizedSectorLabel}
              labelLine={false}
            >
              {sectorNormalizedData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={getNutritionProgrammingCategoryColor(
                    entry.categoryType,
                    theme
                  )}
                />
              ))}
            </Pie>
          </PieChart>
        </div>

        <div
          className={classes.pieChartLegendContainer}
          style={{ borderColor: theme.colors_ds.NEUTRAL_BORDER_WEAK }}
        >
          <div className={classes.pieChartLegendSectorTitleContainer}>
            <div
              className={classes.pieChartLegendSectorTitle}
              style={{ color: theme.colors_ds.NEUTRAL_TEXT_STRONG }}
            >
              {
                chartData.sectorNames.find(
                  (el: { type: number; label: string }) => el.type === 1
                )?.label
              }
            </div>
            <CustomTooltip message="Pending text message">
              <InfoIcon />
            </CustomTooltip>
          </div>

          {sortedData
            .filter((el: any) => el.categoryType === 1)
            .map((entry, index) => (
              <div key={index} className={classes.pieChartLegendItem}>
                <div
                  className={classes.dataPoint}
                  style={{
                    backgroundColor: getNutritionProgrammingCategoryColor(
                      entry.categoryType,
                      theme
                    ),
                  }}
                />
                <div className={classes.text}>{entry.label}</div>
              </div>
            ))}
        </div>
        <div
          className={classes.pieChartLegendContainer}
          style={{ borderColor: theme.colors_ds.NEUTRAL_BORDER_WEAK }}
        >
          <div className={classes.pieChartLegendSectorTitleContainer}>
            <div
              className={classes.pieChartLegendSectorTitle}
              style={{ color: theme.colors_ds.NEUTRAL_TEXT_STRONG }}
            >
              {
                chartData.sectorNames.find(
                  (el: { type: number; label: string }) => el.type === 2
                )?.label
              }
            </div>
            <CustomTooltip message="Pending text message">
              <InfoIcon />
            </CustomTooltip>
          </div>
          {sortedData
            .filter((el: any) => el.categoryType === 2)
            .map((entry, index) => (
              <div key={index} className={classes.pieChartLegendItem}>
                <div
                  className={classes.dataPoint}
                  style={{
                    backgroundColor: getNutritionProgrammingCategoryColor(
                      entry.categoryType,
                      theme
                    ),
                  }}
                ></div>
                <div className={classes.text}>{entry.label}</div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default PieChartWithSectors;
