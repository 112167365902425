import React from "react";

const XWithCircle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M10 0.5C4.50867 0.5 0 4.95087 0 10.5C0 15.9913 4.45086 20.5 10 20.5C15.4913 20.5 20 16.0491 20 10.5C20 5.00867 15.5491 0.5 10 0.5ZM10 18.9393C5.37572 18.9393 1.6185 15.1821 1.6185 10.5578C1.6185 5.93353 5.37572 2.1763 10 2.1763C14.6243 2.1763 18.3815 5.93353 18.3815 10.5578C18.4393 15.1243 14.6243 18.9393 10 18.9393Z"
        fill="#F5223E"
      />
      <path
        d="M13.9913 6.51168C13.7023 6.22267 13.1821 6.22267 12.893 6.51168L10.0029 9.40186L7.1705 6.51168C6.88148 6.22267 6.36125 6.22267 6.07223 6.51168C5.78321 6.8007 5.78321 7.32093 6.07223 7.60995L8.9046 10.4423L6.07223 13.2747C5.78321 13.5637 5.78321 14.0839 6.07223 14.373C6.24564 14.5464 6.41905 14.6042 6.65027 14.6042C6.88148 14.6042 7.05489 14.5464 7.2283 14.373L10.0607 11.5406L12.893 14.373C13.0664 14.5464 13.2399 14.6042 13.4711 14.6042C13.6445 14.6042 13.8757 14.5464 14.0491 14.373C14.3381 14.0839 14.3381 13.5637 14.0491 13.2747L11.2167 10.4423L14.0491 7.60995C14.2803 7.32093 14.2803 6.85851 13.9913 6.51168Z"
        fill="#F5223E"
      />
    </svg>
  );
};

export default XWithCircle;
