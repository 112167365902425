import { useDispatch, useSelector } from "react-redux";
import { answersActions, RootStateAnswers } from "../../store/answers-slice";

import Scale from "../reusable/Scale";

import { MAX_HEIGHT_CM, MAX_WEIGHT_KG, MAX_WEIGHT_LB } from "../../constant";

import classes from "./ScaleForm.module.css";
import { RootStateStepper } from "../../store/stepper-slice";

type Props = {
  scaleType: string | undefined;
};

const ScaleForm = ({ scaleType }: Props) => {
  const dispatch = useDispatch();
  const stepper = useSelector((state: RootStateStepper) => state.stepper.step);
  const formData = useSelector(
    (state: RootStateStepper) => state.stepper.formData
  );
  const answers = useSelector(
    (state: RootStateAnswers) => state.answers.biometricQuest
  );

  const curAnswer = answers.myAnswers[formData[stepper - 1].category];

  const dispatchAnswer = (scaleData: string) => {
    dispatch(
      answersActions.updateBiometricAnswers({
        [formData[stepper - 1].category]: +scaleData,
      })
    );
  };

  return (
    <div className={classes.container} style={{ width: "25%" }}>
      <Scale
        id={1}
        dispatchAnswer={dispatchAnswer}
        label={
          scaleType === "massUnit"
            ? answers.myAnswers.mass_measurement_unit === 1
              ? "kg"
              : "lb"
            : "cm"
        }
        answer={curAnswer}
        max={
          scaleType === "massUnit"
            ? answers.myAnswers.mass_measurement_unit === 1
              ? MAX_WEIGHT_KG
              : MAX_WEIGHT_LB
            : MAX_HEIGHT_CM
        }
        min={scaleType === "massUnit" ? 0 : 0}
      />
    </div>
  );
};

export default ScaleForm;
