import { useState } from "react";

import jsPDF from "jspdf";

import LoadingSpinner from "./LoadingSpinner";

import classes from "./GeneratePDF.module.css";

// type Props = {
//   userName: string;
//   type: string;
//   setAllPagesToVertical: Function;
// };

const GeneratePDF = ({ userName, type, setAllPagesToVertical }) => {
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const name = userName.replace(/ /g, "_");

  let typeName;
  if (type === "10") {
    typeName = "Longevity_AMR";
  } else if (type === "11") {
    typeName = "Longevity_RMR";
  } else if (type === "12") {
    typeName = "Weight_Loss_AMR";
  } else if (type === "13") {
    typeName = "Weight_Loss_RMR";
  } else if (type === "14") {
    typeName = "Performance_AMR";
  } else if (type === "15") {
    typeName = "Performance_RMR";
  }

  //Function to generate a PDF either on Click or on load
  const generatePDF = () => {
    let doc = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: "a4",
      compress: true,
    });

    doc.html(document.querySelector("#content"), {
      callback: function (pdf) {
        let pageCount = doc.internal.getNumberOfPages();
        pdf.deletePage(pageCount);
        pdf.setFont("AktivGroteskEx_A_Bd", "normal");
        pdf.setFont("AktivGroteskEx_A_Lt", "normal");
        pdf.setFont("AktivGroteskEx_A_Md", "normal");
        pdf.setFont("AktivGroteskEx_A_Rg", "normal");
        pdf.setFont("AktivGroteskEx_A_XBd", "normal");
        pdf.setFont("Poppins-Light", "normal");
        pdf.save(`${typeName}_Report_${name.replace(/\./g, "")}`);
        setIsGeneratingPDF(false);
      },

      html2canvas: { scale: 0.75 },
    });
  };

  const generatePDFHandler = () => {
    setIsGeneratingPDF(true);
    setAllPagesToVertical(true);
    setTimeout(() => {
      generatePDF();
      setAllPagesToVertical(false);
    }, 3000);
  };

  const pdfGeneratorIcon = (
    <svg width="20" height="20" viewBox="0 0 20 20">
      {" "}
      <path d="M7.126,14.692h5.748c0.39,0,0.706-0.316,0.706-0.706c0-0.39-0.315-0.706-0.706-0.706H7.126c-0.39,0-0.706,0.316-0.706,0.706C6.42,14.376,6.736,14.692,7.126,14.692z"></path>{" "}
      <path d="M7.126,16.899h4.641c0.39,0,0.706-0.315,0.706-0.706c0-0.389-0.316-0.706-0.706-0.706H7.126c-0.39,0-0.706,0.317-0.706,0.706C6.42,16.584,6.736,16.899,7.126,16.899z"></path>{" "}
      <path d="M18.933,3.163h-3.309V1.686c0-0.392-0.316-0.706-0.706-0.706H5.061c-0.39,0-0.706,0.314-0.706,0.706v1.477H1.067c-0.39,0-0.706,0.317-0.706,0.706v7.997c0,0.391,0.316,0.706,0.706,0.706h3.301v5.743c0,0.39,0.316,0.706,0.706,0.706h7.801c0.192,0,0.373-0.077,0.507-0.215l2.054-2.121c0.127-0.131,0.198-0.306,0.198-0.491v-3.622h3.3c0.39,0,0.706-0.315,0.706-0.706V3.869C19.638,3.481,19.323,3.163,18.933,3.163z M14.222,15.908l-1.647,1.701H5.779v-5.037h8.443V15.908z M18.227,11.16h-3.3H5.074H1.773V4.575h3.288c0.39,0,0.706-0.314,0.706-0.706V2.392h8.446v1.477c0,0.392,0.316,0.706,0.706,0.706h3.309V11.16z"></path>{" "}
    </svg>
  );

  const pdfGeneratorLoader = <LoadingSpinner size="small" />;

  return (
    <div className={classes.btnContainer} onClick={generatePDFHandler}>
      <div className={classes.inner}>
        <div className={classes.text}>Generate PDF</div>
        {isGeneratingPDF ? pdfGeneratorLoader : pdfGeneratorIcon}
      </div>
    </div>
  );
};

export default GeneratePDF;
