import CustomCard from "../../components/reusable/CustomCard";
import Table from "../../components/reusable/Table";

import classes from "./SectionDescription.module.css";

type ChartData = { INDEX: number; NAME: string; VALUE: number };

type cell = { INDEX: number; VALUE: string };
type column = cell[];
type row = { ROW: number; VALUES: column };

interface Props {
  data: {
    DESCRIPTION: string;
    GRAPH_FOOTER1: string;
    GRAPH_FOOTER2: string;
    GRAPH_TITLE: string;
    GRAPH_VALUES: ChartData[];
    INDEX: number;
    TABLE: row[];
    TABLE_FOOTER: string;
    TABLE_HEADER: string;
    TITLE: string;
  };
  rowHeight: string;
}

const SectionDescription = ({ data, rowHeight }: Props): JSX.Element => {
  return (
    <div className={classes.container}>
      <div className={classes.description}>{data.DESCRIPTION}</div>
      {/*<div className={classes.tableHeader}>{data.TABLE_HEADER}</div>*/}
      {/*<CustomCard cardWidth="246px" cardPadding="0px" cardMargin="0px">*/}
      {/*  <Table*/}
      {/*    data={data.TABLE}*/}
      {/*    rowHeight={rowHeight}*/}
      {/*    noTitle={true}*/}
      {/*    fontSize={14}*/}
      {/*    columnBorder={true}*/}
      {/*    customGap="0px"*/}
      {/*    minHeightTitle={""}*/}
      {/*    minHeight={""}*/}
      {/*  />*/}
      {/*</CustomCard>*/}
      {/*<div className={classes.tableFooter}>{data.TABLE_FOOTER}</div>*/}
    </div>
  );
};

export default SectionDescription;
