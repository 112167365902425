import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

import SignUpForm from "../components/SignUpForm";
import SignInForm from "../components/SignInForm";
import ForgotPasswordForm from "../components/ForgotPasswordForm";
import PageLoader from "../../../common/ui/PageLoader";

import classes from "./AuthPage.module.css";

import sideAuthImg from "../../../common/images/backgroundImgMobile.png";
import { getCustomerEmail } from "../../../common/network/Api";
import useTheme from "../../../common/hooks/useTheme";
import LogoSmall from "../../../common/svgs/logo-small";
// @ts-ignore
import useLongPress from "../../../common/hooks/useLongPress";
import {
  LocalStorageVariables,
  useAppStore,
} from "../../../providers/AppStore";
import { Modal } from "@mui/material";

const AuthPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [idEmail, setIdEmail] = useState("");
  const [enteredEmail, setEnteredEmail] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const { ASConfiguration, ASSetEnv } = useAppStore();

  let mode = searchParams.get("mode");
  let id = searchParams.get("id");

  if (mode === null || id === null) {
    mode = "login";
  }

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onLongPress = () => {
    handleOpen();
  };

  const onClick = () => {
    console.log("click is triggered");
  };

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 3000,
  };
  const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);

  const ModalItem = ({ label, id }: { label: string; id: string }) => {
    return (
      <div
        className={classes.modalText}
        style={{
          backgroundColor:
            ASConfiguration === id ? theme.colors_ds.NEUTRAL_BORDER_WEAK : "",
          color:
            ASConfiguration === id
              ? theme.colors_ds.PRIMARY_ICON_MAIN
              : theme.colors_ds.NEUTRAL_TEXT_DEFAULT,
        }}
        onClick={() => {
          localStorage.setItem(LocalStorageVariables.ENVIRONMENT, id);
          ASSetEnv();
          handleClose();
        }}
      >
        {label}
      </div>
    );
  };

  const handleGetCustomerEmail = async () => {
    try {
      setIsLoading(true);
      let curID = id !== null ? id : "";
      let response = await getCustomerEmail(curID);
      let curEmail = response.email;
      setIdEmail(curEmail);
      setIsLoading(false);
    } catch (e: any) {
      console.log(e);

      if (mode === "signup") {
        setShowError(true);
        setErrorMessage(e.message);
      }

      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (mode === "signup") {
      handleGetCustomerEmail();
    }
  }, [mode]);

  const getMode = (mode: string) => {
    switch (mode) {
      case "signup":
        return {
          sideText: "Your health experience starts here",
          title: "Sign Up",
          subTitle: "Already have an account?",
          link: "login",
          linkText: "Log in",
        };
      case "forgot-password":
        return {
          sideText: "Welcome back!",
          title: "Forgot Password",
          subTitle:
            "You will receive an email with a provided link to reset your password. Please check your inbox.",
          link: "",
          linkText: "",
        };

      default:
        return {
          sideText: "Welcome back!",
          title: "Sign in",
          subTitle: `${id !== null ? "Don’t have an account?" : ""}`,
          link: `${id !== null ? "signup" : ""}`,
          linkText: `${id !== null ? "Sign up here" : ""}`,
        };
    }
  };

  return (
    <div className={classes.container}>
      <Modal open={open} onClose={handleClose}>
        <div
          className={classes.modalContainer}
          style={{
            backgroundColor: theme.colors_ds.NEUTRAL_BG_MAIN,
            borderColor: theme.colors_ds.NEUTRAL_BORDER_WEAK,
          }}
        >
          <div className={classes.modalTitle}>Select configuration</div>
          <ModalItem label="Production" id="prod" />
          <ModalItem label="Development" id="dev" />
          <ModalItem label="Local" id="local" />
        </div>
      </Modal>
      <div
        className={classes.side}
        style={{
          backgroundImage: `url('${sideAuthImg}')`,
          backgroundPosition: "top center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "100vh",
        }}
      />
      <div
        className={classes.main}
        style={{
          color: theme.colors_ds.NEUTRAL_TEXT_STRONG,
          backgroundColor: theme.colors_ds.NEUTRAL_BG_MAIN,
        }}
      >
        {isLoading && <PageLoader />}
        {!isLoading && (
          <div className={classes.inner}>
            <div className={classes.innerScroll}>
              <div className={classes.logoContainer}>
                <button
                  {...longPressEvent}
                  style={{ border: "none", backgroundColor: "transparent" }}
                >
                  <LogoSmall />
                </button>
              </div>
              <div className={classes.title}>{getMode(mode).title}</div>
              <div className={classes.titleMobile}>{getMode(mode).title}</div>

              <div className={classes.subTitle}>
                <div>{getMode(mode).subTitle} </div>
                <Link
                  to={`?mode=${getMode(mode).link}&id=${id}`}
                  className={classes.subTitleLink}
                  style={{ color: theme.colors_ds.PRIMARY_TEXT_MAIN }}
                  onClick={() => {
                    setErrorMessage("");
                    setShowError(false);
                  }}
                >
                  {getMode(mode).linkText}
                </Link>
              </div>
              <div className={classes.box}>
                {showError && (
                  <div
                    className={classes.error}
                    style={{ color: theme.colors_ds.DANGER_GRAPH_DEFICIT }}
                  >{`*${errorMessage}`}</div>
                )}
              </div>

              {mode === "login" && (
                <SignInForm
                  id={id}
                  setIsLoading={setIsLoading}
                  setShowError={setShowError}
                  setErrorMessage={setErrorMessage}
                />
              )}

              {mode === "signup" && (
                <SignUpForm
                  id={id}
                  enteredEmail={idEmail}
                  setIsLoading={setIsLoading}
                  setShowError={setShowError}
                  setErrorMessage={setErrorMessage}
                  errorMessage={errorMessage}
                />
              )}
              {mode === "forgot-password" && (
                <ForgotPasswordForm
                  enteredEmail={enteredEmail}
                  setEnteredEmail={setEnteredEmail}
                  setIsLoading={setIsLoading}
                  setShowError={setShowError}
                  setErrorMessage={setErrorMessage}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AuthPage;
