import useTheme from "../hooks/useTheme";

const X_icon = () => {
  const theme = useTheme();
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="1" width="15" height="15" rx="7.5" stroke="#CF1332" />
      <path
        d="M11.7456 12.2453C11.665 12.326 11.5693 12.3901 11.4639 12.4338C11.3586 12.4775 11.2456 12.5 11.1315 12.5C11.0174 12.5 10.9045 12.4775 10.7991 12.4338C10.6937 12.3901 10.598 12.326 10.5174 12.2453L8 9.72798L5.48259 12.2453C5.31972 12.4081 5.09882 12.4996 4.86848 12.4996C4.63815 12.4996 4.41724 12.4081 4.25437 12.2453C4.0915 12.0824 4 11.8615 4 11.6312C4 11.5171 4.02246 11.4042 4.06611 11.2989C4.10975 11.1935 4.17373 11.0978 4.25437 11.0171L6.77178 8.49982L4.25437 5.98252C4.0915 5.81966 4 5.59877 4 5.36844C4 5.13812 4.0915 4.91722 4.25437 4.75436C4.41724 4.5915 4.63815 4.5 4.86848 4.5C5.09882 4.5 5.31972 4.5915 5.48259 4.75436L8 7.27166L10.5174 4.75436C10.5981 4.67372 10.6938 4.60975 10.7992 4.56611C10.9045 4.52246 11.0175 4.5 11.1315 4.5C11.2456 4.5 11.3585 4.52246 11.4639 4.56611C11.5692 4.60975 11.665 4.67372 11.7456 4.75436C11.8263 4.835 11.8902 4.93074 11.9339 5.0361C11.9775 5.14147 12 5.2544 12 5.36844C12 5.48249 11.9775 5.59541 11.9339 5.70078C11.8902 5.80614 11.8263 5.90188 11.7456 5.98252L9.22822 8.49982L11.7456 11.0171C12.0766 11.3481 12.0766 11.9056 11.7456 12.2453Z"
        fill={theme.colors_ds.DANGER_GRAPH_DEFICIT}
      />
    </svg>
  );
};

export default X_icon;
