import LoadingSpinner from "./LoadingSpinner";

import classes from "./PageLoader.module.css";
import useTheme from "../hooks/useTheme";

const PageLoader = () => {
  const theme = useTheme();
  return (
    <div className={classes.loaderContainer}>
      <LoadingSpinner
        size="massive"
        color={theme.colors_ds.PRIMARY_ICON_MAIN}
      />
      <div
        className={classes.loaderTitle}
        style={{ color: theme.colors_ds.NEUTRAL_TEXT_WEAK }}
      >
        Loading
      </div>
    </div>
  );
};

export default PageLoader;
