import { createSlice } from "@reduxjs/toolkit";

// @ts-ignore
import { formAnswers, lifeStyleFormAnswers } from "./data";

export interface RootStateAnswers {
  answers: RootStateAnswersObj;
}
export interface RootStateAnswersObj {
  biometricQuest: any;
  lifestyleQuest: any;
}

const initialAnswersState: RootStateAnswersObj = {
  biometricQuest: formAnswers,
  lifestyleQuest: lifeStyleFormAnswers,
};

const answersSlice = createSlice({
  name: "answers",
  initialState: initialAnswersState,
  reducers: {
    updateBiometricAnswers(state, action) {
      state.biometricQuest.myAnswers = {
        ...state.biometricQuest.myAnswers,
        ...action.payload,
      };
    },
    updateLifestyleAnswers(state, action) {
      state.lifestyleQuest.myAnswers = {
        ...state.lifestyleQuest.myAnswers,
        ...action.payload,
      };
    },
  },
});

export const answersActions = answersSlice.actions;

export default answersSlice;
