import NavItem from "./NavItem";

import classes from "./Navbar.module.css";

type Tab = { INDEX: number; NAME: string; VALUE: number };

interface Props {
  data: Tab[];
}

const Navbar = ({ data }: Props): JSX.Element => {
  const navItemFlex = (item: number) => {
    let itemFlex: number;
    switch (item) {
      case 1:
      case 6:
        itemFlex = 4;
        break;
      case 2:
        itemFlex = 3;
        break;
      default:
        itemFlex = 2.2;
    }
    return itemFlex;
  };

  return (
    <nav>
      <ul className={classes.navBar}>
        {data.map((item) => (
          <li
            className={classes.navItem}
            key={item.INDEX}
            style={{ flex: navItemFlex(item.INDEX) }}
          >
            <NavItem title={item.NAME} value={item.VALUE} index={item.INDEX} />
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navbar;
