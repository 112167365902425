import classes from "./MetricDetails.module.css";
import { useSelector } from "react-redux";
import { RootStatePlatform } from "../../store/platform-slice";
import ChartBars from "./ChartBars";
import InfoPanel from "./InfoPanel";
import BiomarkerSection from "./BiomarkerSection";
import RecommendationsSection from "./RecommendationsSection";
import { scoreCategories } from "../../screens/ScoreScreen";

const MetricDetails = () => {
  const reportData = useSelector(
    (state: RootStatePlatform) => state.platform.dataPlatformServer
  );
  const selectedMetric = useSelector(
    (state: RootStatePlatform) => state.platform.selectedMetric
  );

  const selectedMetricData = reportData.metrics.find(
    (el: any) => el.type === selectedMetric
  );

  return (
    <div className={classes.container}>
      <ChartBars data={selectedMetricData} categories={scoreCategories} />
      <InfoPanel />
      <BiomarkerSection />
      <RecommendationsSection />
    </div>
  );
};

export default MetricDetails;
