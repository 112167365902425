import React from "react";

const ArrowLeft = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="left-arrow">
        <path
          id="Icon"
          d="M11.4998 15.4999C11.2346 15.4999 10.9803 15.3945 10.7928 15.2069L4.79279 9.20692C4.60532 9.01939 4.5 8.76508 4.5 8.49992C4.5 8.23475 4.60532 7.98045 4.79279 7.79292L10.7928 1.79292C10.9814 1.61076 11.234 1.50997 11.4962 1.51224C11.7584 1.51452 12.0092 1.61969 12.1946 1.8051C12.38 1.99051 12.4852 2.24132 12.4875 2.50352C12.4897 2.76571 12.3889 3.01832 12.2068 3.20692L6.91379 8.49992L12.2068 13.7929C12.3466 13.9328 12.4418 14.1109 12.4804 14.3049C12.5189 14.4988 12.4991 14.6999 12.4235 14.8826C12.3478 15.0653 12.2197 15.2214 12.0552 15.3313C11.8908 15.4412 11.6975 15.4999 11.4998 15.4999Z"
          fill="#1F2224"
        />
      </g>
    </svg>
  );
};

export default ArrowLeft;
