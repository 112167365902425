const ENDPOINTS = {
  ID: {
    REFRESH_TOKEN_URL: "api/users/refresh-token",
  },
  PLATFORM: {
    LOGIN_URL: "/api/v2/Login",
    REQUEST_RESET_PASSWORD: "/api/v1/User/SendPasswordReset/",
    RESET_PASSWORD: "/api/v1/User/ResetPassword",
    GET_USER_INFO: "/api/v2/Login/GetUser",
    GET_CUSTOMER_EMAIL: "/api/v1/Registration/GetRegistration/",
    GET_CUSTOMERS: "/api/v2/PrecisionReport/GetCustomers/",
    REGISTER_USER: "/api/v1/Registration/Activate",
    SUBMIT_BIOMETRIC_QUESTIONNAIRE: "/api/v1/Registration/UpdateProfile",
    SUBMIT_BIOMETRIC_QUESTIONNAIRE_TO_NUT_FAILED:
      "/api/v1/Registration/PostFail",
    GET_PLATFORM_DATA: "/api/v2/PrecisionReport/GetReport/",
    GET_REPORT_DATA: "/api/v2/Report/GetReportJson",
    GET_REPORT_DATA_PLATFORM: "/api/v2/Report/GetReportJsonP",
  },
  NUTRITION: {
    SETTINGS_URL: "api/customer/questionnaire/completed",
    SUBMIT_BIOMETRIC_QUESTIONNAIRE: "api/customer/questionnaire/biometrics",
    SUBMIT_LIFESTYLE_QUESTIONNAIRE: "api/customer/questionnaire/lifestyle",
    FETCH_HOME_URL: "api/customer/health/home",
    INTEGRATIONS_STATUS_URL: "api/customer/health/providers/status",
  },
};

export default ENDPOINTS;
