import { useEffect, useState } from "react";

import Layout from "../../components/reusable/Layout";
import Title from "../../components/reusable/Title";
import CustomRadarChart from "../../components/reusable/CustomRadarChart";
import SectionDescription from "./SectionDescription";
import TableItem from "../../components/reusable/TableItem";

import classes from "./PerformancePredictionPage.module.css";
import Table from "../../components/reusable/Table";

type ChartData = { INDEX: number; NAME: string; VALUE: number };

type cell = { INDEX: number; VALUE: string };
type column = cell[];
type row = { ROW: number; VALUES: column };

interface Props {
  data: {
    DESCRIPTION: null;
    INDEX: number;
    SECTION1: {
      DESCRIPTION: string;
      GRAPH_FOOTER1: string;
      GRAPH_FOOTER2: string;
      GRAPH_TITLE: string;
      GRAPH_VALUES: ChartData[];
      INDEX: number;
      TABLE: row[];
      TABLE_FOOTER: string;
      TABLE_HEADER: string;
      TITLE: string;
    };
    SECTION2: {
      INDEX: number;
      TABLE: row[];
      TABLE_FOOTER: row[];
      TITLE: string;
    };
    TITLE: string;
  };
  onSvgConverter: (
    content: string,
    result: string,
    imgWidth: number
  ) => JSX.Element;
}

const PerformancePredictionPage = ({
  data,
  onSvgConverter,
}: Props): JSX.Element => {
  const [imgLoad, setImgLoad] = useState(false);
  const chartWidth = 440;
  const chartHeight = 500;

  useEffect(() => {
    onSvgConverter("customRadarChart2", "PredictionChart", 440);
    setImgLoad(true);
  }, [onSvgConverter]);

  let imgChart = (
    <div
      id="customRadarChart2"
      className={classes.chartContainer}
      style={{ width: chartWidth, height: chartHeight }}
    >
      <CustomRadarChart
        data={data.SECTION1.GRAPH_VALUES}
        dataLabels={data.SECTION1.GRAPH_VALUES}
        title={data.SECTION1.GRAPH_TITLE}
        footer1={data.SECTION1.GRAPH_FOOTER1}
        footer2={data.SECTION1.GRAPH_FOOTER2}
        height={chartHeight - 100}
        width={chartWidth}
        typeOfLabelCustom="trianglePrediction"
        isAxisPredictionCustom={true}
        radius="86%"
        gridStroke="transparent"
        showTicks={false}
        startAngle={90}
        endAngle={-270}
        maxDomain={100}
        tickCount={6}
      />
    </div>
  );

  return (
    <Layout>
      <Title title={data.TITLE} />
      <div className={classes.section1}>
        <div id="PredictionChart" className={classes.imgRadarChart}>
          {!imgLoad && imgChart}
        </div>
        <SectionDescription data={data.SECTION1} rowHeight="50px" />
      </div>
      <div className={classes.section2}>
        <TableItem
          data={data.SECTION2}
          type="sectionTitle"
          minHeightTitle="35px"
          minHeight="35px"
          thresholdsTable={true}
          cardHeight={0}
          evenColumns={false}
          rowHeight={""}
        />
        <div className={classes.footer}>
          <Table
            data={data.SECTION2.TABLE_FOOTER}
            footer={true}
            minHeight="80px"
            minHeightTitle="20px"
            customAlign="flex-start"
            rowHeight={""}
          />
        </div>
      </div>
    </Layout>
  );
};

export default PerformancePredictionPage;
