import classes from "./RiskChartBar.module.css";

import riskPoint from "../../images/riskPoint.png";

type improveArea = { INDEX: number; TEXT: string; TITLE: string };

type Metric = {
  HOW_IT_IS_MEASURED: string;
  HOW_IT_IS_MEASURED_TEXT: string;
  INDEX: number;
  SUGGESTIONS: { IMPROVE_AREAS: improveArea[]; INDEX: number; TITLE: string }[];
  TITLE1: string;
  TITLE2: string;
  SUBTITLE?: string;
  TOP: string;
  VALUE: number;
  WHAT_IT_IS: string;
  WHAT_IT_IS_TEXT: string;
  WHY_ITS_IMPORTANT: string;
  WHY_ITS_IMPORTANT_TEXT: string;
  NORISK?: string;
  HIGHRISK?: string;
};

interface Props {
  data: Metric;
  color?: string;
  onSvgConverter: (
    content: string,
    result: string,
    imgWidth: number
  ) => JSX.Element;
}

const RiskChartBar = ({ data, color, onSvgConverter }: Props): JSX.Element => {
  let fillColorNoRisk: string = `${classes.fillNoRisk} `;
  let fillColorRisk: string = `${classes.fillRisk} `;
  let label1Style: string = `${classes.riskLabel}`;
  let label2Style: string = `${classes.riskLabel}`;
let balancePointStyle

  if (data.VALUE === 0) {
    fillColorRisk = `${classes.fillRisk} ${classes.noActive} `;
    label2Style = `${classes.riskLabel} ${classes.noActive}`;
balancePointStyle = {left:'25%'}
  } else {
    fillColorNoRisk = `${classes.fillNoRisk} ${classes.noActive} `;
    label1Style = `${classes.riskLabel} ${classes.noActive}`;
    balancePointStyle = {left:'75%'}
  }

  return (
    <div>
      {/* <div className={classes.subTitle}>{data.SUBTITLE}</div> */}
      <div className={classes.container} style={{ backgroundColor: color }}>
        <div className={classes.inner}>
          <div className={fillColorNoRisk}></div>
          <div className={fillColorRisk}></div>
          <img
            className={classes.balancePoint}
            src={riskPoint}
            style={balancePointStyle}
            alt="50% point"
          />
        </div>
      </div>
      <div className={classes.labels}>
        <div className={label1Style}>{data.NORISK}</div>
        <div className={label2Style}>{data.HIGHRISK}</div>
      </div>
    </div>
  );
};

export default RiskChartBar;
