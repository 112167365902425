import useTheme from "../hooks/useTheme";

const Female_icon = () => {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="59"
      viewBox="0 0 41 59"
      fill="none"
    >
      <path
        d="M41 18.4375C41 8.1125 31.98 0 20.5 0C9.02 0 0 8.1125 0 18.4375C0 27.2875 6.97 34.6625 16.4 36.5062V44.25H8.2V51.625H16.4V59H24.6V51.625H32.8V44.25H24.6V36.5062C34.03 34.6625 41 27.2875 41 18.4375ZM8.2 18.4375C8.2 12.1687 13.53 7.375 20.5 7.375C27.47 7.375 32.8 12.1687 32.8 18.4375C32.8 24.7062 27.47 29.5 20.5 29.5C13.53 29.5 8.2 24.7062 8.2 18.4375Z"
        fill={theme.colors_ds.NEUTRAL_ICON_DEFAULT}
      />
    </svg>
  );
};

export default Female_icon;
