import React, { MouseEventHandler } from "react";
import useTheme from "../hooks/useTheme";

type Props = { onClick?: MouseEventHandler<SVGSVGElement> };

const LogoSmall = ({ onClick }: Props) => {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="33"
      viewBox="0 0 55 33"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.7102 0.00390533C48.6918 0.00390533 54.9959 5.11467 55 13.0321C55 21.6121 48.8708 27.3867 34.5106 27.3867C30.6419 27.3867 25.9555 27.1381 21.0616 26.6421C20.9626 26.6317 20.8637 26.6617 20.7904 26.7241C20.7159 26.7879 20.6738 26.8778 20.6738 26.9715V32.504C20.6738 32.7774 20.4392 33 20.149 33H12.4428C12.1526 33 11.918 32.7787 11.918 32.5053V25.7205C11.918 25.5604 11.796 25.4237 11.6278 25.3963C7.79031 24.7624 4.06262 23.9774 0.732248 23.0401C0.509862 22.9776 0.357988 22.785 0.359344 22.5663V15.6161C0.359344 15.512 0.410873 15.4156 0.499014 15.3544C0.585799 15.292 0.699704 15.2724 0.805473 15.2998C4.37044 16.2631 8.13474 17.0493 11.9167 17.6755V0.49858C11.9167 0.225207 12.1526 0.00390533 12.4428 0.00390533H38.7102ZM37.8328 7.75467H21.0223C20.8284 7.75467 20.6711 7.90308 20.6711 8.08533V15.9715C20.6711 16.155 20.5762 16.3282 20.4135 16.4336C19.41 17.0845 17.7218 17.6716 15.921 17.9176C15.8233 17.932 15.7515 18.0114 15.7515 18.1038C15.7515 18.1975 15.8233 18.2756 15.921 18.2899C22.5682 19.222 29.3374 19.6386 35.0327 19.6386C42.7375 19.6347 45.8903 17.3253 45.8889 13.6947C45.8889 9.9 43.0874 7.75467 37.8342 7.75467H37.8328ZM3.75888 0C5.83629 0 7.51911 1.58556 7.51911 3.54213C7.51911 5.4987 5.83629 7.08426 3.75888 7.08426C1.68417 7.08426 0 5.4987 0 3.54343C0 1.58426 1.68282 0 3.75888 0ZM3.90939 1.96959H2.3432V4.85562H3.19748V3.87669H3.89719L4.5074 4.85562H5.48915L4.73792 3.71527C4.902 3.65018 5.04438 3.54343 5.14472 3.40544C5.23964 3.26225 5.28575 3.09562 5.27897 2.92639C5.27897 2.28852 4.82199 1.96828 3.90939 1.96828V1.96959ZM3.86464 2.58142C4.06127 2.58142 4.20094 2.60876 4.28501 2.66213C4.37315 2.71941 4.42061 2.81834 4.41112 2.91858C4.42197 3.02272 4.37044 3.12426 4.27823 3.18284C4.21178 3.22189 4.11551 3.24793 3.9894 3.25834L3.8538 3.26355H3.19342V2.58272H3.86464V2.58142Z"
        fill={theme.colors_ds.NEUTRAL_ICON_STRONG}
      />
    </svg>
  );
};

export default LogoSmall;
