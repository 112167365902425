import classes from "./CustomLabelsTriangle.module.css";

type tag = { INDEX: number; NAME: string; VALUE: number };

interface Props {
  data: tag[];
}

const CustomLabelsTriangle = ({ data }: Props): JSX.Element => {
  const tagTypeHandler = (index: number) => {
    let tagType: string;
    switch (index) {
      case 1:
        tagType = classes.cardioTag;
        break;
      case 2:
        tagType = classes.resistanceTag;
        break;
      case 3:
        tagType = classes.intervalTag;
        break;
      default:
        tagType = "";
    }
    return tagType;
  };
  return (
    <>
      {data.map((item) => (
        <div key={item.INDEX} className={tagTypeHandler(item.INDEX)}>
          <div className={classes.title}>{item.NAME}</div>
          <div className={classes.value}>{item.VALUE}%</div>
        </div>
      ))}
    </>
  );
};

export default CustomLabelsTriangle;
