import classes from "./Axis.module.css";

const Axis = (): JSX.Element => {
  return (
    <div className={classes.axis}>
      <div className={classes.line1}>
        <div className={classes.dot} style={{ left: "29.7px" }}></div>
        <div className={classes.dot} style={{ left: "59.4px" }}></div>
        <div className={classes.dot} style={{ left: "89.1px" }}></div>
        <div className={classes.dot} style={{ left: "118.9px" }}></div>
        <div className={classes.dot} style={{ left: "148.6px" }}></div>
        <div className={classes.dot} style={{ left: "178.3px" }}></div>
        <div className={classes.dot} style={{ left: "208px" }}></div>
      </div>
      <div className={classes.line2}>
        <div className={classes.dot} style={{ left: "29.7px" }}></div>
        <div className={classes.dot} style={{ left: "59.4px" }}></div>
        <div className={classes.dot} style={{ left: "89.1px" }}></div>
        <div className={classes.dot} style={{ left: "118.9px" }}></div>
        <div className={classes.dot} style={{ left: "148.6px" }}></div>
        <div className={classes.dot} style={{ left: "178.3px" }}></div>
        <div className={classes.dot} style={{ left: "208px" }}></div>
      </div>
      <div className={classes.line3}>
        <div className={classes.dot} style={{ left: "29.7px" }}></div>
        <div className={classes.dot} style={{ left: "59.4px" }}></div>
        <div className={classes.dot} style={{ left: "89.1px" }}></div>
        <div className={classes.dot} style={{ left: "118.9px" }}></div>
        <div className={classes.dot} style={{ left: "148.6px" }}></div>
        <div className={classes.dot} style={{ left: "178.3px" }}></div>
        <div className={classes.dot} style={{ left: "208px" }}></div>
      </div>
    </div>
  );
};

export default Axis;
