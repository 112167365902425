import classes from "./TreatmentPlanTab.module.css";
import useTheme from "../../../../common/hooks/useTheme";
import TreatmentPlanCard from "./TreatmentPlanCard";

const IVTherapyData = [
  {
    title: "Acetylcysteine Injection",
    details: [
      { label: "Dose", value: "200 mg/mL" },
      { label: "Duration", value: "20 min" },
      { label: "Frequency", value: "1 time/week" },
      { label: "Cycling power", value: "xxx" },
      { label: "Altitude", value: "xxx" },
    ],
  },
  {
    title: "Acetylcysteine Injection",
    details: [
      { label: "Dose", value: "200 mg/mL" },
      { label: "Duration", value: "20 min" },
      { label: "Frequency", value: "1 time/week" },
      { label: "Cycling power", value: "xxx" },
      { label: "Altitude", value: "xxx" },
    ],
  },
  {
    title: "Acetylcysteine Injection",
    details: [
      { label: "Dose", value: "200 mg/mL" },
      { label: "Duration", value: "20 min" },
      { label: "Frequency", value: "1 time/week" },
      { label: "Cycling power", value: "xxx" },
      { label: "Altitude", value: "xxx" },
    ],
  },
  {
    title: "Acetylcysteine Injection",
    details: [
      { label: "Dose", value: "200 mg/mL" },
      { label: "Duration", value: "20 min" },
      { label: "Frequency", value: "1 time/week" },
      { label: "Cycling power", value: "xxx" },
      { label: "Altitude", value: "xxx" },
    ],
  },
];

const TreatmentPlanTab = () => {
  const theme = useTheme();
  return (
    <div className={classes.innerContainer}>
      <div
        className={classes.title}
        style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
      >
        IV therapy
      </div>
      <div className={classes.cardContainer}>
        {IVTherapyData.map((item, index) => (
          <TreatmentPlanCard
            title={item.title}
            details={item.details}
            key={index}
          />
        ))}
      </div>
      <div
        className={classes.title}
        style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
      >
        Supplements
      </div>
      <div className={classes.cardContainer}>
        {IVTherapyData.map((item, index) => (
          <TreatmentPlanCard
            title={item.title}
            details={item.details}
            key={index}
          />
        ))}
      </div>
      <div
        className={classes.title}
        style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}
      >
        Services
      </div>
      <div className={classes.cardContainer}>
        {IVTherapyData.map((item, index) => (
          <TreatmentPlanCard
            title={item.title}
            details={item.details}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

export default TreatmentPlanTab;
