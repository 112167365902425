import useTheme from "../../../../common/hooks/useTheme";

import classes from "./CardWithBarChart.module.css";

type Props = {
  data: {
    label: string;
    current: number;
    target: number;
  };
};

const CardWithBarChart = ({ data }: Props) => {
  const theme = useTheme();
  return (
    <div
      className={classes.cardContainer}
      style={{ borderColor: theme.colors_ds.NEUTRAL_BORDER_WEAK }}
    >
      <div className={classes.cardDetailsContainer}>
        <div
          className={classes.label}
          style={{ color: theme.colors_ds.NEUTRAL_TEXT_STRONG }}
        >
          {data.label}
        </div>
        <div
          className={classes.value}
          style={{ color: theme.colors_ds.NEUTRAL_TEXT_STRONG }}
        >
          {`${Math.round(data.current / data.target)}%`}
        </div>
      </div>
      <div
        className={classes.bar}
        style={{ backgroundColor: theme.colors_ds.NEUTRAL_BORDER_GRAPH }}
      >
        <div
          className={classes.barInner}
          style={{
            width: `${data.current / data.target}%`,
            backgroundColor: theme.colors_ds.PRIMARY_GRAPH_MAIN,
          }}
        />
      </div>
    </div>
  );
};

export default CardWithBarChart;
