export const formAnswers = {
  myAnswers: {
    birth_date: "",
    gender: "",
    length_measurement_unit: "",
    height: "",
    mass_measurement_unit: "",
    current_weight: "",
    target_weight: "",
    primary_fitness_goal: "",
    secondary_fitness_goal: "",
    fat_mass_target: "",
    muscle_mass_target: "",
    fat_loss_intensity: "",
    physical_activity: "",
    occupation_type: "",
    resistance_training_level: "",
    cardio_training_level: "",
    interval_training_level: "",
  },
};

export const lifeStyleFormAnswers = {
  myAnswers: {
    resistance_training_frequency: "",
    cardio_training_frequency: "",
    interval_training_frequency: "",
    dietary_restrictions: 0,
    sleep_consistency: "",
    sleep_average: "",
    main_meal_before_bed: "",
    nibble_frequency: "",
    walking_frequency: "",
    fruit_frequency: "",
    fruit_preference: 0,
    vegetable_frequency: "",
    vegetable_preference: 0,
    meat_frequency: "",
    processed_food_frequency: "",
    lean_protein_frequency: "",
    legumes_frequency: "",
    protein_preference: 0,
    healthy_fats_frequency: "",
    fat_source_preference: 0,
    grains_preference: 0,
    alcohol_frequency: "",
    coffee_frequency: "",
    sunlight_frequency: "",
    morning_sunlight_frequency: "",
    snore_frequency: "",
    breathing_exercise_frequency: "",
    core_exercise_frequency: "",
    weight_loss_frequency: "",
    medical_conditions: 0,
    kcal_intake_limit: "",
    focus_issues: "",
    smoking_status: "",
  },
};
