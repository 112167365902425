import {
  Button,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import useTheme from "../../../../common/hooks/useTheme";

type Props = {
  children: any;
  message: string;
};

const CustomTooltip = ({ children, message }: Props) => {
  const theme = useTheme();
  const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
      arrow
      {...props}
      classes={{ popper: className }}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [-2, -14],
              },
            },
          ],
        },
      }}
      children={props.children}
      sx={{}}
    />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 252,
      borderRadius: "8px",
      padding: "8px",
      boxShadow: "var(--box-shadow-general)",
      backgroundColor: theme.colorTokens.P[10],
      color: theme.colors_ds.NEUTRAL_TEXT_STRONG,
      fontSize: "14px",
      fontFamily: "var(--font-Medium)",
    },
    [`& .${tooltipClasses.arrow}::before`]: {
      backgroundColor: theme.colorTokens.P[10],
    },
  });
  return (
    <CustomWidthTooltip title={message}>
      <div>{children}</div>
    </CustomWidthTooltip>
  );
};

export default CustomTooltip;
