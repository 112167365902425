import { useSelector } from "react-redux";

import Quest from "../layout/Quest";
import Layout from "../layout/Layout";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAppStore } from "../../../../providers/AppStore";
import { RootStateStepper } from "../../store/stepper-slice";

const BiometricQuestionnaire = () => {
  const stepper = useSelector((state: RootStateStepper) => state.stepper.step);
  const navigate = useNavigate();
  const { ASIsUserLoaded } = useAppStore();

  useEffect(() => {
    if (!ASIsUserLoaded()) {
      navigate("/auth");
    }
  }, []);

  const getContent = (step: number) => {
    switch (step) {
      case 2:
      case 3:
        return <Quest flexType="row" />;
      case 4:
      case 5:
        return <Quest scaleType="massUnit" />;
      case 8:
        return <Quest labelDescription={true} />;
      case 9:
        return <Quest scaleType="lengthUnit" />;
      default:
        return <Quest />;
    }
  };

  return <Layout>{getContent(stepper)}</Layout>;
};

export default BiometricQuestionnaire;
