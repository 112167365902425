import { LocalStorageVariables, useConfig } from "../../providers/AppStore";
import axios from "axios";
import { DEBUG_PRINT_API_CALLS } from "../../debugFlags";
import ENDPOINTS from "./Endpoints";
import { redirect } from "react-router-dom";

const AppApiInstance = async (
  baseUrlType: string,
  authorization: boolean,
  basicAuthorization: boolean,
  useAuthenticationToken: boolean
) => {
  let CONFIG = await useConfig();
  const value = await localStorage.getItem(LocalStorageVariables.USER);
  const env = await localStorage.getItem(LocalStorageVariables.ENVIRONMENT);
  const aflValue = await localStorage.getItem(
    LocalStorageVariables.AFFILIATE_USER
  );

  const usr = JSON.parse(value);
  const aflUser = JSON.parse(aflValue);

  if (authorization && !usr?.token) {
    console.log("authorized endpoint with no token");
    return null;
  }

  const instance = axios.create({
    baseURL: `${CONFIG[baseUrlType]}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      "X-GO": "1",
      Accept: "application/json",
      "Accept-Version": "1.0.0",
      "Action-Source": "customer_mobile_precision",
      //add env header if needed
      ...(baseUrlType === "PLATFORM_BASE_URL" && { "X-Environment": env }),
      // add authorization header if needed
      ...(authorization &&
        usr?.token && { Authorization: "Bearer " + usr?.token }),
      // ...(useAuthenticationToken &&
      //   aflUser?.authenticationToken && {
      //     AuthenticationToken: aflUser.authenticationToken,
      //   }),
    },
  });

  instance.interceptors.request.use(
    (x) => {
      const headers = {
        ...x.headers.common,
        ...x.headers[x.method],
        ...x.headers,
      };
      // remove irrelevant headers to reduce the noise
      ["common", "get", "post", "head", "put", "patch", "delete"].forEach(
        (header) => {
          delete headers[header];
        }
      );
      const printable = `REQUEST | ${new Date().toISOString()} | Request: ${x.method.toUpperCase()} | ${
        x.url
      } | ${JSON.stringify(x.data)} | ${JSON.stringify(headers)}`;

      DEBUG_PRINT_API_CALLS && console.log(printable);

      return x;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      console.log("error expire token", error.response.data);

      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (x) => {
      const printable = `RESPONSE |
  ${new Date().toISOString()} |
  Response: ${x.status} |
  Error: ${JSON.stringify(x.data.error)} |
    Data:  ${JSON.stringify(x.data)}`;

      DEBUG_PRINT_API_CALLS && console.log(printable);
      return x;
    },
    async function (error) {
      const originalRequest = error.config;
      // If the error status is 401 and there is no originalRequest._retry flag,
      // it means the token has expired and we need to refresh it
      console.log(
        "Api Call is Rejected! Error Message ->",
        JSON.stringify(error.response.data)
      );
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
          const response = await axios.post(
            `${CONFIG["ID_BASE_URL"]}${ENDPOINTS.ID.REFRESH_TOKEN_URL}`,
            {
              id: usr?.id,
              refresh_token: usr?.refresh_token,
            }
          );
          const { id_token } = response.data;
          usr.id_token = id_token;
          await localStorage.setItem(
            LocalStorageVariables.USER,
            JSON.stringify(usr)
          );

          originalRequest.headers.Authorization = `Bearer ${id_token}`;
          return axios(originalRequest);
        } catch (e) {
          console.log("error in refresh token request", e);
          // await AsyncStorage.removeItem(AsyncStorageVariables.USER);
          // await AsyncStorage.removeItem(AsyncStorageVariables.NOT_NOW_FEEDBACK);
          // await AsyncStorage.removeItem(AsyncStorageVariables.BACKGROUND_DATE);
          // await AsyncStorage.removeItem(AsyncStorageVariables.NOTIFICATIONS);
          // await AsyncStorage.removeItem(AsyncStorageVariables.GROCERY_LIST);
          // await AsyncStorage.removeItem(AsyncStorageVariables.ENVIRONMENT);
          // await AsyncStorage.removeItem(AsyncStorageVariables.UTC_OFFSET);
          // RootNavigation.navigate(Routes.Login, {});
        }
      }

      return Promise.reject(error);
    }
  );
  return instance;
};

export const PrecisionIDBaseApi = async () =>
  await AppApiInstance("ID_BASE_URL", false, false, false);

export const PrecisionIDInternalBaseApi = async () =>
  await AppApiInstance("ID_BASE_URL", false, true, false);

export const PrecisionNUTBaseApi = async () =>
  await AppApiInstance("NUT_BASE_URL", true, false, false);

export const PrecisionNoAuthNUTBaseApi = async () =>
  await AppApiInstance("NUT_BASE_URL", false, false, false);

export const PrecisionPlatformBaseApi = async () =>
  await AppApiInstance("PLATFORM_BASE_URL", true, false, false);

export const PrecisionNoAuthtokenPlatformBaseApi = async () =>
  await AppApiInstance("PLATFORM_BASE_URL", false, false, false);
