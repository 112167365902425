import { useEffect, useState } from "react";

import Layout from "../../components/reusable/Layout";
import Title from "../../components/reusable/Title";
import CustomCard from "../../components/reusable/CustomCard";

import Navbar from "../../components/navbar/Navbar";
import MetricsSection from "./MetricsSection";
import RiskChartBar from "../../components/reusable/RiskChartBar";

import classes from "./OverviewMetricBarGraphPage.module.css";
import CalsTable from "../CaloricBalance/CalsTable";
import FuelSources from "../CaloricBalance/FuelSources";

type Tab = { INDEX: number; NAME: string; VALUE: number };

type improveArea = { INDEX: number; TEXT: string; TITLE: string };

type Metric = {
  HOW_IT_IS_MEASURED: string;
  HOW_IT_IS_MEASURED_TEXT: string;
  INDEX: number;
  SUGGESTIONS: { IMPROVE_AREAS: improveArea[]; INDEX: number; TITLE: string }[];
  TITLE1: string;
  TITLE2: string;
  SUBTITLE?: string;
  TOP: string;
  VALUE: number;
  WHAT_IT_IS: string;
  WHAT_IT_IS_TEXT: string;
  WHY_ITS_IMPORTANT: string;
  WHY_ITS_IMPORTANT_TEXT: string;
  NORISK?: string;
  HIGHRISK?: string;
};

interface Props {
  data: {
    DESCRIPTION: null;
    INDEX: number;
    METRICS: Metric[];
    NAVBAR: Tab[];
    RISK_METRICS: Metric[];
    PERFORMANCE_METRICS: Metric[] | null;
    QRCODE: {
      FOOTER1: string;
      HEADER1: string;
      HEADER2: string;
      QRCODE: string;
    };

    TITLE: string;

    TITLE_PERFORMANCE_METRICS: string;
  };
  data2?: any;
  isCustomReport?: boolean;
  onSvgConverter: (
    content: string,
    result: string,
    imgWidth: number
  ) => JSX.Element;
}

const OverviewMetricBarGraphPage = ({
  data,
  data2,
  isCustomReport,
  onSvgConverter,
}: Props): JSX.Element => {
  const [imgRiskBar, setImgRiskBar] = useState(false);
  let showSubTitle =
    data.PERFORMANCE_METRICS !== null && data.PERFORMANCE_METRICS.length > 0;
  let showRiskMetricBar = data.RISK_METRICS.length > 0;

  useEffect(() => {
    if (showRiskMetricBar && !isCustomReport) {
      onSvgConverter("riskBar", "imgRiskBar", 750);
      setImgRiskBar(true);
    }
  }, [onSvgConverter, showRiskMetricBar]);

  const RiskBar = (
    <div id="riskBar">
      <CustomCard cardPadding="14px 23px" cardMargin="100px auto">
        <div className={classes.title}>
          {showRiskMetricBar && data.RISK_METRICS[0].TITLE1}
        </div>
        <RiskChartBar
          data={data.RISK_METRICS[0]}
          onSvgConverter={onSvgConverter}
        />
      </CustomCard>
    </div>
  );

  return (
    <Layout>
      <Title title={data.TITLE} />
      <Navbar data={data.NAVBAR} />
      <MetricsSection data={data.METRICS} labels={data.NAVBAR.slice(0, -1)} />
      {showSubTitle && (
        <div className={classes.subTitle}>{data.TITLE_PERFORMANCE_METRICS}</div>
      )}
      {data.PERFORMANCE_METRICS !== null && (
        <MetricsSection
          data={data.PERFORMANCE_METRICS}
          labels={data.NAVBAR.slice(0, -1)}
        />
      )}
      {showRiskMetricBar && !isCustomReport && (
        <div id="imgRiskBar" className={classes.imgRiskBar}>
          {!imgRiskBar && RiskBar}
        </div>
      )}
      <div style={{ marginTop: "40px" }}>
        {isCustomReport && <CalsTable data={data2.SECTION2} />}
        {isCustomReport && <FuelSources data={data2.SECTION3} />}
      </div>
    </Layout>
  );
};

export default OverviewMetricBarGraphPage;
