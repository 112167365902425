import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const ArrowDown = ({ width = 24, height = 24 }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99992 12.1938C7.73472 12.1938 7.48041 12.0884 7.29292 11.9008L1.29292 5.90081C1.11076 5.7122 1.00997 5.4596 1.01224 5.19741C1.01452 4.93521 1.11969 4.6844 1.3051 4.49899C1.49051 4.31358 1.74132 4.20841 2.00352 4.20613C2.26571 4.20385 2.51832 4.30465 2.70692 4.48681L7.99992 9.77981L13.2929 4.48681C13.3852 4.3913 13.4955 4.31511 13.6175 4.26271C13.7395 4.2103 13.8707 4.18271 14.0035 4.18156C14.1363 4.1804 14.268 4.2057 14.3909 4.25598C14.5138 4.30627 14.6254 4.38052 14.7193 4.47441C14.8132 4.5683 14.8875 4.67996 14.9377 4.80285C14.988 4.92575 15.0133 5.05743 15.0122 5.19021C15.011 5.32299 14.9834 5.45421 14.931 5.57621C14.8786 5.69822 14.8024 5.80856 14.7069 5.90081L8.70692 11.9008C8.51943 12.0884 8.26511 12.1938 7.99992 12.1938Z"
        fill="#3B3F42"
      />
    </svg>
  );
};

export default ArrowDown;
