import { useState } from "react";
import classes from "./ClientsSearchBar.module.css";
import useTheme from "../../../../common/hooks/useTheme";
import SearchIcon from "../../svg/SearchIcon";

type Props = { setClientFilter: Function };

const ClientsSearchBar = ({ setClientFilter }: Props) => {
  const theme = useTheme();
  
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div 
      className={`${classes.container} ${isFocused ? classes.containerFocused : ''}`}
      style={{
        borderColor: isFocused ? theme.colors_ds.PRIMARY_TEXT_MAIN : theme.colors_ds.NEUTRAL_BORDER_DEFAULT,
      }}
    >
      {!isFocused && (
        <div className={classes.icon}>
          <SearchIcon fill={theme.colors_ds.NEUTRAL_ICON_WEAK} />
        </div>
      )}
      {isFocused && (
        <label 
          className={classes.label}
          style={{
            color: theme.colors_ds.PRIMARY_TEXT_MAIN,
          }}
        >Choose customer</label>
      )}
      <input
        type="text"
        className={classes.input}
        style={{
          color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT,
          caretColor: theme.colors_ds.NEUTRAL_TEXT_DEFAULT,
        }}
        onChange={(e) => setClientFilter(e.target.value)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        placeholder="Choose client"
      />
    </div>
  );
};

export default ClientsSearchBar;