import classes from "./BiomarkerCard.module.css";
import ArrowDown from "../../svg/ArrowDown";
import useTheme from "../../../../common/hooks/useTheme";
import { useSelector } from "react-redux";
import { RootStatePlatform } from "../../store/platform-slice";
import { getBiomarkerCategoryColor } from "../../store/helper";
import CustomLineChart from "./CustomLineChart";
import React, { useState } from "react";
import { Popover } from "@mui/material";

type Props = {
  item: any;
  setExpandedBiomarker: Function;
  expandedBiomarker: number;
  index: number;
};
const BiomarkerCard = ({
  item,
  setExpandedBiomarker,
  expandedBiomarker,
  index,
}: Props) => {
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setExpandedBiomarker(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setExpandedBiomarker(-1);
  };

  const open = Boolean(anchorEl);
  const id = open ? "biomarker-card-popper" : undefined;

  const data = useSelector(
    (state: RootStatePlatform) => state.platform.data.score
  );

  return (
    <div
      className={classes.biomarkerCardContainer}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className={classes.biomarkerCard}
        style={{
          borderColor:
            expandedBiomarker === index
              ? theme.colors_ds.PRIMARY_ICON_MAIN
              : isHovered
              ? theme.colors_ds.NEUTRAL_BORDER_DEFAULT
              : theme.colors_ds.NEUTRAL_BORDER_WEAK,
        }}
      >
        <div
          className={classes.categoryIcon}
          style={{
            backgroundColor: getBiomarkerCategoryColor(
              item.scoreCategory,
              theme,
              "biomarkers"
            ),
          }}
        />
        <div className={classes.biomarkerCardInner}>
          <div
            className={classes.biomarkerLabel}
            style={{ color: theme.colors_ds.NEUTRAL_TEXT_STRONG }}
          >
            {item.label}
          </div>
          <div className={classes.biomarkerCardDetails}>
            <div
              className={classes.biomarkerValue}
              style={{ color: theme.colors_ds.NEUTRAL_TEXT_STRONG }}
            >
              {item.value}
              {item.unit}
            </div>
            <div
              className={classes.biomarkerCategory}
              style={{ color: theme.colors_ds.NEUTRAL_TEXT_WEAK }}
            >
              {data.BiomarkerCategories[item.scoreCategory]}
            </div>
          </div>
        </div>
        <div
          onClick={handleClick}
          style={{
            transform: expandedBiomarker === index ? "rotate(180deg)" : "",
            transition: "transform 150ms ease",
            height: "16px",
          }}
        >
          <ArrowDown width={16} height={16} />
        </div>
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{
          top: anchorEl?.getBoundingClientRect().bottom || 0,
          left: 24,
        }} // Align to the left of the viewport
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPaper-root": {
            boxShadow: "none",
            backgroundColor: "transparent",
          },
        }}
      >
        <CustomLineChart />
      </Popover>
    </div>
  );
};

export default BiomarkerCard;
