import classes from "./WorkoutPlanTab.module.css";
import useTheme from "../../../../common/hooks/useTheme";
import PlatformTable from "./PlatformTable";
import PieChartWithoutSectors from "../common/PieChartWithoutSectors";
import { useSelector } from "react-redux";
import { RootStatePlatform } from "../../store/platform-slice";

const WorkoutPlanTab = () => {
  const theme = useTheme();
  const reportData = useSelector(
    (state: RootStatePlatform) =>
      state.platform.dataPlatformServer.programming.training
  );

  const normalizedTableTrainingProgramData = reportData.training_program.map(
    (el: any) => {
      return {
        c1: el.zone,
        c2: el.hr,
        c3: el.watts,
        c4: el.speed,
        c5: el.benefits,
        c6: el.feels_like,
        c7: el.when_to_use,
      };
    }
  );

  const normalizedTableEnergyConsumptionData =
    reportData.energy_consumption.map((el: any) => {
      return {
        c1: el.zone,
        c2: el.fat,
        c3: el.carbs,
        c4: el.average,
        c5: el.lower,
        c6: el.upper,
      };
    });

  const tableEnduranceData = [
    {
      c1: "Exercise",
      c2: "Calories",
      c3: "Duration",
      c4: "Zone",
      c5: "Sessions",
    },
    {
      c1: "Running",
      c2: "200 Kcal",
      c3: "40 min",
      c4: "Zone 2",
      c5: "2/week",
    },
  ];

  const tableIntervalData = [
    {
      c1: "Exercise",
      c2: "Calories",
      c3: "Duration",
      c4: "Zone",
      c5: "Sessions",
      c6: "Sets",
      c7: "Recovery",
      c8: "Effort",
    },
    {
      c1: "Running",
      c2: "200 Kcal",
      c3: "40 min",
      c4: "Zone 2",
      c5: "2/week",
      c6: "3",
      c7: "30 sec",
      c8: "Intensity",
    },
    {
      c1: "Running",
      c2: "200 Kcal",
      c3: "40 min",
      c4: "Zone 2",
      c5: "2/week",
      c6: "3",
      c7: "30 sec",
      c8: "Intensity",
    },
  ];

  const tableResistanceLowerBodyData = [
    {
      c1: "Exercise",
      c2: "Reps",
      c3: "Recovery",
      c4: "Sets",
    },
    {
      c1: "Squats",
      c2: "20",
      c3: "20 sec",
      c4: "3",
    },
    {
      c1: "Leg press",
      c2: "20",
      c3: "20 sec",
      c4: "3",
    },
    {
      c1: "Hip thrusters",
      c2: "20",
      c3: "20 sec",
      c4: "3",
    },
    {
      c1: "Bulgarian split squats",
      c2: "20",
      c3: "20 sec",
      c4: "3",
    },
    {
      c1: "RDLs",
      c2: "20",
      c3: "20 sec",
      c4: "3",
    },
  ];

  const tableResistanceBackData = [
    {
      c1: "Exercise",
      c2: "Reps",
      c3: "Recovery",
      c4: "Sets",
    },
    {
      c1: "Squats",
      c2: "20",
      c3: "20 sec",
      c4: "3",
    },
    {
      c1: "Leg press",
      c2: "20",
      c3: "20 sec",
      c4: "3",
    },
    {
      c1: "Hip thrusters",
      c2: "20",
      c3: "20 sec",
      c4: "3",
    },
    {
      c1: "Bulgarian split squats",
      c2: "20",
      c3: "20 sec",
      c4: "3",
    },
    {
      c1: "RDLs",
      c2: "20",
      c3: "20 sec",
      c4: "3",
    },
  ];

  const tableResistanceArmsData = [
    {
      c1: "Exercise",
      c2: "Reps",
      c3: "Recovery",
      c4: "Sets",
    },
    {
      c1: "Squats",
      c2: "20",
      c3: "20 sec",
      c4: "3",
    },
    {
      c1: "Leg press",
      c2: "20",
      c3: "20 sec",
      c4: "3",
    },
    {
      c1: "Hip thrusters",
      c2: "20",
      c3: "20 sec",
      c4: "3",
    },
    {
      c1: "Bulgarian split squats",
      c2: "20",
      c3: "20 sec",
      c4: "3",
    },
    {
      c1: "RDLs",
      c2: "20",
      c3: "20 sec",
      c4: "3",
    },
  ];

  const tableBodyPartsData = [
    {
      c1: "Body part",
      c2: "Type",
      c3: "Effort",
      c4: "Calories",
      c5: "Sessions",
      c6: "Duration",
      c7: "Rep range",
    },
    {
      c1: "Lower body",
      c2: "Hypertrophy",
      c3: "60-70%",
      c4: "200 kcal",
      c5: "2/week",
      c6: "40 min",
      c7: "8-10",
    },
    {
      c1: "Back",
      c2: "Hypertrophy",
      c3: "60-70%",
      c4: "200 kcal",
      c5: "2/week",
      c6: "40 min",
      c7: "8-10",
    },
    {
      c1: "Arms & Shoulders",
      c2: "Hypertrophy",
      c3: "60-70%",
      c4: "200 kcal",
      c5: "2/week",
      c6: "40 min",
      c7: "8-10",
    },
  ];

  const trainingBreakdownChartData = {
    title: "Training Breakdown",
    pieData: [
      { label: "Interval", value: 350, categoryType: 1 },
      { label: "Resistance", value: 450, categoryType: 2 },
      { label: "Endurance", value: 350, categoryType: 3 },
    ],
  };

  const getNormalizedDataForPieChart = (data: any) => {
    const percentage1 = Math.round(
      (data[0].value / (data[0].value + data[1].value + data[2].value)) * 100
    );
    const percentage3 = Math.round(
      (data[2].value / (data[0].value + data[1].value + data[2].value)) * 100
    );

    return data.map((el: any) => {
      if (el.categoryType === 2) {
        return {
          label: el.label,
          value: el.value,
          categoryType: el.categoryType,
          percentage: 100 - percentage1 - percentage3,
        };
      } else {
        return {
          label: el.label,
          value: el.value,
          categoryType: el.categoryType,
          percentage: el.categoryType === 1 ? percentage1 : percentage3,
        };
      }
    });
  };

  return (
    <div className={classes.innerContainer}>
      <PlatformTable
        data={normalizedTableTrainingProgramData}
        title="Training program"
      />
      <PlatformTable
        data={normalizedTableEnergyConsumptionData}
        title="Energy consumption & fueling"
      />
      {/*<div className={classes.enduranceTableContainer}>*/}
      {/*  <PlatformTable*/}
      {/*    data={tableEnduranceData}*/}
      {/*    title="Endurance"*/}
      {/*    dataPointColor={theme.colorTokens.D[50]}*/}
      {/*  />*/}
      {/*  <PieChartWithoutSectors*/}
      {/*    title={trainingBreakdownChartData.title}*/}
      {/*    pieData={getNormalizedDataForPieChart(*/}
      {/*      trainingBreakdownChartData.pieData*/}
      {/*    )}*/}
      {/*    type="trainingBreakdown"*/}
      {/*  />*/}
      {/*</div>*/}
      {/*<PlatformTable*/}
      {/*  data={tableIntervalData}*/}
      {/*  title="Interval"*/}
      {/*  dataPointColor={theme.colorTokens.W[40]}*/}
      {/*/>*/}
      {/*<div>*/}
      {/*  <div className={classes.titleContainer}>*/}
      {/*    <div*/}
      {/*      className={classes.dataPoint}*/}
      {/*      style={{ backgroundColor: theme.colors_ds.PRIMARY_GRAPH_MAIN }}*/}
      {/*    />*/}

      {/*    <div*/}
      {/*      className={classes.title}*/}
      {/*      style={{ color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT }}*/}
      {/*    >*/}
      {/*      Resistance*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className={classes.resistanceTableContainer}>*/}
      {/*    <PlatformTable*/}
      {/*      data={tableResistanceLowerBodyData}*/}
      {/*      title="Lower Body"*/}
      {/*      hasSmallFontInTitle={true}*/}
      {/*      hasUnevenColumns={true}*/}
      {/*    />*/}
      {/*    <PlatformTable*/}
      {/*      data={tableResistanceBackData}*/}
      {/*      title="Back"*/}
      {/*      hasSmallFontInTitle={true}*/}
      {/*      hasUnevenColumns={true}*/}
      {/*    />*/}
      {/*    <PlatformTable*/}
      {/*      data={tableResistanceArmsData}*/}
      {/*      title="Arms & Shoulders"*/}
      {/*      hasSmallFontInTitle={true}*/}
      {/*      hasUnevenColumns={true}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<PlatformTable data={tableBodyPartsData} />*/}
    </div>
  );
};

export default WorkoutPlanTab;
