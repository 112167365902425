import CustomCard from "../../components/reusable/CustomCard";
import ScaleItem from "./ScaleItem";
import classes from "./ScaleBalance.module.css";

import triangle from "../../images/triangle.png";

// interface Props {
//   data: {
//     ACTIVE_CALORIES_TITLE: string;
//     ACTIVE_CALORIES_VALUE: number;
//     DAILY_ACTIVITY_CALORIES_TITLE: string;
//     DAILY_ACTIVITY_CALORIES_VALUE: number;
//     RMR_TITLE: string;
//     RMR_VALUE: number;
//     WEIGHT_GAIN_TEXT: string;
//     WEIGHT_GAIN_TITLE: string;
//     WEIGHT_LOSS_TEXT: string;
//     WEIGHT_LOSS_TITLE: string;
//     WEIGHT_MAINTENANCE_TEXT: string;
//     WEIGHT_MAINTENANCE_TITLE: string;
//   };
// }
const ScaleBalance = ({ data }) => {
  const SECTIONS = {
    INDEX: 1,
    TABLE1: [
      {
        INDEX: 1,
        TITLE: data.ACTIVE_CALORIES_TITLE,
        TEXT: data.ACTIVE_CALORIES_VALUE,
      },
      {
        INDEX: 2,
        TITLE: data.DAILY_ACTIVITY_CALORIES_TITLE,
        TEXT: data.DAILY_ACTIVITY_CALORIES_VALUE,
      },
      {
        INDEX: 3,
        TITLE: data.RMR_TITLE,
        TEXT: data.RMR_VALUE,
      },
    ],
    TABLE2: [
      {
        INDEX: 1,
        TITLE: data.WEIGHT_GAIN_TITLE,
        TEXT: data.WEIGHT_GAIN_TEXT,
      },
      {
        INDEX: 2,
        TITLE: data.WEIGHT_MAINTENANCE_TITLE,
        TEXT: data.WEIGHT_MAINTENANCE_TEXT,
      },
      {
        INDEX: 3,
        TITLE: data.WEIGHT_LOSS_TITLE,
        TEXT: data.WEIGHT_LOSS_TEXT,
      },
    ],
  };
  return (
    <CustomCard>
      <div className={classes.container}>
        <div className={classes.innerLeft}>
          {SECTIONS.TABLE1.map((el) => (
            <div key={el.INDEX}>
              <ScaleItem key={el.INDEX} data={el} color="var(--secondary09)" />
            </div>
          ))}
        </div>
        <div className={classes.innerLeft}>
          {SECTIONS.TABLE2.map((el) => (
            <div key={el.INDEX}>
              <ScaleItem key={el.INDEX} data={el} color="var(--secondary19)" />
            </div>
          ))}
        </div>
      </div>
      <div className={classes.base}>
        <img
          src={triangle}
          className={classes.baseImg}
          alt="base of the scale"
        />
      </div>
    </CustomCard>
  );
};

export default ScaleBalance;
