import React, { MouseEventHandler } from "react";
import useTheme from "../hooks/useTheme";

type Props = {
  onClick?: MouseEventHandler<SVGSVGElement>;
  width?: number;
  height?: number;
  color?: string;
};

const Logo = ({ onClick, width = 191, height = 47, color }: Props) => {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 191 47"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.7377 14.0772C70.9261 14.0767 71.1049 14.148 71.2401 14.2725L71.3594 14.4142L89.7058 42.6026C89.7842 42.7231 89.9324 42.7774 90.0702 42.7367C90.1736 42.7061 90.2526 42.6273 90.2856 42.5291L90.3026 42.4257V14.5644C90.3026 14.3496 90.4421 14.1672 90.6356 14.1028L90.7896 14.078H97.9353C98.0646 14.0777 98.1886 14.1289 98.28 14.2202C98.3409 14.2811 98.3839 14.3564 98.4057 14.4381L98.4223 14.5644V46.0295C98.4223 46.2443 98.2828 46.4267 98.0893 46.4911L97.9353 46.5159H83.0728C82.8845 46.5164 82.7056 46.445 82.5705 46.3203L82.4512 46.1786L64.1048 17.9902C64.0264 17.87 63.8781 17.8157 63.7403 17.8564C63.637 17.887 63.558 17.9658 63.5249 18.0638L63.508 18.1671V46.0278C63.5076 46.2424 63.3682 46.4246 63.1748 46.489L63.021 46.5139H55.8752C55.6602 46.5137 55.4778 46.3744 55.4132 46.1813L55.3882 46.0278V14.5636C55.3882 14.3485 55.5277 14.1663 55.7213 14.1019L55.8752 14.0772H70.7377Z"
        fill={color ? color : theme.colors_ds.NEUTRAL_ICON_STRONG}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M190.012 14.0771C190.228 14.0771 190.41 14.2166 190.475 14.4099L190.5 14.5636V20.8886C190.5 21.1034 190.36 21.2858 190.166 21.3502L190.012 21.375H162.405C162.271 21.3752 162.156 21.4569 162.106 21.573L162.081 21.699V26.7268C162.081 26.861 162.163 26.9762 162.279 27.0255L162.405 27.0511H185.951C186.167 27.0511 186.349 27.1904 186.414 27.3837L186.439 27.5375V33.0518C186.439 33.181 186.388 33.305 186.296 33.396C186.235 33.4569 186.16 33.4999 186.078 33.5218L185.951 33.5382H162.404C162.27 33.5387 162.155 33.6202 162.106 33.7364L162.08 33.8625V38.8904C162.08 39.0247 162.162 39.14 162.278 39.1892L162.404 39.2146H190.012C190.227 39.2151 190.409 39.3543 190.474 39.5474L190.499 39.7011V46.0269C190.498 46.2415 190.359 46.4237 190.165 46.4881L190.012 46.513H154.447C154.232 46.5128 154.05 46.3735 153.985 46.1804L153.96 46.0269V14.5636C153.96 14.3488 154.1 14.1663 154.293 14.102L154.447 14.0771H190.012Z"
        fill={color ? color : theme.colors_ds.NEUTRAL_ICON_STRONG}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M126.354 13.5908C141.619 13.5908 149.901 21.0699 149.901 30.2958C149.901 39.5214 141.458 47.0004 126.354 47.0004C111.251 47.0004 102.807 39.5214 102.807 30.2958C102.807 21.0699 111.251 13.5908 126.354 13.5908ZM126.355 21.0516C117.189 21.0516 112.064 25.1896 112.064 30.2957C112.064 35.3181 117.022 39.4037 125.907 39.5364L126.355 39.5398C135.52 39.539 140.645 35.4007 140.645 30.2957C140.645 25.1904 135.618 21.0516 126.355 21.0516Z"
        fill={color ? color : theme.colors_ds.NEUTRAL_ICON_STRONG}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.0637 14.0781C45.3208 14.0781 51.1669 19.1051 51.1679 26.8975C51.1679 35.3309 45.4832 41.0073 32.1666 41.0073C28.5822 41.0073 24.2359 40.7584 19.6977 40.2703C19.6061 40.2607 19.5147 40.2902 19.4461 40.3518C19.4004 40.3926 19.3677 40.4451 19.3509 40.503L19.338 40.5928V46.0279C19.3376 46.2427 19.1978 46.4249 19.0041 46.4892L18.8501 46.514H11.7052C11.49 46.514 11.3074 46.3747 11.243 46.1815L11.2182 46.0279V39.3593C11.2174 39.202 11.104 39.0676 10.9489 39.0405C7.39487 38.4179 3.93313 37.6468 0.845891 36.7257C0.691568 36.6786 0.574166 36.5602 0.52511 36.4134L0.5 36.2586V29.4287C0.5 29.3273 0.547491 29.2313 0.628754 29.17C0.710018 29.1088 0.815388 29.0894 0.913235 29.1174C3.62679 29.8828 6.37089 30.5345 9.13834 31.0711L11.2182 31.4519V14.5646C11.2179 14.4354 11.2692 14.3117 11.3606 14.2203C11.4215 14.1594 11.4969 14.1165 11.5787 14.0947L11.7052 14.0781H36.0637ZM35.2481 21.6998H19.6597C19.5252 21.6998 19.4099 21.7815 19.3606 21.8979L19.3351 22.024V29.765C19.337 29.9485 19.2475 30.121 19.0962 30.2254C18.1654 30.8647 16.5992 31.4433 14.9297 31.6846C14.8278 31.6846 14.7452 31.767 14.7452 31.8687C14.7452 31.9708 14.8278 32.0531 14.9297 32.0531C19.9594 32.7936 25.0292 33.2243 30.1103 33.3433L32.6516 33.3769C39.8001 33.3769 42.7232 31.106 42.7195 27.5383C42.7195 23.8085 40.1202 21.6998 35.2481 21.6998Z"
        fill={color ? color : theme.colors_ds.NEUTRAL_ICON_STRONG}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M186.647 2.37395C186.696 2.41499 186.731 2.46875 186.749 2.5282L186.762 2.62078V7.51885C186.763 7.74978 186.6 7.94921 186.374 7.99603C180.338 9.22582 172.817 9.84751 158.507 9.86138C158.291 9.86092 158.109 9.72173 158.044 9.5286L158.019 9.37496V4.6714C158.019 4.5422 158.07 4.41821 158.162 4.32717C158.253 4.23584 158.377 4.18468 158.507 4.18497C171.16 4.16561 178.815 3.54103 186.385 2.30112C186.479 2.2858 186.575 2.31239 186.647 2.37395Z"
        fill={color ? color : theme.colors_ds.NEUTRAL_ICON_STRONG}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4938 2.26989C19.7945 3.56876 20.1835 5.52227 19.4794 7.21941C18.7753 8.91656 17.1168 10.0229 15.2774 10.0226C14.0716 10.0226 12.9152 9.54432 12.0626 8.69256C11.2099 7.8411 10.731 6.68617 10.731 5.4821C10.7306 3.64507 11.8384 1.98867 13.5377 1.28548C15.237 0.582289 17.1932 0.970736 18.4938 2.26989ZM15.4593 3.46618H13.5648V7.16741H14.5923V5.91565H15.4428L16.1862 7.16741H17.3659L16.4574 5.70553C16.658 5.62402 16.8307 5.48616 16.9546 5.30841C17.0669 5.12401 17.1227 4.91071 17.1151 4.6951C17.1181 3.87572 16.5662 3.46618 15.4593 3.46618Z"
        fill={color ? color : theme.colors_ds.NEUTRAL_ICON_STRONG}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4006 4.25355C15.6397 4.25355 15.8101 4.28737 15.9115 4.355C16.0168 4.43015 16.0749 4.555 16.0647 4.68391C16.0763 4.81686 16.0149 4.94605 15.9042 5.02091C15.8336 5.06715 15.734 5.09808 15.6053 5.1134L15.3904 5.12496H14.5916V4.25355H15.4006Z"
        fill={color ? color : theme.colors_ds.NEUTRAL_ICON_STRONG}
      />
    </svg>
  );
};

export default Logo;
