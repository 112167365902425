import classes from "./RecommendationsSection.module.css";
import { getBiomarkerCategoryColor } from "../../store/helper";
import useTheme from "../../../../common/hooks/useTheme";
import RecommendationCard from "./RecommendationCard";
import { useSelector } from "react-redux";
import { RootStatePlatform } from "../../store/platform-slice";
import RecommendationsGroup from "./RecommendationsGroup";
import DoubleArrowUp from "../../svg/DoubleArrowUp";
import DoubleArrowDown from "../../svg/DoubleArrowDown";
import CheckWithCircle from "../../svg/CheckWithCircle";
import XWithCircle from "../../svg/XWithCircle";

const RecommendationsSection = () => {
  const theme = useTheme();
  const data = useSelector(
    (state: RootStatePlatform) => state.platform.data.score
  );
  const reportData = useSelector(
    (state: RootStatePlatform) => state.platform.dataPlatformServer
  );
  const selectedMetric = useSelector(
    (state: RootStatePlatform) => state.platform.selectedMetric
  );

  const selectedMetricData = reportData.metrics.find(
    (el: any) => el.type === selectedMetric
  );

  return (
    <div className={classes.container}>
      {/*<div className={classes.inner}>*/}
      {/*  <RecommendationsGroup*/}
      {/*    title={data.PositiveFactorsTitle}*/}
      {/*    text={data.PositiveFactorsDescription}*/}
      {/*    items={selectedMetricData.positiveFactors}*/}
      {/*    color={theme.colorTokens.S[50]}*/}
      {/*  />*/}
      {/*  <RecommendationsGroup*/}
      {/*    title={data.HigherScoreBenefitsTitle}*/}
      {/*    text={data.HigherScoreBenefitsDescription}*/}
      {/*    items={selectedMetricData.higherScoreBenefits}*/}
      {/*    color={theme.colors_ds.NEUTRAL_BORDER_WEAK}*/}
      {/*    hasIcon={<DoubleArrowUp />}*/}
      {/*    cardHasNoColorCoding={true}*/}
      {/*    groupHasLeftBorder={true}*/}
      {/*  />*/}
      {/*</div>*/}
      {/*<div className={classes.inner}>*/}
      {/*  <RecommendationsGroup*/}
      {/*    title={data.NegativeFactorsTitle}*/}
      {/*    text={data.NegativeFactorsDescription}*/}
      {/*    items={selectedMetricData.negativeFactors}*/}
      {/*    color={theme.colorTokens.D[60]}*/}
      {/*  />*/}
      {/*  <RecommendationsGroup*/}
      {/*    title={data.LowerScoreImpactsTitle}*/}
      {/*    text={data.LowerScoreImpactsDescription}*/}
      {/*    items={selectedMetricData.lowerScoreImpacts}*/}
      {/*    color={theme.colors_ds.NEUTRAL_BORDER_WEAK}*/}
      {/*    hasIcon={<DoubleArrowDown />}*/}
      {/*    cardHasNoColorCoding={true}*/}
      {/*    groupHasLeftBorder={true}*/}
      {/*  />*/}
      {/*</div>*/}
      <div className={classes.inner}>
        {selectedMetricData.lifestyle_recommendations !== null && (
          <RecommendationsGroup
            title="Habits to adopt"
            text={data.NegativeFactorsDescription}
            items={selectedMetricData.lifestyle_recommendations.adopt}
            color={theme.colorTokens.D[60]}
            cardHasNoColorCoding={true}
            hasIcon={<CheckWithCircle />}
          />
        )}
        {selectedMetricData.lifestyle_recommendations !== null && (
          <RecommendationsGroup
            title="Habits to let go"
            text={data.LowerScoreImpactsDescription}
            items={selectedMetricData.lifestyle_recommendations.avoid}
            color={theme.colors_ds.NEUTRAL_BORDER_WEAK}
            hasIcon={<XWithCircle />}
            cardHasNoColorCoding={true}
            groupHasLeftBorder={true}
          />
        )}
      </div>
    </div>
  );
};

export default RecommendationsSection;
