import React, { useEffect, useRef, useState } from "react";
import classes from "./ClientsListLetterNavigation.module.css"
import useTheme from "../../../../common/hooks/useTheme";

type Props = {
    clientRowGroupRefs: any;
};

const ClientsListLetterNavigation = ({clientRowGroupRefs}: Props) => {
  const theme = useTheme();

  // Scroll to a specific row group
  const scrollToRowGroup = (letter: string) => {
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let targetLetter = letter;
    let index = alphabet.indexOf(letter);

    // Search forward
    while (index < alphabet.length && !clientRowGroupRefs.current[targetLetter]) {
      index++;
      targetLetter = alphabet[index];
    }

    // If not found, search backward
    if (!clientRowGroupRefs.current[targetLetter]) {
      index = alphabet.indexOf(letter);
      while (index >= 0 && !clientRowGroupRefs.current[targetLetter]) {
        index--;
        targetLetter = alphabet[index];
      }
    }

    if (clientRowGroupRefs.current[targetLetter]) {
      clientRowGroupRefs.current[targetLetter].scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className={classes.container}>
        {['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'].map((letter, index) => (
        <div 
            key={index} 
            className={classes.item} 
            style={{ color: theme.colors_ds.PRIMARY_TEXT_MAIN }}
            onClick={() => scrollToRowGroup(letter)}
        > 
            {letter} 
        </div>
        ))} 
    </div>
  );
};

export default ClientsListLetterNavigation;