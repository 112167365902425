import classes from "./BtnForm.module.css";
import useTheme from "../../../../common/hooks/useTheme";
import Male_icon from "../../../../common/svgs/Male_icon";
import Female_icon from "../../../../common/svgs/Female_icon";
import Selected_icon from "../../../../common/svgs/Selected_icon";
import React from "react";

type Props = {
  data: {
    index: number;
    label: string;
    lengthUnit?: string;
    massUnit?: string;
  };
  value: number;
  category: string;
  answer: number;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const BtnForm = ({ data, value, category, answer, ...props }: Props) => {
  let content;
  const theme = useTheme();
  if (category === "mass_measurement_unit") {
    content = (
      <div className={classes.symbol}>
        {`${data.lengthUnit} / ${data.massUnit}`}
      </div>
    );
  } else if (category === "gender") {
    content = data.label === "Male" ? <Male_icon /> : <Female_icon />;
  }
  return (
    <label
      className={classes.btn}
      style={{
        borderColor:
          value === +answer
            ? theme.colors_ds.PRIMARY_ICON_MAIN
            : theme.colors_ds.NEUTRAL_BORDER_WEAK,
        backgroundColor:
          value === +answer
            ? theme.colorTokens.P[10]
            : theme.colors_ds.NEUTRAL_BG_MAIN,
        color: theme.colors_ds.NEUTRAL_TEXT_DEFAULT,
      }}
    >
      <div className={classes.iconContainer}>
        {content}
        {value === +answer && (
          <div className={classes.btnChecked}>
            <Selected_icon />
          </div>
        )}
      </div>
      <input
        className={classes.btnInput}
        type="radio"
        value={value}
        {...props}
      />
      {data.label}
    </label>
  );
};

export default BtnForm;
