import CoverPage from "../pages/CoverPage/CoverPage";
import PillarsOfLongevityPage from "../pages/PillarsOfLongevityPage/PillarsOfLongevityPage";
import OverviewMetricBarGraphPage from "../pages/OverviewMetricBarGraphPage/OverviewMetricBarGraphPage";
import CoreMetricsPage from "../pages/CoreMetricsPage/CoreMetricsPage";
import WellnessHealthHistoryPage from "../pages/WellnessHealthHistoryPage/WellnessHealthHistoryPage";
import MetricPage from "../pages/MetricPage/MetricPage";
import RiskMetricPage from "../pages/MetricPage/RiskMetricPage";
import CaloricBalancePage from "../pages/CaloricBalance/CaloricBalancePage";
import MacronutrientBalancePage from "../pages/MacronutrientBalancePage/MacronutrientBalancePage";
import TestingSchedulePage from "../pages/TestingSchedulePage/TestingSchedulePage";
import AuxiliaryTreatmentsPage from "../pages/AuxiliaryTreatmentsPage/AuxiliaryTreatmentsPage";
import AuxiliaryTreatmentsPageV02 from "../pages/AuxiliaryTreatmentsPage_V02/AuxiliaryTreatmentsPageV02";
import UserServicesPage from "../pages/UserServicesPage/UserServicesPage";
import { useEffect, useState } from "react";
import classes from "../pages/BiologicalAgePage/BiologicalAgePage.module.css";
import BiologicalAgePage from "../pages/BiologicalAgePageOld/BiologicalAgePage";

const ReportRMR = ({ data, svgConverter, reportType, allPagesToVertical }) => {
  // console.log(data);
  const [imgBiologicalAgePage, setImgBiologicalAgePage] = useState(false);

  let wellnessHealthHistoryPageHasContext = false;
  for (
    let i = 0;
    i <
    data.PAGES.find((page) => page.PAGE_TYPE === "HEALTH_HISTORY").SECTIONS
      .length;
    i++
  ) {
    if (
      data.PAGES.find((page) => page.PAGE_TYPE === "HEALTH_HISTORY").SECTIONS[i]
        .TEXT !== null
    ) {
      wellnessHealthHistoryPageHasContext = true;
    }
  }

  // useEffect(() => {
  //   svgConverter("biologicalAgePage", "biologicalAgeImage", 794);
  //   setImgBiologicalAgePage(true);
  // }, [svgConverter]);

  // delete when deployed new VO2 max chart
  const biaxialLineChartDataHandler = () => {
    const biaxialLineChartData = {
      gender: data.GENDER,
      lines_men: [
        {
          x: 15,
          area0: [15, 37],
          area1: [37, 42],
          area2: [42, 47],
          area3: [47, 52],
          area4: [52, 65],
        },
        {
          x: 75,
          area0: [15, 21],
          area1: [21, 24],
          area2: [24, 27],
          area3: [27, 30],
          area4: [30, 65],
        },
        { x: data.AGE, lineUser: data.VO2PEAK },
      ],
      lines_women: [
        {
          x: 15,
          area0: [15, 33],
          area1: [33, 38],
          area2: [38, 42],
          area3: [42, 47],
          area4: [47, 65],
        },
        {
          x: 75,
          area0: [15, 17],
          area1: [17, 20],
          area2: [20, 23],
          area3: [23, 25],
          area4: [25, 65],
        },
        { x: data.AGE, lineUser: data.VO2PEAK },
      ],
    };
    return biaxialLineChartData;
  };

  // const imgBiologicalAge = (
  //   <div id="biologicalAgePage" className={classes.imgBiologicalAge}>
  //     <BiologicalAgePage
  //       data={data.PAGES.find((page) => page.PAGE_TYPE === "VO2MAX")}
  //       onSvgConverter={svgConverter}
  //       gender={data.GENDER}
  //       userAge={data.AGE}
  //       userVO2PEAK={data.VO2PEAK}
  //     />
  //   </div>
  // );

  {
    /*//New design for VO2 max chart*/
  }

  // useEffect(() => {
  //   svgConverter("biologicalAgePage", "biologicalAgeImage", 794);
  //   setImgBiologicalAgePage(true);
  // }, [svgConverter]);
  //
  // const imgBiologicalAge = (
  //   <div id="biologicalAgePage" className={classes.imgBiologicalAge}>
  //     <BiologicalAgePage
  //       data={data.PAGES.find((page) => page.PAGE_TYPE === "VO2MAX")}
  //       onSvgConverter={svgConverter}
  //       gender={data.GENDER}
  //       userAge={data.AGE}
  //       userVO2PEAK={data.VO2PEAK}
  //     />
  //   </div>
  // );

  return (
    <>
      <CoverPage
        data={data.PAGES.find((page) => (page.PAGE_TYPE = "COVER"))}
        trainerName={data.TRAINER}
        trainerMail={data.TRAINER_MAIL}
        reportType={reportType}
      />
      <PillarsOfLongevityPage
        data={data.PAGES.find((page) => page.PAGE_TYPE === "PILLARS")}
        gender={data.GENDER}
        reportType={reportType}
      />
      <OverviewMetricBarGraphPage
        data={data.PAGES.find((page) => page.PAGE_TYPE === "OVERVIEW")}
        onSvgConverter={svgConverter}
      />
      {data.PAGES.find((page) => page.PAGE_TYPE === "INTRO") && (
        <CoreMetricsPage
          data={data.PAGES.find((page) => page.PAGE_TYPE === "INTRO")}
          reportType={reportType}
        />
      )}
      {wellnessHealthHistoryPageHasContext && (
        <WellnessHealthHistoryPage
          data={data.PAGES.find((page) => page.PAGE_TYPE === "HEALTH_HISTORY")}
        />
      )}
      {/*{data.PAGES.find((page) => page.PAGE_TYPE === "VO2MAX") && (*/}
      {/*  <div*/}
      {/*    id="biologicalAgeImage"*/}
      {/*    style={*/}
      {/*      imgBiologicalAgePage && !allPagesToVertical*/}
      {/*        ? {*/}
      {/*            transform: "rotate(90deg)",*/}
      {/*            marginTop: "-166px",*/}
      {/*            marginBottom: "-165px",*/}
      {/*          }*/}
      {/*        : { height: "1122px" }*/}
      {/*    }*/}
      {/*  >*/}
      {/*    {!imgBiologicalAgePage && imgBiologicalAge}*/}
      {/*  </div>*/}
      {/*)}*/}
      {data.PAGES.find((page) => page.PAGE_TYPE === "BIOLOGICAL_AGE") && (
        <BiologicalAgePage
          data={data.PAGES.find((page) => page.PAGE_TYPE === "BIOLOGICAL_AGE")}
          onSvgConverter={svgConverter}
          biaxialLineChartData={biaxialLineChartDataHandler()}
          dataNew={
            data.PAGES.find((page) => page.PAGE_TYPE === "OVERVIEW").NAVBAR
          }
          showVO2MaxChart={false}
        />
      )}
      {data.USERSERVICES === 1 &&
        data.PAGES.find((page) => page.PAGE_TYPE === "USERSERVICES") && (
          <UserServicesPage
            data={data.PAGES.find((page) => page.PAGE_TYPE === "USERSERVICES")}
          />
        )}
      <MetricPage
        data={data.PAGES.find((page) => page.PAGE_TYPE === "OVERVIEW")}
      />
      <RiskMetricPage
        data={data.PAGES.find((page) => page.PAGE_TYPE === "OVERVIEW")}
        onSvgConverter={svgConverter}
      />
      <CaloricBalancePage
        data={data.PAGES.find((page) => page.PAGE_TYPE === "CALORIC_BALANCE")}
        onSvgConverter={svgConverter}
      />
      <MacronutrientBalancePage
        data={data.PAGES.find((page) => page.PAGE_TYPE === "MACRO_BALANCE")}
        onSvgConverter={svgConverter}
      />
      <TestingSchedulePage
        data={data.PAGES.find((page) => page.PAGE_TYPE === "TESTING_SCHEDULE")}
        onSvgConverter={svgConverter}
      />
      {data.USERSERVICES === 0 &&
        data.PAGES.find((page) => page.PAGE_TYPE === "SERVICES") && (
          <AuxiliaryTreatmentsPageV02
            data={data.PAGES.find((page) => page.PAGE_TYPE === "SERVICES")}
          />
        )}
    </>
  );
};

export default ReportRMR;
