import classes from "./Layout.module.css";
import sideAuthImg from "../../../../common/images/backgroundImgMobile.png";
import useTheme from "../../../../common/hooks/useTheme";
import LogoSmall from "../../../../common/svgs/logo-small";

type Props = {
  children: any;
};
const Layout = ({ children }: Props) => {
  const theme = useTheme();
  return (
    <div className={classes.container}>
      <div
        className={classes.side}
        style={{
          backgroundImage: `url('${sideAuthImg}')`,
          backgroundPosition: "top center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "100vh",
        }}
      />
      <div
        className={classes.main}
        style={{ backgroundColor: theme.colors_ds.NEUTRAL_BG_MAIN }}
      >
        <div className={classes.header}>
          <LogoSmall />
        </div>
        <div className={classes.content}>{children}</div>
      </div>
    </div>
  );
};
export default Layout;
