import OverviewMetricBarGraphPage from "../pages/OverviewMetricBarGraphPage/OverviewMetricBarGraphPage";
import CustomCoverPage from "../pages/CustomCoverPage/CustomCoverPage";
import TrainerNotePage from "../pages/TrainerNotePage/TrainerNotePage";

const CustomReportRMR = ({ data, svgConverter, reportType }) => {
  return (
    <>
      <CustomCoverPage />
      <TrainerNotePage data={data.NOTES} />
      <OverviewMetricBarGraphPage
        data={data.PAGES.find((page) => page.PAGE_TYPE === "OVERVIEW")}
        data2={data.PAGES.find((page) => page.PAGE_TYPE === "CALORIC_BALANCE")}
        onSvgConverter={svgConverter}
        isCustomReport={true}
      />
    </>
  );
};

export default CustomReportRMR;
