import useTheme from "../hooks/useTheme";

const Selected_icon = () => {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M9 0C4.6 0 1 3.6 1 8C1 12.4 4.6 16 9 16C13.4 16 17 12.4 17 8C17 3.6 13.4 0 9 0ZM8 11.4L4.6 8L6 6.6L8 8.6L12 4.6L13.4 6L8 11.4Z"
        fill={theme.colors_ds.NEUTRAL_ICON_DEFAULT}
      />
    </svg>
  );
};

export default Selected_icon;
