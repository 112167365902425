import { configureStore } from "@reduxjs/toolkit";

import stepperSlice from "./stepper-slice";
import answersSlice from "./answers-slice";
import platformSlice from "../../platform/store/platform-slice";

const store = configureStore({
  reducer: {
    stepper: stepperSlice.reducer,
    answers: answersSlice.reducer,
    platform: platformSlice.reducer,
  },
});

export default store;
