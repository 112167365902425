import classes from "./CustomCard.module.css";

interface Props {
  children: any;
  cardHeight?: number | string;
  cardWidth?: string;
  cardPadding?: string;
  cardMargin?: string;
  isDarkTheme?: boolean;
}

const CustomCard = ({
  children,
  cardHeight,
  cardWidth,
  cardPadding,
  cardMargin,
  isDarkTheme,
}: Props): JSX.Element => {
  return (
    <div
      className={classes.card}
      style={
        isDarkTheme
          ? {
              width: cardWidth,
              minHeight: cardHeight,
              padding: cardPadding,
              margin: cardMargin,
              background: "var(--primary100)",
              borderRadius: 0,
            }
          : {
              width: cardWidth,
              minHeight: cardHeight,
              padding: cardPadding,
              margin: cardMargin,
            }
      }
    >
      {children}
    </div>
  );
};

export default CustomCard;
