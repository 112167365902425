import classes from "./Points.module.css";

import axisPoint from "../../../images/pointBiologicalAge.png";
import useTheme from "../../../../../common/hooks/useTheme";

const Points = (): JSX.Element => {
  const theme = useTheme();
  return (
    <div className={classes.pointsAge}>
      <img
        className={classes.pointAge0}
        src={axisPoint}
        alt="Point 0 of radial age chart"
      />
      <div
        className={`${classes.pointAgeGeneral} ${classes.pointAge10}`}
        style={{ backgroundColor: theme.colors_ds.NEUTRAL_TEXT_WEAK }}
      />
      <div
        className={`${classes.pointAgeGeneral} ${classes.pointAge20}`}
        style={{ backgroundColor: theme.colors_ds.NEUTRAL_TEXT_WEAK }}
      />
      <div
        className={`${classes.pointAgeGeneral} ${classes.pointAge30}`}
        style={{ backgroundColor: theme.colors_ds.NEUTRAL_TEXT_WEAK }}
      />
      <div
        className={`${classes.pointAgeGeneral} ${classes.pointAge40}`}
        style={{ backgroundColor: theme.colors_ds.NEUTRAL_TEXT_WEAK }}
      />
      <div
        className={`${classes.pointAgeGeneral} ${classes.pointAge50}`}
        style={{ backgroundColor: theme.colors_ds.NEUTRAL_TEXT_WEAK }}
      />
      <div
        className={`${classes.pointAgeGeneral} ${classes.pointAge60}`}
        style={{ backgroundColor: theme.colors_ds.NEUTRAL_TEXT_WEAK }}
      />
      <div
        className={`${classes.pointAgeGeneral} ${classes.pointAge70}`}
        style={{ backgroundColor: theme.colors_ds.NEUTRAL_TEXT_WEAK }}
      />
      <div
        className={`${classes.pointAgeGeneral} ${classes.pointAge80}`}
        style={{ backgroundColor: theme.colors_ds.NEUTRAL_TEXT_WEAK }}
      />
      <div
        className={`${classes.pointAgeGeneral} ${classes.pointAge90}`}
        style={{ backgroundColor: theme.colors_ds.NEUTRAL_TEXT_WEAK }}
      />

      <img
        className={classes.pointAge100}
        src={axisPoint}
        alt="Point 0 of radial age chart"
      />
    </div>
  );
};

export default Points;
