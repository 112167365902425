type Props = { fill?: string };

const CheckedIcon = ({ fill }: Props) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="-2 -2 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0" y="0" width="22" height="22" rx="4" stroke={fill} />
      <path 
        d="M7.6 16.5805C8.6 17.6214 9.6 17.4133 10.4 16.5805L18.4 8.25304C19.2 7.42029 19.2 6.17117 18.4 5.33843C17.6 4.50568 16.4 4.50568 15.6 5.33843L9 12.2086L6.4 9.50216C5.6 8.66941 4.4 8.66941 3.6 9.50216C2.8 10.3349 2.8 11.584 3.6 12.4168L7.6 16.5805Z"
        fill={fill}
      />
    </svg>
  );
};

export default CheckedIcon;
