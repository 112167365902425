type Props = { fill?: string };

const UncheckedIcon = ({ fill }: Props) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="-2 -2 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0" y="0" width="22" height="22" rx="4" stroke={fill} />
    </svg>
  );
};

export default UncheckedIcon;
